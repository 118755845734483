import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ParamsBar from "./ParamsBar";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  margin-left: 8px;
  margin-right: 8px;
`;

const CreateForm = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputField = styled.input`
  height: 30px;
  width: 300px;
  box-sizing: border-box;
`;

const LabelName = styled.label`
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
`;

const SubmitButton = styled.button`
  outline: none;
  cursor: pointer;
  border: none;
  background-color: green;
  padding: 0 20px;
  height: 30px;
  font-weight:bold;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  font-family: "Raleway", sans-serif;
  margin-left: 10px;
  font-size: 16px;
  }
  transition: all 0.4s;
  &:hover {
    background-color: #fabe3c;
  } 
    
`;

const CreationForm = (props) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const templateIds =
      newsletterBuilder.columns["newsletter-column"].templateIds;
    const templates = templateIds.reduce((object, templateId) => {
      object[templateId] = newsletterBuilder.templates[templateId];

      return object;
    }, {});
    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/newsletter/add`,
      {
        title: newsletterBuilder.newsletterTitle,
        categoryId: newsletterBuilder.categoryId,

        templates: templates,
      },

      {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      }
    );

    if (response.status === 200) {
      history.push(`/newsletter/edit/${response.data._id}`);

    }
  };

  return (
    <Container>
      <ParamsBar></ParamsBar>
      <CreateForm>
        <LabelName>{t("creationForm.labelName")}</LabelName>
        <InputField
          onChange={(event) => {
            const newTitle = event.target.value;
            dispatch({
              type: "UPDATE_NEWSLETTER_TITLE",
              title: newTitle,
            });
          }}
          value={newsletterBuilder.newsletterTitle}
          type="text"
          name="title"
        ></InputField>
        <SubmitButton onClick={handleSubmit}>
          {t("creationForm.submitBtn")}
        </SubmitButton>
      </CreateForm>
    </Container>
  );
};

export default CreationForm;
