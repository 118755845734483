import React from "react";
import { useLocation } from "react-router-dom";
import {
  MenuWrapper,
  SubMenuWrapper,
  ItemMenu,
  MenuTitle,
  SubMenuLink,
  SubMenuWithoutLink,
  SubMenuText,
} from "./Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import useScrollPosition from "../../utils/useScrollPosition.js";
import { useTranslation } from "react-i18next";

const AdminSideBar = (props) => {
  const location = useLocation();
  //   const urlArray = location.pathname.split("/");
  //   const Urlcategory = urlArray[1];
  const history = useHistory();
  const scrollPos = useScrollPosition();
  const { t } = useTranslation();

  return (
    <MenuWrapper position={scrollPos > 120 ? 0 : 120}>
      <MenuTitle
        selectedlink={
          location.pathname.indexOf("/category/") !== -1 ? "true" : "false"
        }
        onClick={() => history.push("/category/list")}
      >
        {t("adminSidebar.templates")} <FontAwesomeIcon icon="chevron-right" />
      </MenuTitle>
      <SubMenuWrapper
        selectedlink={
          location.pathname.indexOf("/category/") !== -1 ? "true" : "false"
        }
      >
        <ItemMenu>
          <SubMenuLink
            selectedlink={
              location.pathname.indexOf("/category/list") !== -1
                ? "true"
                : "false"
            }
            className="sub-menu-link"
            to="/category/list"
          >
            <SubMenuText>{t("adminSidebar.list")}</SubMenuText>
          </SubMenuLink>
        </ItemMenu>
        <ItemMenu>
          <SubMenuLink
            selectedlink={
              location.pathname.indexOf("/category/add") !== -1
                ? "true"
                : "false"
            }
            className="sub-menu-link"
            to="/category/add"
          >
            <SubMenuText>{t("adminSidebar.create")}</SubMenuText>
          </SubMenuLink>
        </ItemMenu>

        <ItemMenu>
          <SubMenuWithoutLink
            selectedlink={
              location.pathname.indexOf("/category/edit/") !== -1
                ? "true"
                : "false"
            }
            className="sub-menu-link"
          >
            <SubMenuText>{t("adminSidebar.edit")}</SubMenuText>
          </SubMenuWithoutLink>
        </ItemMenu>
      </SubMenuWrapper>
      <ul>
        <MenuTitle
          selectedlink={
            location.pathname.indexOf("/user/") !== -1 ? "true" : "false"
          }
          onClick={() => history.push("/user/list")}
        >
          {t("adminSidebar.users")} <FontAwesomeIcon icon="chevron-right" />
        </MenuTitle>
        <SubMenuWrapper
          selectedlink={
            location.pathname.indexOf("/user/") !== -1 ? "true" : "false"
          }
        >
          <ItemMenu>
            <SubMenuLink
              selectedlink={
                location.pathname.indexOf("/user/list") !== -1
                  ? "true"
                  : "false"
              }
              className="sub-menu-link"
              to="/user/list"
            >
              <SubMenuText> {t("adminSidebar.list")}</SubMenuText>
            </SubMenuLink>
          </ItemMenu>
          <ItemMenu>
            <SubMenuLink
              selectedlink={
                location.pathname.indexOf("/user/add") !== -1 ? "true" : "false"
              }
              className="sub-menu-link"
              to="/user/add"
            >
              <SubMenuText>{t("adminSidebar.create")}</SubMenuText>
            </SubMenuLink>
          </ItemMenu>
          <ItemMenu>
            <SubMenuWithoutLink
              selectedlink={
                location.pathname.indexOf("/user/edit/") !== -1
                  ? "true"
                  : "false"
              }
              className="sub-menu-link"
            >
              <SubMenuText>{t("adminSidebar.edit")}</SubMenuText>
            </SubMenuWithoutLink>
          </ItemMenu>
        </SubMenuWrapper>
      </ul>
    </MenuWrapper>
  );
};

export default AdminSideBar;
