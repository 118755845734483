import styled from "styled-components";

export const Container = styled.div`
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  width: 100%;
`;

export const LeftColumn = styled.div``;

export const LeftColumnWrapper = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  height: calc(100vh - 198px);
  display: flex;
  width: ${({ isVisible }) => (isVisible ? "350px" : "0px")};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  margin: ${({ isVisible }) => (isVisible ? "8px" : "8px 0px 8px 0px")};
  transition: all 0.4s ease;
  position: relative;
  box-sizing: border-box;
`;

export const RightColumnWrapper = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  height: calc(100vh - 198px);
  box-sizing: border-box;
  flex: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ScrollContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  left: 0;
`;

export const RightColumn = styled.div`
  // min-height: calc(100vh - 198px);
  min-height: 100px;

  box-sizing: border-box;
  transition: background-color 0.2s ease;
`;
