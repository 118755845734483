import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import getCroppedImg from "../../utils/cropImage";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getColors } from "../../utils/getColors";

const StyledCanvas = styled.canvas`
  // border: 1px solid #d3d3d3;
  display: none;
`;

const VisualStyledCanvas = styled.canvas`
  // border: 1px solid #d3d3d3;
`;



const ColorList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  & li {
    box-sizing: border-box;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 30px;
    height: 30px;
  }
`;

const CanvasContainer = styled.div`
  margin-bottom: 20px;
`;

const SubmitButton = styled.button`
  background-color: #13ce66;

  border-radius: 1000px;
  padding: 10px 20px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  border: none;
  text-transform: uppercase;
  transition: background-color 0.2s, border-color 0.2s;
  outline: none;
  &:hover {
    background-color: #fabe3c;
  }
`;

const TextInput = styled.input`
  width: 100%;
  height: 52px;
  font-family: "Raleway", sans-serif;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e0e6ed;
  padding: 16px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  color: #000;
  line-height: 20px;
  margin-bottom: 20px;
`;
const FormLabel = styled.label`
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  color: #0c3944;
  display: block;
  font-weight: bold;
`;

const PickerBtn = styled.button`
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  background-color: ${({ color }) => color};
  border: 1px solid grey;

  border-radius: 80px;
  cursor: pointer;
  outline: none;
  transition: all 0.1s ease-in-out;
  &:hover {
    position: absolute;

    transform: scale(1.3);
    z-index: 100;
    box-shadow: inset rgba(0, 0, 0, 0) 2px 2px 12px, #ffffff 0 0 10px;
  }
`;

const CanvasTitle = (props) => {
  const scale = 2;
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultColors = getColors();
  const { templateId, fieldType, fieldId } = { ...newsletterBuilder.modal };
  const canvasRef = React.useRef(null);
  const visualCanvas = React.useRef(null);
  const [canvasTitle, setCanvasTitle] = useState(
    newsletterBuilder.templates[templateId].content[fieldType][fieldId].text
  );
  const [canvasBgColor, setCanvasBgColor] = useState(
    newsletterBuilder.templates[templateId].content[fieldType][fieldId].bgColor
  );
  const [canvasColor, setCanvasColor] = useState(
    newsletterBuilder.templates[templateId].content[fieldType][fieldId].color
  );
  const [canvasWidth, setCanvasWidth] = useState(
    newsletterBuilder.templates[templateId].content[fieldType][fieldId].imgWidth
  );

  const renderBgColorPicker = () => {
    return defaultColors.map((element, index) => {
      return (
        <li key={`bg-color-${index}`}>
          <PickerBtn
            onClick={() => {
              setCanvasBgColor(element);
            }}
            color={element}
          ></PickerBtn>
        </li>
      );
    });
  };

  const renderColorPicker = () => {
    return defaultColors.map((element, index) => {
      return (
        <li key={`bg-color-${index}`}>
          <PickerBtn
            onClick={() => {
              setCanvasColor(element);
            }}
            color={element}
          ></PickerBtn>
        </li>
      );
    });
  };

  const changeCanvasText = (event) => {
    const text = event.target.value;
    if (canvasWidth < 1198) {
      setCanvasTitle(text);
    } else if (text.length < canvasTitle.length) {
      setCanvasTitle(text);
    }
  };

  const saveImage = async (canvasValue) => {
    try {
      const canvas = canvasValue;

      const formData = await new FormData();

      const newImage = await getCroppedImg(canvas.toDataURL("image/png"), {
        width: canvasWidth + 10 * scale,
        height: 60 * scale,
        x: 0,
        y: 0,
      });

      formData.append("files", newImage);
      formData.append("nom", "kiki");

      const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/upload/image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );


      dispatch({
        type: "UPDATE_TEMPLATE_CANVAS_IMAGE_TITLE",
        data: {
          templateId: templateId,
          fieldType: fieldType,
          fieldId: fieldId,
          imgSrc: response.data.imageUrl,
          text: canvasTitle,
          imgWidth: (canvasWidth + 10 * scale) / 2,
          color: canvasColor,
          bgColor: canvasBgColor,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {






    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let rect = canvas.getBoundingClientRect();
    canvas.width = rect.width;
    canvas.height = rect.height;
    canvas.style.width = rect.width + "px";
    canvas.style.height = rect.height + "px";

    canvas.width = canvasWidth * scale;
    canvas.height = 60 * scale;
    canvas.style.width = canvasWidth * scale + "px";
    canvas.style.height = 60 * scale + "px";


    // ensure all drawing operations are scaled

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // scale everything down using CSS

    ctx.font = `800 ${30 * scale}px 'Raleway', sans-serif`;
    ctx.fillStyle = canvasBgColor;
    const width = Math.floor(ctx.measureText(canvasTitle).width) + 10 * scale;

    setCanvasWidth(width);
    ctx.beginPath();
    ctx.moveTo(0, 14 * scale);
    ctx.lineTo(width - 1 * scale, 8 * scale);
    ctx.lineTo(width + 1 * scale, 44 * scale);
    ctx.lineTo(0, 55 * scale);
    ctx.closePath();

    ctx.fill();

    ctx.fillStyle = canvasColor;

    ctx.fillText(canvasTitle, 10 * scale, 40 * scale);
    ctx.imageSmoothingEnabled = false;






    const canvas2 = visualCanvas.current;
    const ctx2 = canvas2.getContext("2d");
    let rect2 = canvas2.getBoundingClientRect();
    canvas2.width = rect2.width;
    canvas2.height = rect2.height;
    canvas2.style.width = rect2.width + "px";
    canvas2.style.height = rect2.height + "px";

    canvas2.width = canvasWidth;
    canvas2.height = 60;
    canvas2.style.width = canvasWidth + "px";
    canvas2.style.height = 60 + "px";


    // ensure all drawing operations are scaled

    ctx2.clearRect(0, 0, canvas.width, canvas.height);

    // scale everything down using CSS

    ctx2.font = `800 ${30}px 'Raleway', sans-serif`;
    ctx2.fillStyle = canvasBgColor;
    const width2 = Math.floor(ctx2.measureText(canvasTitle).width) + 10;


    ctx2.beginPath();
    ctx2.moveTo(0, 14);
    ctx2.lineTo(width2 - 1, 8);
    ctx2.lineTo(width2 + 1, 44);
    ctx2.lineTo(0, 55);
    ctx2.closePath();

    ctx2.fill();

    ctx2.fillStyle = canvasColor;

    ctx2.fillText(canvasTitle, 10, 40);
    ctx2.imageSmoothingEnabled = false;






  }, [canvasTitle, canvasBgColor, canvasWidth, canvasColor]);
  return (
    <>
      <FormLabel>{t("canvasTitle.textLabel")}</FormLabel>
      <TextInput
        type="text"
        value={canvasTitle}
        onChange={changeCanvasText}
      ></TextInput>

      <FormLabel>{t("canvasTitle.bgColorLabel")}</FormLabel>
      <ColorList>{renderBgColorPicker()}</ColorList>
      <FormLabel>{t("canvasTitle.colorLabel")}</FormLabel>
      <ColorList>{renderColorPicker()}</ColorList>
      <CanvasContainer>
        <StyledCanvas ref={canvasRef} />
      </CanvasContainer>

      <CanvasContainer>
        <VisualStyledCanvas ref={visualCanvas} />
      </CanvasContainer>

      <SubmitButton
        onClick={() => {
          saveImage(canvasRef.current);
        }}
      >
        {t("canvasTitle.submitBtn")}
      </SubmitButton>
    </>
  );
};

export default CanvasTitle;
