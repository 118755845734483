import React from "react";
import styled from "styled-components";
import templateDisplayer from "../../adminUtils/templateDisplayer.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
const templates = require("../../datas/templates.json");

const ContainerWrapper = styled.section`
  background-color: #fff;
  padding: 20px;
`;

const Container = styled.div``;

const Title = styled.h1`
  font-size: 20px;
  color: #0c3944;
  font-weight: bold;
  color: #0c3944;
  margin-bottom: 20px;
  font-family: "Raleway", sans-serif;
`;

const TemplateContainer = styled.div`
  min-width: 600px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TemplateLeft = styled.div`
  width: 80%;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  & > :first-child {
    zoom: 1;
    pointer-events: none;
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.2)};
    width:100%;
    
`;

const SelectBtn = styled.button`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 40px;
  background-color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  color: ${({ isSelected }) => (isSelected ? "green" : "grey")};
`;

const TemplateRight = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectAllTemplatesBtn = styled.button`
  background-color: #075163;
  border-color: #075163;
  border-radius: 1000px;
  padding: 10px 20px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  transition: background-color 0.2s, border-color 0.2s;
  outline: none;
  &:hover {
    background-color: #003643;
    border-color: #003643;
  }
`;

const TemplateChoice = (props) => {
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const renderTemplateList = () => {
    const templateKeys = Object.keys(templates);

    const templateList = templateKeys.map((templateKey, index) => {
      const isSelected = admin.category.templates.indexOf(templateKey) !== -1;

      return (
        <TemplateContainer key={`template-${index}`}>
          <TemplateLeft isSelected={isSelected}>
            {templateDisplayer(templates[templateKey])}
          </TemplateLeft>
          <TemplateRight>
            <SelectBtn
              isSelected={isSelected}
              onClick={() => {
                if (isSelected === true) {
                  dispatch({
                    type: "REMOVE_CATEGORY_TEMPLATE",
                    templateName: templateKey,
                  });
                } else {
                  dispatch({
                    type: "ADD_CATEGORY_TEMPLATE",
                    templateName: templateKey,
                  });
                }
              }}
            >
              <FontAwesomeIcon icon={isSelected ? "check-square" : "square"} />
            </SelectBtn>
          </TemplateRight>
        </TemplateContainer>
      );
    });

    return templateList;
  };

  return (
    <ContainerWrapper>
      <Title>{t("templateChoice.title")}</Title>

      <SelectAllTemplatesBtn
        onClick={() => {
          dispatch({
            type: "ADD_ALL_TEMPLATES",
            templates: Object.keys(templates),
          });
        }}
      >
        {t("templateChoice.selectBtn")}
      </SelectAllTemplatesBtn>
      <Container>{renderTemplateList()}</Container>
    </ContainerWrapper>
  );
};

export default TemplateChoice;
