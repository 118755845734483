import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import SelectField from "./SelectField";

const ContainerWrapper = styled.div``;
const LAbelContainer = styled.div`
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  width: 150px;
  margin-right: 10px;
  font-weight: bold;
`;

const FieldContainer = styled.div`
  flex: 1;
`;

const Container = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

const MarginBottomEditor = ({ templateId, marginBottom }) => {
  const { t } = useTranslation();
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();

  const editionMode =
    newsletterBuilder.selectedTemplateId === templateId ? true : false;

  const { content } = { ...newsletterBuilder.templates[templateId] };

  const { type, model } = { ...content.options[marginBottom] };

  const changeMarginBottom = (event) => {
    const bgColor = event.target.value;
    dispatch({
      type: "CHANGE_TEMPLATE_MARGIN_BOTTOM",
      data: {
        templateId: templateId,
        type: bgColor,
      },
    });
  };

  const marginOptions = [
    { data: "normal", name: t("marginBottomEditor.normalOption") },
    { data: "big", name: t("marginBottomEditor.bigOption") },
  ];

  const renderMarginBottomField = () => {
    if (editionMode === true) {
      return (
        <ContainerWrapper>
          <Container>
            <LAbelContainer>
              {t("marginBottomEditor.marginBottomLabel")}
            </LAbelContainer>
            <FieldContainer>
              <SelectField
                action={changeMarginBottom}
                labelName={model}
                optionValue={type}
                optionValues={marginOptions}
              ></SelectField>
            </FieldContainer>
          </Container>
        </ContainerWrapper>
      );
    } else {
      return null;
    }
  };

  return <>{renderMarginBottomField()}</>;
};

export default MarginBottomEditor;
