import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  height: calc(100vh - 120px);
`;

export const InfoMessage = styled.div`
  color: #0c3944;

  font-family: Raleway, sans-serif;

  font-size: 22px;

  font-weight: normal;
  line-height: 30px;
`;
