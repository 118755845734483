const replaceParagraphByDiv = (target) => {
  return target.replace(/(<p)/gim, "<div").replace(/<\/p>/gim, "</div>");
};

const renderHtmlContent = (data) => {
  const htmlStart = `<!doctype html>
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

  <head>
    <!--[if !mso]><!-- -->
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <!--<![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <style type="text/css">
      #outlook a {
        padding: 0;
      }

      body {
        margin: 0;
        padding: 0;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }

      table,
      td {
        border-collapse: collapse;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
      }

      img {
        border: 0;
        height: auto;
        line-height: 100%;
        outline: none;
        text-decoration: none;
        -ms-interpolation-mode: bicubic;
      }

      p {
        display: block;
        margin: 13px 0;
      }
    </style>
    <!--[if mso]>
    <xml>
    <o:OfficeDocumentSettings>
      <o:AllowPNG/>
      <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
<!--[if lte mso 11]>
    <style type="text/css">
      .mj-outlook-group-fix { width:100% !important; }
    </style>
    <![endif]-->
<!--[if !mso]><!-->
<link href="https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap" rel="stylesheet" type="text/css">
<link href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700" rel="stylesheet" type="text/css">
<link href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap" rel="stylesheet" type="text/css">
<style type="text/css">
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap);
</style>
<!--<![endif]-->
<style>
strong {
  font-weight: 900;
}
</style>
<style type="text/css">
@media only screen and (min-width:480px) {
  .mj-column-px-326 {
    width: 326px !important;
    max-width: 326px;
  }
  .mj-column-per-100 {
    width: 100% !important;
    max-width: 100%;
  }
  .mj-column-per-45 {
    width: 45% !important;
    max-width: 45%;
  }
  .mj-column-per-10 {
    width: 10% !important;
    max-width: 10%;
  }
  .mj-column-per-48 {
    width: 48% !important;
    max-width: 48%;
  }
  .mj-column-px-15 {
    width: 15px !important;
    max-width: 15px;
  }
  .mj-column-per-44 {
    width: 44% !important;
    max-width: 44%;
  }
  .mj-column-per-6 {
    width: 6% !important;
    max-width: 6%;
  }
  .mj-column-per-50 {
    width: 50% !important;
    max-width: 50%;
  }
  .mj-column-per-36 {
    width: 36% !important;
    max-width: 36%;
  }
  .mj-column-per-58 {
    width: 58% !important;
    max-width: 58%;
  }
  .mj-column-per-30 {
    width: 30% !important;
    max-width: 30%;
  }
  .mj-column-per-64 {
    width: 64% !important;
    max-width: 64%;
  }
  .mj-column-per-4 {
    width: 4% !important;
    max-width: 4%;
  }
  .mj-column-per-3 {
    width: 3% !important;
    max-width: 3%;
  }
  .mj-column-per-53 {
    width: 53% !important;
    max-width: 53%;
  }
}
</style>
<style type="text/css">
@media only screen and (max-width:480px) {
  table.mj-full-width-mobile {
    width: 100% !important;
  }
  td.mj-full-width-mobile {
    width: auto !important;
  }
}
</style>
<style type="text/css">
@media (max-width:480px) {
  .image-padding-right {
    padding-left: 0px!important;
    padding-right: 0px!important;
    width: 600px!important;
    padding: 0px;
  }
  .section-padding {
    padding-left: 0px!important;
  }
  .content-padding {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
  .small-content-col {
    padding: 0px!important;
  }
  .content-padding-small {
    padding: 0px!important;
  }
  .mobile-width-max {
    width: 100%!important;
    width: 280px!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
}
</style>
  </head>

  <body>
  `;

  const htmlEnd = `</body>
  </html>`;

  const htmlTemplate =
    htmlStart + replaceParagraphByDiv(data).toString() + htmlEnd;
  return htmlTemplate;
};

export default renderHtmlContent;
