import React, { useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Container,
  FormContainer,
  FormInput,
  FormLabel,
  InputContainer,
  SubmitBtn,
  FieldError,
  Title,
  StyledToastContainer,
} from "./styles.js";
import axios from "axios";

const ResetPassword = (props) => {
  const { t } = useTranslation();
  const [emailState, setEmailState] = useState({ email: "" });
  const [emailNotif, setEmailNotif] = useState({
    isValid: false,
    message: t("resetPassword.emailRequiredMessage"),
  });

  const history = useHistory();

  const notifyAccountError = () => toast(t("resetPassword.accountError"));

  const handleMail = (event) => {
    event.stopPropagation();
    const fieldValue = event.target.value;
    const newEmailState = { ...emailState };

    newEmailState.email = fieldValue;
    setEmailState(newEmailState);
    const newEmailNotif = { ...emailNotif };
    if (typeof fieldValue !== "undefined" && fieldValue !== "") {
      let lastAtPos = fieldValue.lastIndexOf("@");
      let lastDotPos = fieldValue.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fieldValue.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fieldValue.length - lastDotPos > 2
        )
      ) {
        newEmailNotif.message = t("resetPassword.emailValididityMessage");
        setEmailNotif(newEmailNotif);
        newEmailNotif.isValid = false;
      } else {
        newEmailNotif.isValid = true;
        setEmailNotif(newEmailNotif);
      }
    } else {
      newEmailNotif.message = t("resetPassword.emailRequiredMessage");
      newEmailNotif.isValid = false;
      setEmailNotif(newEmailNotif);
    }
  };

  const sendResetEmail = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/sendResetPasword`,
        {
          email: emailState.email,
        }
      );

      if (response.status === 200) {
        if (response.data.success === true) {
          history.push(`successResetPassword`);
        } else if (response.data.success === false) {
          notifyAccountError();
        }
      }
    } catch (error) {}

    resetForm();
  };

  const formValid = () => {
    if (emailNotif.isValid) {
      return true;
    }
    return false;
  };

  const resetForm = () => {};

  return (
    <Container>
      <FormContainer>
        <Title>{t("resetPassword.title")}</Title>
        <FormLabel htmlFor="email">{t("resetPassword.emailLabel")}</FormLabel>
        <InputContainer>
          <FormInput
            onChange={handleMail}
            type="email"
            id="email"
            name="email"
            value={emailState.email}
            placeholder={t("resetPassword.emailPlaceholder")}
          ></FormInput>
          <FieldError isValid={emailNotif.isValid}>
            {emailNotif.message}
          </FieldError>
        </InputContainer>
        <SubmitBtn
          isVisible={formValid()}
          onClick={sendResetEmail}
          className="submit-button"
          type="submit"
        >
          {t("resetPassword.submitBtn")}
        </SubmitBtn>
      </FormContainer>
      <StyledToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </Container>
  );
};

export default ResetPassword;
