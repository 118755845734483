import styled from "styled-components";

export const CropperContainer = styled.div`
  height: 100%;
  width: 100%;

  position: relative;

  box-sizing: border-box;

  & div.reactEasyCrop_CropAreaGrid::before  {
    border: 1px solid #000;
  }

  & div.reactEasyCrop_CropAreaGrid::after  {
    border: 1px solid #000;
  }
`;

export const AddFile = styled.button`
  background-color: #075163;
  border-color: #075163;
  border-radius: 1000px;
  padding: 10px 20px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  text-transform: uppercase;
  transition: background-color 0.2s, border-color 0.2s;
  outline: none;
  &:hover {
    background-color: #003643;
    border-color: #003643;
  }
`;

export const InputFile = styled.input`
display:none;
  }
`;

export const CropperInterface = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: column;
`;

export const InterfaceSection = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
`;

export const SaveImageBtn = styled.button`
  background-color: #13ce66;
  border-color: #13ce66;
  border-radius: 1000px;
  padding: 10px 20px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  transition: background-color 0.2s, border-color 0.2s;
  outline: none;
  text-transform: uppercase;
  outline: none;
  transition: all 0.4s;
  &:hover {
    background-color: #fabe3c;
    border-color: #fabe3c;
  }
  max-width: 400px;
`;

export const ImageSizeWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const SizeLabel = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #0c3944;
  font-weight: bold;
  margin-right: 10px;
`;
export const SizeText = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #0c3944;
  margin-right: 20px;
`;
