import React, { useState, useEffect } from "react";
import CategoryForm from "../../../components/adminCategory/CategoryForm";
import { useDispatch } from "react-redux";
import LoaderAnimation from "../../../components/LoaderAnimation";
import AdminLayout from "../../../components/AdminLayout";
import Breadcrumb from "../../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
const footer = require("../../../datas/footer.json");
const header = require("../../../datas/header.json");

const CategoryCreate = (props) => {
  const [isLoading, setIsloading] = useState(true);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch({
        type: "EDIT_CATEGORY",
        category: {
          title: "",
          header: header["header-1"],
          footer: footer["footer-1"],
          templates: [],
        },
      });
      setIsloading(false);
      //
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminLayout>
      <Breadcrumb
        title={t("categoryCreate.title")}
        link="/category/list"
      ></Breadcrumb>
      {isLoading === true ? (
        <LoaderAnimation></LoaderAnimation>
      ) : (
        <CategoryForm categoryId={undefined}></CategoryForm>
      )}
    </AdminLayout>
  );
};

export default CategoryCreate;
