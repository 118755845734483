import React, { useEffect } from "react";
import styled from "styled-components";
import HeaderAdmin from "./HeaderAdmin";
import FooterAdmin from "./FooterAdmin";
import TemplateChoice from "./TemplateChoice";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import useScrollPosition from "../../utils/useScrollPosition.js";

const StyledToastContainer = styled(ToastContainer).attrs({
  className: "toast-container",
  toastClassName: "toast",
  bodyClassName: "body",
  progressClassName: "progress",
})`
  /* .toast-container */
  width: 400px;

  /* .toast is passed to toastClassName */
  .toast {
    background-color: #13ce66;
  }

  button[aria-label="close"] {
    display: none;
  }

  /* .body is passed to bodyClassName */
  .body {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: #fff;
  }

  /* .progress is passed to progressClassName */
  .progress {
  }
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;

const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 300px);
  box-sizing: border-box;
`;

const HeaderForm = styled.div`
  display: flex;

  min-width: 600px;
  padding: 20px;

  box-sizing: border-box;
`;

const FormLabel = styled.label`
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  color: #0c3944;
  display: block;
  font-weight: bold;
`;

const TextInput = styled.input`
  width: 100%;
  height: 52px;
  font-family: "Raleway", sans-serif;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e0e6ed;
  padding: 16px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  color: #000;
  line-height: 20px;
`;

const TitleWrapper = styled.div`
  width: 70%;
`;

const CreateCategoryBtn = styled.button`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 200px;

  top: 0px;

  height: 50px;
  background-color: #13ce66;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  border-color: #13ce66;
  font-family: "Raleway", sans-serif;
  transition: background-color 0.2s, border-color 0.2s;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  border: none;
  text-transform: uppercase;
  outline: none;
  transition: all 0.4s;
  &:hover {
    background-color: #fabe3c;
    border-color: #fabe3c;
  }
  max-width: 400px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0.5)};
  ${({ isVisible }) => (isVisible ? "" : "pointer-events: none;")}
`;

const UpdateCategoryBtn = styled.button`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  // width: 100%;
  width: 200px;

  top: 0px;

  height: 50px;

  background-color: #13ce66;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  border-color: #13ce66;
  font-family: "Raleway", sans-serif;
  transition: background-color 0.2s, border-color 0.2s;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  border: none;
  text-transform: uppercase;
  outline: none;
  transition: all 0.4s;
  &:hover {
    background-color: #fabe3c;
    border-color: #fabe3c;
  }
  max-width: 400px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0.5)};
  ${({ isVisible }) => (isVisible ? "" : "pointer-events: none;")}
`;

const ContainerRight = styled.div`
  height: 139px;
  width: 1px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  position: ${({ position }) => (position < 60 ? "sticky" : "relative")};
  ${({ position }) =>
    position < 60 ? "top:" + parseInt(position) + "px" : ""};
  right: 0px;
`;

const CategoryForm = ({ categoryId }) => {
  const { t } = useTranslation();
  const admin = useSelector((state) => state.admin);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const notifySuccess = () => {
    toast(t("categoryForm.registerMessage"));
  };

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("save") === "ok") {
      notifySuccess();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formValid = () => {
    if (
      admin.category.title.length > 0 &&
      admin.category.header !== null &&
      admin.category.footer !== null &&
      admin.category.templates.length > 0
    ) {
      return true;
    }
    return false;
  };

  const saveCategory = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/category/add`,
      admin.category,

      {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      }
    );

    if (response.status === 200) {
      history.push(`/category/edit/${response.data._id}?save=ok`);
    }
  };

  const updateCategory = async () => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_DOMAIN}/category/edit/${admin.category._id}`,
      admin.category,

      {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      }
    );

    if (response.status === 200) {
      notifySuccess();
    }
  };
  const scrollPos = useScrollPosition();
  return (
    <>
      <MainContainer>
        <ContainerLeft>
          <HeaderForm>
            <TitleWrapper>
              <FormLabel>{t("title")}</FormLabel>
              <TextInput
                type="text"
                name="title"
                onChange={(event) => {
                  const title = event.target.value;
                  dispatch({
                    type: "EDIT_CATEGORY_TITLE",
                    title: title,
                  });
                }}
                value={admin.category.title}
              ></TextInput>
            </TitleWrapper>
          </HeaderForm>
        </ContainerLeft>
      </MainContainer>
      <MainContainer>
        <ContainerLeft>
          <HeaderAdmin></HeaderAdmin>
          <FooterAdmin></FooterAdmin>
          <TemplateChoice></TemplateChoice>
        </ContainerLeft>
        <ContainerRight position={scrollPos > 120 ? 0 : 120}>
          {admin.category._id === undefined ? (
            <CreateCategoryBtn
              isVisible={formValid()}
              onClick={() => {
                saveCategory();
              }}
            >
              {t("createCategoryBtn")}
            </CreateCategoryBtn>
          ) : (
            <UpdateCategoryBtn
              isVisible={formValid()}
              onClick={() => {
                updateCategory();
              }}
            >
              {t("categoryForm.registerBtn")}
            </UpdateCategoryBtn>
          )}
        </ContainerRight>

        <StyledToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
      </MainContainer>
    </>
  );
};

export default CategoryForm;
