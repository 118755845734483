import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: space-between;
  height: 100%;
  border-sizing: border-box;
`;

export const ColLeft = styled.div`
  width: 200px;
`;

export const ColRight = styled.div`
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
`;
