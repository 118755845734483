import styled from "styled-components";
import { ToastContainer } from "react-toastify";

export const ContainerWrapper = styled.div`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  margin-left: 20px;
  margin-right: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DeviceContainerView = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #eee;
  height: 100%;
`;

export const DeviceSize = styled.div`
  width: ${({ deviceWidth }) => `${deviceWidth}px`};
  height: 100%;
`;

export const HtmlPreview = styled.div`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

export const ScrollContainer = styled.div`
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  margin-right: 20px;
  margin-left: 20px;
  width: calc(100% - 40px);
  padding: 0;
  margin: 0;
`;

export const HtmlCodeWrapper = styled.div`
  & pre code {
    display: flex;
    flex-direction: column;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BtnContainer = styled.div`
  padding: 20px;
`;

export const TextInput = styled.input`
  height: 42px;
  font-family: "Raleway", sans-serif;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e0e6ed;
  padding: 16px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  color: #000;
  line-height: 20px;
  margin-right: 20px;
`;

export const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  height: 42px;
  background-color: #0c3944;
  font-weight: 700;
  border: none;
  font-family: "Raleway", sans-serif;
  transition: background-color 0.2s;
  border-radius: 25px;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  outline: none;
  transition: all 0.4s;
  &:hover {
    background-color: #86003e;
  }
`;

export const CopyBtn = styled(Btn)``;

export const SendNewsletterBtn = styled(Btn)`
  opacity: ${({ isValid }) => (isValid ? 1 : 0.5)};
  ${({ isValid }) => (isValid ? "" : "pointer-events: none;")}
`;

export const FormLabel = styled.label`
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  color: #0c3944;
  display: block;
  font-weight: bold;
`;

export const InterfaceList = styled.ul`
  display: flex;
  align-items: center;
`;

export const InterfaceItem = styled.li`
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
  padding-left: 20px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    background: #fabe3c;
    border-radius: 1000px;
    width: 6px;
`;

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: "toast-container",
  toastClassName: "toast",
  bodyClassName: "body",
  progressClassName: "progress",
})`
  /* .toast-container */
  width: 400px;

  /* .toast is passed to toastClassName */
  .toast {
    background-color: #13ce66;
  }

  button[aria-label="close"] {
    display: none;
  }

  /* .body is passed to bodyClassName */
  .body {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: #fff;
  }

  /* .progress is passed to progressClassName */
  .progress {
  }
`;
