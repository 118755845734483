import styled from "styled-components";

export const LangSelector = styled.select`
  background: none;
  border: none;
  outline: none;
  color: #000;
  font-family: Raleway, sans-serif;
  font-size: 20px;
  font-weight: 900;
  -moz-appearance: none;
  -webkit-appearance: none;
`;
