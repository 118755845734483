import React from "react";

import TitleEditor from "../components/editorFields/TitleEditor";
import TextareaEditor from "../components/editorFields/TextareaEditor";
import ImgEditor from "../components/editorFields/ImgEditor";
import BtnEditor from "../components/editorFields/BtnEditor";
import TagEditor from "../components/editorFields/TagEditor";
import BackgroundColorEditor from "../components/editorFields/BackgroundColorEditor";
import DividerEditor from "../components/editorFields/DividerEditor";
import TemplateBgColorEditor from "../components/editorFields/TemplateBgColorEditor";
import MarginBottomEditor from "../components/editorFields/MarginBottomEditor";
import MarginTopEditor from "../components/editorFields/MarginTopEditor";
import CanvasImageEditor from "../components/editorFields/CanvasImageEditor";
import SwitchTagEditor from "../components/editorFields/SwitchTagEditor";
import EventImageEditor from "../components/editorFields/EventImageEditor";

const fieldDisplayer = (template, fieldType, fieldId) => {
  const { model } = template.content[fieldType][fieldId];
  let templateEditor = null;

  switch (model) {
    case "title":
      templateEditor = (
        <TitleEditor templateId={template.id} titleId={fieldId}></TitleEditor>
      );

      break;
    case "divider":
      templateEditor = (
        <DividerEditor
          templateId={template.id}
          divider={fieldId}
        ></DividerEditor>
      );

      break;
    case "switchTag":
      templateEditor = (
        <SwitchTagEditor
          templateId={template.id}
          switchTag={fieldId}
        > </SwitchTagEditor>
      );

      break;


    case "templateBgColor":
      templateEditor = (
        <TemplateBgColorEditor
          templateId={template.id}
          bgColor={fieldId}
        ></TemplateBgColorEditor>
      );

      break;

    case "marginBottom":
      templateEditor = (
        <MarginBottomEditor
          templateId={template.id}
          marginBottom={fieldId}
        ></MarginBottomEditor>
      );

      break;

    case "marginTop":
      templateEditor = (
        <MarginTopEditor
          templateId={template.id}
          marginBottom={fieldId}
        ></MarginTopEditor>
      );

      break;

    case "text":
      templateEditor = (
        <TextareaEditor
          key={`textarea-${template.id}`}
          templateId={template.id}
          textId={fieldId}
        ></TextareaEditor>
      );

      break;
    case "image":
      templateEditor = (
        <ImgEditor templateId={template.id} imageId={fieldId}></ImgEditor>
      );

      break;
    case "button":
      templateEditor = (
        <BtnEditor templateId={template.id} btnId={fieldId}></BtnEditor>
      );

      break;
    case "tag":
      templateEditor = (
        <TagEditor templateId={template.id} tagId={fieldId}></TagEditor>
      );

      break;
    case "backgroundColor":
      templateEditor = (
        <BackgroundColorEditor
          templateId={template.id}
          bgId={fieldId}
        ></BackgroundColorEditor>
      );

      break;
    case "canvas-image":
      templateEditor = (
        <CanvasImageEditor
          templateId={template.id}
          imageId={fieldId}
        ></CanvasImageEditor>
      );

      break;
    case "event-image":
      templateEditor = (
        <EventImageEditor
          templateId={template.id}
          imageId={fieldId}
        ></EventImageEditor>
      );

      break;
    default:
      templateEditor = null;
  }

  return templateEditor;
};

export default fieldDisplayer;
