import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdminHandleImage from "./AdminHandleImage";

const CloseBtn = styled.a`
 
  text-decoration:none
  font-size: 20px;
  color: #000;
`;

const ModalContainer = styled.div`
  position: relative;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 20px;
  padding: 40px;

  box-sizing: border-box;
  box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

const AdminModal = (props) => {
  const adminModalRef = useRef();
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  useOnClickOutside(adminModalRef, () =>
    dispatch({ type: "CLOSE_ADMIN_MODAL" })
  );

  const modalContentDisplayer = () => {
    let contentToDisplay;
    switch (admin.modal.adminContent) {
      case "admin-crop-image":
        contentToDisplay = <AdminHandleImage footer={admin.modal.isFooter}></AdminHandleImage>;
        break;

      default:
        contentToDisplay = "vide";
    }

    return contentToDisplay;
  };

  return (
    <div
      className={`modal-wrapper ${admin.modal.isOpen ? "visible" : ""}`}
      id="login-modal"
    >
      <ModalContainer>
        <CloseBtn
          href="#close"
          className="close"
          onClick={(event) => {
            event.stopPropagation();
            dispatch({ type: "CLOSE_ADMIN_MODAL" });
          }}
        >
          <FontAwesomeIcon icon="times" />
        </CloseBtn>

        {modalContentDisplayer()}
      </ModalContainer>
    </div>
  );
};

// Hook
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export default AdminModal;
