import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const ManualColorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;


const ColorInputWrapper = styled.div`
  width: 200px;
  margin-right: 20px;
`;

const TextInput = styled.input`
  width: 100%;
  height: 52px;
  font-family: "Raleway", sans-serif;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e0e6ed;
  padding: 16px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  color: #000;
  line-height: 20px;
`;


const AddColorBtn = styled.button`
  border-color: #fff;
  border-radius: 1000px;
  height: 50px;
  width: 50px;
  border-color: #a7a7a7;
  color: #fff;
  background-color: ${({ btnData }) =>
        btnData.isValid === true ? btnData.newColor : "#FFF"};
  opacity: ${({ btnData }) => (btnData.isValid ? 1 : 0.5)};
  ${({ btnData }) => (btnData.isValid ? "" : "pointer-events: none;")}
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  border-width: 4px;
  border-style: dashed;
  transition: background-color 0.2s, border-color 0.2s;
  cursor: pointer;
  outline: none;
`;




const AddNewColorHeader = props => {

    const [manualColor, setManualColor] = useState("");
    const [isValidColor, setIsValidColor] = useState(false);
    const dispatch = useDispatch();

    const checkHexaColor = (hexaValue) => {
        const re = new RegExp("^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$");
        if (re.test(hexaValue)) {
            setIsValidColor(true);
        } else {
            setIsValidColor(false);
        }
    };


    return (<ManualColorWrapper>
        <ColorInputWrapper>
            <TextInput
                type="text"
                value={manualColor}
                placeholder="ex: #E6E6E6"
                onChange={(event) => {
                    const newManualColor = event.target.value;
                    checkHexaColor(newManualColor);

                    setManualColor(newManualColor);
                }}
            ></TextInput>
        </ColorInputWrapper>
        <AddColorBtn
            btnData={{ isValid: isValidColor, newColor: manualColor }}
            onClick={() => {
                dispatch({
                    type: "UPDATE_HEADER_BG_COLOR",
                    bgColor: manualColor,
                });
            }}
        ></AddColorBtn>
    </ManualColorWrapper>);

}


export default AddNewColorHeader;