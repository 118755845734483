import styled from "styled-components";
import { Link } from "react-router-dom";
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 91px;
  margin-bottom: 20px;
`;

export const UserNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  background-color: #0c3944;
  color: #fff;
  font-size: 12px;
  line-height: 24px;
  border-radius: 50%;
  margin-left: 10px;
`;

export const Title = styled.h1`
  color: #0c3944;
  font-family: Raleway, sans-serif;
  font-size: 26px;
  font-weight: 800;
  display: flex;
  align-items: center;
  margin-left: 45px;
`;

export const AddUserBtn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  background-color: #13ce66;
  border-color: #13ce66;
  border-radius: 1000px;
  padding: 10px 20px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  border-width: 1px;
  border-style: solid;
  text-decoration: none;
  border: none;
  font-family: "Raleway", sans-serif;
  transition: background-color 0.2s, border-color 0.2s;
  &:hover {
    background-color: #fabe3c;
    border-color: #fabe3c;
  }
  & span {
    margin-left: 4px;
  }
`;
