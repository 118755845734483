import React from "react";
import Editor from "../../utils/editFunctions";

const Template22 = ({ template, statusMode }) => {
  const editor = new Editor(template, statusMode);

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      {/*[if mso | IE]>
      <table
        align="center" border="0" cellpadding="0" cellspacing="0" class="content-padding-outlook" style="width:600px;" width="600"
      >
        <tr>
          <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
      <![endif]*/}
      <div
        className="content-padding"
        style={{
          background: "#FFFFFF",
          backgroundColor: "#FFFFFF",
          margin: "0px auto",
          maxWidth: "600px",
        }}
      >
        <table
          align="center"
          border={0}
          cellPadding={0}
          cellSpacing={0}
          style={{
            background: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  direction: "ltr",
                  fontSize: "0px",
                  padding: `${editor.handleTopMargin()}  10% ${editor.handleBottomMargin()}  10%`,
                  textAlign: "center",
                }}
              >
                {/*[if mso | IE]>
                  <table border="0" cellpadding="0" cellspacing="0">
                    <tr>
                      <td style="vertical-align:top;width:580px;">
                <![endif]*/}
                <div
                  className="mj-column-per-100 mj-outlook-group-fix"
                  style={{
                    fontSize: "0px",
                    textAlign: "left",
                    direction: "ltr",
                    display: "inline-block",
                    verticalAlign: "top",
                    width: "100%",
                  }}
                >
                  <table
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            backgroundColor: editor.handleBgColor(
                              "backgroundColors",
                              "backgroundColor-1"
                            ),
                            verticalAlign: "top",
                            padding: 0,
                          }}
                        >
                          <table
                            border={0}
                            cellPadding={0}
                            cellSpacing={0}
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td
                                  align="center"
                                  style={{
                                    fontSize: "0px",
                                    padding: "10px 25px",
                                    paddingBottom: "0px",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontFamily:
                                        '"Raleway", "Arial-Black", sans-serif',
                                      fontSize: "99px",
                                      fontWeight: "bold",
                                      lineHeight: "106px",
                                      textAlign: "center",
                                      color: editor.handleColorTitle("title-1"),
                                    }}
                                  >
                                    {editor.handleTitle("title-1")}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="center"
                                  style={{
                                    fontSize: "0px",
                                    padding: "10px 25px",
                                    paddingRight: "10%",
                                    paddingBottom: "60px",
                                    paddingLeft: "10%",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontFamily: '"Lato", Arial, sans-serif',
                                      fontSize: "16px",
                                      fontWeight: 800,
                                      lineHeight: "25px",
                                      textAlign: editor.handleTextAlign("text-1"),
                                      color: "#F8F9FA",
                                    }}
                                  >
                                    {editor.handleText("text-1")}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/*[if mso | IE]>
      </td>

  </tr>

            </table>
          <![endif]*/}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/*[if mso | IE]>
    </td>
  </tr>
</table>
<![endif]*/}
      {/* template 22 */}
    </div>
  );
};

export default Template22;
