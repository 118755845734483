import React from "react";
import styled from "styled-components";

const SelectInput = styled.select`
  display: block;
  width: 100%;
  height: 35px;
  margin: 10px auto;
  background: #fff;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  padding: 5px;
  font-size: 16px;
  border: 2px solid #fff;
  transition: all 0.3s ease;
  border-radius: 5px;
  outline: none;
  &:focus {
    border: 2px solid #1abc9d;
  }
`;

const SelectField = ({ action, labelName, optionValue, optionValues }) => {
  const renderOptions = () => {
    return optionValues.map((element, index) => {
      return (
        <option key={`${labelName})-${index})`} value={element.data}>
          {element.name}
        </option>
      );
    });
  };

  return (
    <SelectInput value={optionValue} onChange={action}>
      {renderOptions()}
    </SelectInput>
  );
};

export default SelectField;
