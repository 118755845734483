import React, { useState, useEffect } from "react";
import CategoryForm from "../../../components/adminCategory/CategoryForm";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import LoaderAnimation from "../../../components/LoaderAnimation";
import { useTranslation } from "react-i18next";
import AdminLayout from "../../../components/AdminLayout";
import Breadcrumb from "../../../components/Breadcrumb";

const CategoryEdit = (props) => {
  const [isLoading, setIsloading] = useState(true);
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { categoryId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/category/${categoryId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await dispatch({
        type: "EDIT_CATEGORY",
        category: response.data,
      });

      await setIsloading(false);
    };

    //

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminLayout>
      <Breadcrumb
        title={t("categoryEdit.title", { categoryTitle: admin.category.title })}
        link="/category/list"
      ></Breadcrumb>
      {isLoading === true ? (
        <LoaderAnimation></LoaderAnimation>
      ) : (
        <CategoryForm categoryId={categoryId}></CategoryForm>
      )}
    </AdminLayout>
  );
};

export default CategoryEdit;
