import React, { useRef, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Container,
  HtmlPreview,
  ScrollContainer,
  HtmlCodeWrapper,
  FormLabel,
  ContainerWrapper,
  SendNewsletterBtn,
  TextInput,
  FormWrapper,
  InterfaceList,
  InterfaceItem,
  CopyBtn,
  DeviceContainerView,
  DeviceSize,
  StyledToastContainer,
} from "./styles";
import { useSelector, useDispatch } from "react-redux";

import Prism from "prismjs";
import axios from "axios";
import templateDisplayer from "../../../utils/templateDisplayer.js";
import HtmlFileDownload from "../HtmlFileDownload";
import renderHtmlContent from "./renderHtmlContent";

const HtmlTab = (props) => {
  const { t } = useTranslation();
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const [emailTarget, setEmailTarget] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const notifySuccess = (message) => {
    toast(message);
  };
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const codeHtmlToKeep = useRef({ current: "" });

  const getDeviceWidth = (selectedLink) => {
    let size = 0;
    switch (selectedLink) {
      case "HtmlDesktop":
        size = 800;
        break;
      case "HtmlMobile":
        size = 320;
        break;
      default:
        size = 0;
    }

    return size;
  };

  const checkEmail = (email) => {
    if (typeof email !== "undefined" && email !== "") {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
      }
    } else {
      setIsValidEmail(false);
    }
  };

  const renderCodeHtml = async () => {
    if (codeHtmlToKeep === null) {
    } else if (codeHtmlToKeep.current.innerHTML !== undefined) {
      await dispatch({
        type: "UPDATE_HTML_CODE_FOR_NEWSLETTER",
        htmlCode: renderHtmlContent(codeHtmlToKeep.current.outerHTML),
      });
    }
    await Prism.highlightAll();
    // return codeHtmlToKeep.current.outerHTML.toString();
  };

  useEffect(() => {
    renderCodeHtml();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsletterBuilder.updateHtmlCode]);

  const sendHtmlJson = async () => {
    const htmlData = {
      content: encodeURI(renderHtmlContent(codeHtmlToKeep.current.outerHTML)),
      email: emailTarget,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/sendTestEmail`,
      htmlData,
      {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      }
    );

    if (response.status === 200) {
      notifySuccess("Le mail a bien été envoyé");
    }
  };

  return (
    <>
      <ContainerWrapper
        isVisible={newsletterBuilder.newsletterSelectedTab === "HtmlTab"}
      >
        <InterfaceList>
          <InterfaceItem>
            <FormLabel>{t("htmlTab.sendNewsletterLabel")}</FormLabel>

            <FormWrapper>
              <TextInput
                type="text"
                placeholder="email"
                value={emailTarget}
                onChange={(event) => {
                  const newEmailTarget = event.target.value;
                  setEmailTarget(newEmailTarget);
                  checkEmail(newEmailTarget);
                }}
              ></TextInput>

              <SendNewsletterBtn isValid={isValidEmail} onClick={sendHtmlJson}>
                {t("htmlTab.sendNewsletterBtn")}
              </SendNewsletterBtn>
            </FormWrapper>
          </InterfaceItem>
          <InterfaceItem>
            <FormLabel>{t("htmlTab.downloadFileLabel")}</FormLabel>
            <HtmlFileDownload></HtmlFileDownload>
          </InterfaceItem>
          <InterfaceItem>
            <FormLabel>{t("htmlTab.copyHtmlLabel")}</FormLabel>
            <CopyBtn
              onClick={() => {
                navigator.clipboard.writeText(
                  renderHtmlContent(codeHtmlToKeep.current.outerHTML)
                );
              }}
            >
              {t("htmlTab.copyHtmlBtn")}
            </CopyBtn>
          </InterfaceItem>
        </InterfaceList>
        <Container>
          <ScrollContainer>
            {/* <Highlight className="Markdown">{renderCodeHtml()}</Highlight> */}
            <HtmlCodeWrapper>
              <pre className="code-output">
                <code className={`language-markup`}>
                  {newsletterBuilder.newsletterHtmlCode}
                </code>
              </pre>
            </HtmlCodeWrapper>
          </ScrollContainer>
        </Container>
        <HtmlPreview isVisible={false} ref={codeHtmlToKeep}>
          {templateDisplayer(newsletterBuilder.header)}
          {newsletterBuilder.columns["newsletter-column"].templateIds.map(
            (templateId, index) => {
              const template = newsletterBuilder.templates[templateId];
              let model = [templateDisplayer(template)];
              return model;
            }
          )}
          {templateDisplayer(newsletterBuilder.footer)}
        </HtmlPreview>
      </ContainerWrapper>
      <ContainerWrapper
        isVisible={
          newsletterBuilder.newsletterSelectedTab === "HtmlDesktop" ||
          newsletterBuilder.newsletterSelectedTab === "HtmlMobile"
        }
      >
        <ScrollContainer>
          <DeviceContainerView>
            <DeviceSize
              deviceWidth={getDeviceWidth(
                newsletterBuilder.newsletterSelectedTab
              )}
            >
              <iframe
                title="device view"
                srcDoc={
                  newsletterBuilder.newsletterSelectedTab === "HtmlDesktop" ||
                  newsletterBuilder.newsletterSelectedTab === "HtmlMobile"
                    ? renderHtmlContent(codeHtmlToKeep.current.outerHTML)
                    : null
                }
                height="100%"
                width="100%"
              ></iframe>
            </DeviceSize>
          </DeviceContainerView>
        </ScrollContainer>
      </ContainerWrapper>
      <StyledToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
};

export default HtmlTab;
