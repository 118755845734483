import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import TemplateEditor from "../TemplateEditor";

const Item = styled.div`
  //background-color: ${(props) => (props.isDragging ? "green" : "red")};
`;

const TemplateNewsletterItems = (props) => {
  const [templateSelected, setTemplateSelected] = useState({
    isSelected: false,
    templateId: null,
    isActived: false,
    editorInterface: null,
  });
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);

  return (
    <>
      {newsletterBuilder.columns["newsletter-column"].templateIds.map(
        (template, index) => {
          return (
            <Item id={template} key={`newsletter-${template}`}>
              <TemplateEditor
                templateSelected={templateSelected}
                setTemplateSelected={setTemplateSelected}
                templateId={template}
              ></TemplateEditor>
            </Item>
          );
        }
      )}
    </>
  );
};

export default TemplateNewsletterItems;
