import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import LoaderAnimation from "./LoaderAnimation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const Container = styled.div`
  padding: 20px;
`;

const CategoryList = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const CategoryItem = styled.li`
  position: relative;
  width: 300px;
  flex-basis: 300px;
  margin-right: 20px;
  margin-left: 20px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  justify-content: space-between;
  margin-bottom: 20px;

  align-items: center;
  box-sizing: border-box;
  box-shadow: 7px 7px 5px #0000000d;
  position: relative;
  color: rgb(12, 57, 68);
  font-size: 16px;
  font-family: "Raleway", sans-serif;
`;

const ItemContainer = styled.div`
  padding: 20px;
`;

const TitleInfo = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 21px;
  font-weight: 800;
  margin-bottom: 10px;
`;

const DateInfo = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 21px;
  font-weight: 300;
  margin-bottom: 10px;
`;

const BtnContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.8s;
  &:hover {
    opacity: 1;
  }
`;

const EditBtn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background-color: #13ce66;
  border-radius: 100px;
  cursor: pointer;
  font-size: 80px;
  color: #fff;
  border: none;
  outline: none;
`;

const ImgWrapper = styled.div`
  width: 298px;
  height: 188px;
  border-radius: 10px 10px 0 0;
  background-color: ${({ bgColor }) => bgColor};
`;

const ItemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const Title = styled.h1`
  position: relative;
  font-size: 30px;
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  margin-bottom: 20px;
  color: #0c3944;
  padding-left: 20px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    background: #fabe3c;
    border-radius: 1000px;
    width: 6px;
`;
const ModelChoice = (props) => {
  const { t } = useTranslation();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/categories`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await setCategoryList(response.data);

      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Title>{t("modelChoice.title")}</Title>
      {isLoading === true ? (
        <LoaderAnimation></LoaderAnimation>
      ) : (
        <CategoryList>
          {categoryList.map((element, index) => {
            return (
              <CategoryItem key={`category+${index}`}>
                <ImgWrapper
                  bgColor={
                    element.header.content.backgroundColors["backgroundColor-1"]
                      .bgColor
                  }
                >
                  <ItemImg
                    src={
                      element.header.hasFullWidthImage
                      ? element.header.content.images["image-3"].imgSrc
                      : element.header.content.images["image-1"].imgSrc
                    }
                  />
                </ImgWrapper>
                <ItemContainer>
                  <TitleInfo>{element.title}</TitleInfo>

                  <DateInfo>
                    {moment(element.createdDate).format("DD-MM-YYYY")}
                  </DateInfo>

                  <BtnContainer>
                    <EditBtn to={`/newsletter/create/${element._id}`}>
                      <FontAwesomeIcon icon="plus" />
                    </EditBtn>
                  </BtnContainer>
                </ItemContainer>
              </CategoryItem>
            );
          })}
        </CategoryList>
      )}
    </Container>
  );
};

export default ModelChoice;
