import styled from "styled-components";
import { Link } from "react-router-dom";

export const List = styled.ul`
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 91px;
  margin-bottom: 20px;
`;

export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  
`;

export const ItemInfo = styled.div`
  flex: 2;
`;

export const ItemPrompt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ConfirmDeleteBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width:100px;
  height: 50px;
  background-color: #b71934;
  border-radius: 25px;
  margin-left: 10px;
  font-size: 16px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    background-color: #1b3815;
  }
`;

export const CancelDeleteBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width:100px;
  height: 50px;
  background-color: #13ce66;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  border: none;

  transition: all 0.4s;
  &:hover {
    background-color: #fabe3c;
  }
`;



export const ItemAction = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

export const ItemInfoLabel = styled.div`
  flex: 2;
  
`;

export const ItemActionLabel = styled.div`
  flex: 1;
  
`;

export const Title = styled.h1`
  color: #0c3944;
  font-family: Raleway, sans-serif;
  font-size: 26px;
  font-weight: 800;
  display: flex;
  align-items: center;
  margin-left: 45px;
`;

export const CategoryNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  background-color: #0c3944;
  color: #fff;
  font-size: 12px;
  line-height: 24px;
  border-radius: 50%;
  margin-left: 10px;
`;

export const CategoryItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  align-items: center;
  cursor: pointer;
  line-height: 24px;
  padding: 10px 20px;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.05);
  position: relative;
  color: rgb(12, 57, 68);
  font-size: 16px;
  font-family: "Raleway", sans-serif;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    background: #fabe3c;
    border-radius: 1000px;
    width: 6px;
  }
`;

export const ListHeader = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #0c3944;
  padding: 20px;
  font-family: "Raleway", sans-serif;
`;

export const AddTemplatesBtn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  background-color: #13ce66;
  border-color: #13ce66;
  border-radius: 1000px;
  padding: 10px 20px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  border-width: 1px;
  border-style: solid;
  text-decoration: none;
  border: none;
  font-family: "Raleway", sans-serif;
  transition: background-color 0.2s, border-color 0.2s;
  &:hover {
    background-color: #fabe3c;
    border-color: #fabe3c;
  }
  & span {
    margin-left: 4px;
  }
`;

export const EditBtn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #13ce66;
  border-radius: 25px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  border: none;

  transition: all 0.4s;
  &:hover {
    background-color: #fabe3c;
  }
`;

export const DeleteBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #b71934;
  border-radius: 25px;
  margin-left: 10px;
  font-size: 16px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    background-color: #1b3815;
  }
`;
