const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 * @param {string} imageType - optional image mime type
 * @param {boolean} noCrop - disable crop
 */
export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0, imageType = "image/png", noCrop = false) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");

  const ctx = canvas.getContext("2d");

  if (noCrop) {
    const maxWidth = 2000;
    let w = image.width;
    let h = image.height;
    if (w > maxWidth) {
      h = image.height / image.width * maxWidth;
      w = maxWidth;
    }
    canvas.width = w;
    canvas.height = h;
    ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, w, h);
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(file);
      }, imageType, 0.9);
    });
  }

  const safeArea = Math.max(image.width, image.height) * 2;

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );
  const maxSize = 2000;
  let finalCanvas;
  if (canvas.width > maxSize) {
    const resizeCanvas = document.createElement('canvas');
    const resizeCtx = resizeCanvas.getContext("2d");
    let resizeWidth;
    let resizeHeight;
    if (canvas.width > canvas.height) {
      resizeWidth = maxSize;
      resizeHeight = Math.floor(canvas.height / canvas.width * maxSize);
    }
    else {
      resizeHeight = maxSize;
      resizeWidth = Math.floor(canvas.width / canvas.height * maxSize);
    }
    resizeCanvas.width = resizeWidth;
    resizeCanvas.height = resizeHeight;
    resizeCtx.drawImage(canvas, 0, 0, resizeWidth, resizeHeight);
    finalCanvas = resizeCanvas;
  }
  else {
    finalCanvas = canvas;
  }
  return new Promise((resolve) => {
    finalCanvas.toBlob((file) => {
      resolve(file);
    }, imageType, 0.9);
  });

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');
/*
  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file,) => {
      resolve(file);
    });
  });
 */
}
