import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoaderAnimation from "./LoaderAnimation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "moment/min/locales.min";

const Container = styled.div`
  font-family: "Raleway", sans-serif;
  padding: 20px;
`;

const CategoryList = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const CategoryItem = styled.li`
  position: relative;
  width: 300px;
  flex-basis: 300px;
  margin-right: 20px;
  margin-left: 20px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  justify-content: space-between;
  margin-bottom: 20px;

  align-items: center;
  box-sizing: border-box;
  box-shadow: 7px 7px 5px #0000000d;
  position: relative;
  color: rgb(12, 57, 68);
  font-size: 16px;
  font-family: "Raleway", sans-serif;
`;

const TitleInfo = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 21px;
  font-weight: 800;
  margin-bottom: 10px;
`;

const CategoryInfo = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CategoryTag = styled.div`
  display: none;
  padding: 5px 10px;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: normal;
  border-radius: 100px;
  background-color: #a7a7a7;
  color: #fff;
`;

const ItemContainer = styled.div`
  padding: 20px;
`;

const ItemAction = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImgWrapper = styled.div`
  width: 298px;
  height: 188px;
  border-radius: 10px 10px 0 0;
  background-color: ${({ bgColor }) => bgColor};
`;

const ItemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const EditBtn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #13ce66;
  border-radius: 25px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  border: none;
  outline: none;
  transition: all 0.4s;
  &:hover {
    background-color: #fabe3c;
  }
`;

const DeleteBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #b71934;
  border-radius: 25px;
  margin-left: 10px;
  font-size: 16px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    background-color: #1b3815;
  }
`;

const Title = styled.h1`
position: relative;
font-size: 30px;
font-family: "Raleway", sans-serif;
font-weight: 800;
margin-bottom: 20px;
color: #0c3944;
padding-left: 20px;
&::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  background: #fabe3c;
  border-radius: 1000px;
  width: 6px;
`;

const CloneBtn = styled.button`
  background-color: #075163;
  border-color: #075163;
  border-radius: 1000px;
  padding: 10px 20px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  transition: background-color 0.2s, border-color 0.2s;
  outline: none;
  &:hover {
    background-color: #003643;
    border-color: #003643;
  }
`;

const DateInfo = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 21px;
  font-weight: 300;
  margin-bottom: 10px;
`;

const UserNewsletterList = (props) => {
  const { t } = useTranslation();
  const [deleted, setDeleted] = useState(null);
  const [cloned, setCloned] = useState(null);

  const deleteNewsletter = async (id) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_DOMAIN}/newsletter/${id}`,
      {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      }
    );

    if (response.status === 200) {
      setDeleted(new Date());
    }
  };

  const cloneNewsletter = async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/newsletter/clone/${id}`,
      {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      }
    );

    if (response.status === 200) {
      setCloned(new Date());
    }
  };

  const [newsletterList, setNewsletterList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/user/${user.id}/newsletters`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await setNewsletterList(response.data);

      await setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted, cloned]);

  return (
    <Container>
      <Title>{t("userNewsletterList.title")}</Title>
      {isLoading === true ? (
        <LoaderAnimation></LoaderAnimation>
      ) : (
          <CategoryList>
            {newsletterList.newsletters.map((element, index) => {
              return (
                <CategoryItem key={`newsletter+${index}`}>
                  <ImgWrapper
                    bgColor={
                      element.category.header.content.backgroundColors[
                        "backgroundColor-1"
                      ].bgColor
                    }
                  >
                    <ItemImg
                      src={
                        element.category.header.hasFullWidthImage
                          ? element.category.header.content.images["image-3"].imgSrc
                          : element.category.header.content.images["image-1"].imgSrc
                      }
                    />
                  </ImgWrapper>
                  <ItemContainer>
                    <TitleInfo>{element.title}</TitleInfo>

                    <DateInfo>
                      {moment(element.createdDate)
                        .locale("fr")

                        .format("lll")}
                    </DateInfo>
                    <CategoryInfo>
                      <CategoryTag>{element.category.title}</CategoryTag>
                    </CategoryInfo>
                    <ItemAction>
                      <CloneBtn
                        onClick={() => {
                          cloneNewsletter(element._id);
                        }}
                      >
                        Dupliquer
                    </CloneBtn>

                      <DeleteBtn
                        onClick={() => {
                          deleteNewsletter(element._id);
                        }}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </DeleteBtn>

                      <EditBtn to={`/newsletter/edit/${element._id}`}>
                        <FontAwesomeIcon icon="pencil-alt" />
                      </EditBtn>
                    </ItemAction>
                  </ItemContainer>
                </CategoryItem>
              );
            })}
          </CategoryList>
        )}
    </Container>
  );
};

export default UserNewsletterList;
