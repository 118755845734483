import React from "react";
import styled from "styled-components";
import PickBgColor from "./PickBgColor";
import { useSelector, useDispatch } from "react-redux";

const ContainerWrapper = styled.div``;

const BackgroundColorEditor = ({ templateId, bgId }) => {
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();

  const editionMode =
    newsletterBuilder.selectedTemplateId === templateId ? true : false;

  const changeBackgroundColor = (event) => {
    const bgColor = event.target.value;

    dispatch({
      type: "CHANGE_BOX_BACKGROUND_COLOR",
      data: {
        templateId: templateId,
        bgId: bgId,
        bgColor: bgColor,
      },
    });
  };

  const renderButton = () => {
    if (editionMode === true) {
      return (
        <ContainerWrapper
          onMouseEnter={() => {
            dispatch({
              type: "ADD_HIGHLIGHT_ON_FIELD",
              AttributeId: bgId,
            });
          }}
          onMouseLeave={() => {
            // if (isSelectedField === false) {
            dispatch({
              type: "REMOVE_HIGHLIGHT_ON_FIELD",
            });
            // }
          }}
        >
          {newsletterBuilder.templates[templateId].content.backgroundColors[
            bgId
          ].bgColor === undefined ? null : (
            <PickBgColor
              labelName="background"
              action={changeBackgroundColor}
            ></PickBgColor>
          )}
        </ContainerWrapper>
      );
    } else {
      return newsletterBuilder.templates[templateId].content.backgroundColors[
        bgId
      ].bgColor;
    }
  };

  return <>{renderButton()}</>;
};

export default BackgroundColorEditor;
