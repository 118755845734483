import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import Checkbox from './Checkbox';
import {useTranslation} from 'react-i18next';
import UrlEditor from './UrlEditor';

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${({ disabled }) => (disabled !== true ? 1 : 0.2)};
  ${({ disabled }) => (disabled !== true ? "" : "pointer-events: none;")}
`;

const ImgHover = styled.div`
  width: 130px;
  height: 100%;
  z-index: 10;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: yellow;
  }
  &:hover button {
    opacity: 1;
  }
`;

const ModalBtn = styled.button`
  border: none;
  transition: opacity 0.5s ease;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  font-size: 30px;
  cursor: pointer;
  //transform: translate(-50%, -50%);
`;


const Container = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

const LAbelContainer = styled.div`
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  width: 100px;
  margin-right: 10px;
  font-weight: bold;
`;

const FieldContainer = styled.div`
  flex: 1;
`;

const DividerLabel = styled.label`
  font-family: "Raleway", sans-serif;
  font-size: 16px;
`;


const ImgEditor = ({ templateId, imageId }) => {
  const { t } = useTranslation();
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();

  const { content } = { ...newsletterBuilder.templates[templateId] };
  const { imgAlt, imgSrc, imgWidth, link, url, disabled } = {
    ...content.images[imageId],
  };

  const renderImg = () => {
    const img = (
      <img
        height="auto"
        src={imgSrc}
        style={{
          border: 0,
          display: "block",
          outline: "none",
          textDecoration: "none",
          height: "130px",
          width: "130px",
          objectFit: "cover",
          fontSize: "13px",
        }}
        alt={imgAlt}
        width={imgWidth}
      />
    );

    return (
      <ContainerWrapper>
        {newsletterBuilder.templates[templateId].content.images[imageId]
          .disabled === undefined ? null : (
          <Container>
            <LAbelContainer>{t("imgEditor.disableLabel")}</LAbelContainer>
            <FieldContainer>
              <DividerLabel htmlFor="image_disabled">
                <Checkbox
                  id="image_disabled"
                  onChange={() => {
                    dispatch({
                      type: "CHANGE_IMAGE_DISABLED",
                      data: {
                        templateId: templateId,
                        imageId: imageId,
                        disabled: !disabled,
                      },
                    });
                  }}
                  checked={disabled}
                ></Checkbox>
                {disabled === true
                  ? t("imgEditor.enable")
                  : t("imgEditor.disable")}
              </DividerLabel>
            </FieldContainer>
          </Container>
        )}
      <ContainerWrapper disabled={disabled}>
      <ImgHover
        onMouseEnter={() => {
          dispatch({
            type: "ADD_HIGHLIGHT_ON_FIELD",
            AttributeId: imageId,
          });
        }}
        onMouseLeave={() => {
          // if (isSelectedField === false) {
          dispatch({
            type: "REMOVE_HIGHLIGHT_ON_FIELD",
          });
          // }
        }}
      >
        {img}
        <ModalBtn
          onClick={(event) => {
            event.preventDefault();

            dispatch({
              type: "OPEN_AND_INIT_IMAGE_UPLOADER",
              data: {
                templateId: templateId,
                imageId: imageId,
              },
            });

            // const newData = { ...data };
            // const newModal = { ...data.modal };
            // newModal.isVisible = true;
            // newModal.templateId = templateId;
            // newModal.fieldType = "images";
            // newModal.fieldId = imageId;
            // newData.modal = newModal;
            // setData(newData);
          }}
        >
          <FontAwesomeIcon icon="camera" />
        </ModalBtn>
        {/* <ImageContainer imgWidth={imgWidth}>
            <ImageCropper
              imgSrc={imgSrc}
              imgWidth={imgWidth}
              imgHeight={imgHeight}
              data={data}
              setData={setData}
              templateId={templateId}
              imageId={imageId}
            ></ImageCropper>
          </ImageContainer> */}
      </ImgHover>
      {content.images[imageId]
        .allowLink === undefined ? null : (
          <ContainerWrapper>
            <Container>
              <LAbelContainer>{t("imgEditor.linkLabel")}</LAbelContainer>
              <FieldContainer>
                <DividerLabel htmlFor="button_disabled">
                  <Checkbox
                    id="button_disabled"
                    onChange={() => {
                      dispatch({
                        type: "CHANGE_IMAGE_LINK",
                        data: {
                          templateId: templateId,
                          imageId: imageId,
                          link: !link,
                        },
                      });
                    }}
                    checked={link}
                  ></Checkbox>
                  {link === true
                    ? t("imgEditor.disableLink")
                    : t("imgEditor.enableLink")}
                </DividerLabel>
              </FieldContainer>
            </Container>
            <ContainerWrapper disabled={!link}>
              <UrlEditor action={(e) => dispatch({
                type: "CHANGE_IMAGE_LINK_URL",
                data: {
                  templateId: templateId,
                  imageId: imageId,
                  url: e.target.value,
                },
              })} link={url}></UrlEditor>
            </ContainerWrapper>
          </ContainerWrapper>
      )}
      </ContainerWrapper>
      </ContainerWrapper>
    );
  };

  return <>{renderImg()}</>;
};

export default ImgEditor;
