import React from "react";
import styled from "styled-components";
import templateDisplayer from "../utils/templateDisplayer.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";

const EditorContainer = styled.div`
  position: relative;
  ${({ isVisible }) =>
    isVisible
      ? `&:before{content: '';display: block;
    position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
    border: 4px solid #b7b7b7;
    }`
      : ""}

  display:flex;
`;

const TemplateColumn = styled.div`
  display: block;

  flex: 1;
`;

const InterfaceContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  display: flex;
  z-index: 10;
`;

const OverlayWrapper = styled.div`
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: ${({ isActived }) => (isActived ? 10 : 1)};
  cursor: pointer;

  &:hover {
    ${({ isVisible }) =>
    isVisible
      ? `background:none;`
      : `background-color:  rgba(121, 123, 202, 0.5);`}
  }
`;

const RightInterface = styled.div`
  position: absolute;
  right: 0px;
  padding: 10px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  z-index: 10;
  box-sizing: border-box;

  ${OverlayWrapper}:hover & {
    display: flex;
  }
`;

const MoveUpBtn = styled.button`
  height: 30px;
  width: 30px;
  background-color: #eee;
  border-radius: 20px;
  font-size: 20px;
  border: none;
  outline: none;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #fff;
    background-color: #000;
  }
`;

const MoveDownBtn = styled.button`
  height: 30px;
  width: 30px;
  background-color: #eee;
  border-radius: 20px;
  font-size: 20px;
  border: none;
  outline: none;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #fff;
    background-color: #000;
  }
`;

const TrashBtn = styled.button`
  height: 30px;
  width: 30px;
  background-color: #eee;
  border-radius: 20px;
  border: none;
  outline: none;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #fff;
    background-color: red;
  }
`;

const EditorButton = styled.button`
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #eee;

  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  &:hover {
    color: #fff;
    background-color: #000;
  }
`;

const TemplateEditor = ({
  templateId,
  templateSelected,
  setTemplateSelected,
}) => {
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();
  const template = newsletterBuilder.templates[templateId];
  const isVisible = templateId === templateSelected.templateId ? true : false;
  const isActived = templateSelected.isActived && isVisible ? true : false;

  return (
    <EditorContainer
      onClick={(event) => {
        if (templateSelected.templateId !== templateId) {
          const newTemplateSelected = { ...templateSelected };

          newTemplateSelected.templateId = templateId;

          newTemplateSelected.isSelected = true;

          newTemplateSelected.isActived = false;

          newTemplateSelected.editorInterface = Object.keys(
            template.content
          ).reduce((object, element) => {
            object[element] = { isOpen: false };

            return object;
          }, {});

          setTemplateSelected(newTemplateSelected);

          dispatch({
            type: "HIDE_NEWSLETTER_MENU",
          });
        }
      }}
      isVisible={isVisible}
    >
      <OverlayWrapper isVisible={isVisible} isActived={isActived}>
        <RightInterface>
          <MoveUpBtn
            onClick={() => {
              dispatch({
                type: "MOVE_NEWLETTER_ITEM",
                data: {
                  templateId: template.id,
                  direction: "up",
                },
              });
            }}
          >
            <FontAwesomeIcon icon="angle-up" />
          </MoveUpBtn>
          <TrashBtn
            onClick={() => {
              dispatch({
                type: "DELETE_NEWLETTER_ITEM",
                templateId: template.id,
              });
            }}
          >
            <FontAwesomeIcon icon="trash" />
          </TrashBtn>
          <MoveDownBtn
            onClick={() => {
              dispatch({
                type: "MOVE_NEWLETTER_ITEM",
                data: {
                  templateId: template.id,
                  direction: "down",
                },
              });
            }}
          >
            <FontAwesomeIcon icon="angle-down" />
          </MoveDownBtn>
        </RightInterface>
        <InterfaceContainer>
          <EditorButton
            isVisible={isVisible}
            onClick={(event) => {
              event.preventDefault();



              const newTemplateSelected = { ...templateSelected };
              newTemplateSelected.isActived = !templateSelected.isActived;

              setTemplateSelected(newTemplateSelected);
              dispatch({
                type: "SHOW_NEWSLETTER_MENU",
              });
              dispatch({
                type: "SELECT_TEMPLATE",
                templateId: template.id,
              });
            }}
          >
            <FontAwesomeIcon icon={isActived ? "pencil-alt" : "pencil-alt"} />
          </EditorButton>

          {/* <EditorInterface template={template}></EditorInterface> */}
        </InterfaceContainer>
      </OverlayWrapper>

      <TemplateColumn>
        {templateDisplayer(template, "EDITION_MODE")}
      </TemplateColumn>
    </EditorContainer>
  );
};

export default TemplateEditor;
