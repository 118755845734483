import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Divider from "../components/templates/options/Divider";

const HoverField = styled.span`
  display: block;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
  ${({ isSelected }) =>
    isSelected
      ? `
      // background-color:rgba(143,188,143, 0.1);
      outline-offset: 10px;
       outline: 5px dashed rgba(143,188,143, 1);`
      : ""};
  width: 100%;
  height: 100%;

  box-sizing: border-box;
`;

const RenderHtml = styled.span`
  display: block;
`;

const FieldContainer = styled.span`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
`;

class Editor {
  constructor(template, statusMode = "NEWSLETTER-FORMAT") {
    this.template = template;
    this.newsletterBuilder = useSelector((state) => state.newsletterBuilder);

    this.statusMode = statusMode;
  }

  renderText = (attribute, content) => {
    if (content) {
      if (this.statusMode === "NEWSLETTER-FORMAT") {
        return (
          <RenderHtml
            className="rendered-html"
            dangerouslySetInnerHTML={{ __html: decodeURI(content) }}
          />
        );

        // <ReactMarkdown source={content} />;
      } else {
        return (
          <FieldContainer>
            <HoverField isSelected={this.isSelected(attribute)}></HoverField>
            <RenderHtml
              className="rendered-html"
              dangerouslySetInnerHTML={{ __html: decodeURI(content) }}
            />
            {/* <ReactMarkdown source={content} /> */}
          </FieldContainer>
        );
      }
    } else {
      if (this.statusMode === "NEWSLETTER-FORMAT") {
        return "\u00a0";
      } else {
        return (
          <FieldContainer>
            <HoverField isSelected={this.isSelected(attribute)}></HoverField>
            {"\u00a0"}
          </FieldContainer>
        );
      }
    }
  };

  renderTitle = (attribute, content) => {
    if (content) {
      if (this.statusMode === "NEWSLETTER-FORMAT") {
        return <RenderHtml>{content}</RenderHtml>;
      } else {
        return (
          <FieldContainer>
            <HoverField isSelected={this.isSelected(attribute)}></HoverField>
            <RenderHtml>{content}</RenderHtml>
          </FieldContainer>
        );
      }
    } else {
      if (this.statusMode === "NEWSLETTER-FORMAT") {
        return "\u00a0";
      } else {
        return (
          <FieldContainer>
            <HoverField isSelected={this.isSelected(attribute)}></HoverField>
            {"\u00a0"}
          </FieldContainer>
        );
      }
    }
  };

  // renderDivider = (attribute, content) => {
  //   if (content) {
  //     if (this.statusMode === "NEWSLETTER-FORMAT") {
  //       return <RenderHtml>{content}</RenderHtml>;

  //       // <ReactMarkdown source={content} />;
  //     } else {
  //       return (
  //         <FieldContainer>
  //           <HoverField isSelected={this.isSelected(attribute)}></HoverField>
  //           <RenderHtml>{content}</RenderHtml>
  //         </FieldContainer>
  //       );
  //     }
  //   } else {
  //     if (this.statusMode === "NEWSLETTER-FORMAT") {
  //       return "\u00a0";
  //     } else {
  //       return (
  //         <FieldContainer>
  //           <HoverField isSelected={this.isSelected(attribute)}></HoverField>
  //           {"\u00a0"}
  //         </FieldContainer>
  //       );
  //     }
  //   }
  // };

  renderHighlightImage = (attribute) => {
    if (this.statusMode === "NEWSLETTER-FORMAT") {
      return "";
    } else {
      return <HoverField isSelected={this.isSelected(attribute)}></HoverField>;
    }
  };

  isSelected = (titleField) => {
    if (
      this.template.id === this.newsletterBuilder.selectedTemplateId &&
      titleField === this.newsletterBuilder.selectedAttributeId
    ) {
      return true;
    }
    return false;
  };

  handleTitle = (titleField) => {
    return this.renderTitle(
      titleField,
      this.template.content.titles[titleField].text
    );
  };

  handleTitleColor = (titleField, defaultColor) => {
    if (this.template.content.titles[titleField].color === undefined) {
      return defaultColor;
    }

    return this.template.content.titles[titleField].color;
  };

  //
  hasAnActiveTitle = (titleField) => {
    if (
      this.template.content.titles[titleField].text === undefined ||
      this.template.content.titles[titleField].text === ""
    ) {
      return false;
    }

    return true;
  };

  handleLinkSelected = (titleField) => {
    if (
      this.isSelected(titleField) === true &&
      this.statusMode !== "NEWSLETTER-FORMAT"
    ) {
      return {
        textDecoration: "none",
        outline: "green dashed 3px",
        display: "block",
      };
    }
    return {
      textDecoration: "none",
    };
  };

  // canvas title

  handleCanvasTitleAlt = (titleField) => {
    return this.template.content["canvas-images"][titleField].text;
  };

  handleCanvasTitleImg = (titleField) => {
    return this.template.content["canvas-images"][titleField].imgSrc;
  };

  handleCanvasTitleWidth = (titleField) => {
    return this.template.content["canvas-images"][titleField].imgWidth;
  };

  // event template

  handleEventImg = (templateName) => {
    return this.template.content["event-images"][templateName].imgSrc;
  };

  handleEventLink = (templateName) => {
    return this.template.content["event-images"][templateName].templateLink;
  };

  // options start
  handleDivider = () => {
    if (this.template.content.options.divider.isActived === true) {
      return <Divider></Divider>;
    }

    return null;
  };

  handleTemplateBgColor = () => {
    if (this.template.content.options.templateBgColor !== undefined) {
      return this.template.content.options.templateBgColor.color;
    }

    return "#FFFFFF";
  };

  handleBottomMargin = () => {
    let marginBottom = this.template.content.options.marginBottom.default;

    switch (this.template.content.options.marginBottom.type) {
      case "normal":
        marginBottom = this.template.content.options.marginBottom.default;
        break;
      case "big":
        marginBottom = this.template.content.options.marginBottom.big;
        break;
      default:
        marginBottom = this.template.content.options.marginBottom.default;
    }

    return marginBottom;
  };

  handleTopMargin = () => {
    let marginTop = this.template.content.options.marginTop.default;

    switch (this.template.content.options.marginTop.type) {
      case "normal":
        marginTop = this.template.content.options.marginTop.default;
        break;
      case "big":
        marginTop = this.template.content.options.marginTop.big;
        break;
      default:
        marginTop = this.template.content.options.marginTop.default;
    }

    return marginTop;
  };

  // options end
  handleColorTitle = (titleField) => {
    if (this.template.content.titles[titleField].color) {
      return this.template.content.titles[titleField].color;
    }
  };

  handleBtnText = (btnField) => {
    return this.renderText(
      btnField,
      this.template.content.buttons[btnField].text
    );

    // if (this.template.content.buttons[btnField].text) {
    //   return this.template.content.buttons[btnField].text;
    // } else {
    //   return "\u00a0";
    // }
  };

  handleTagText = (tagField) => {
    return this.renderText(tagField, this.template.content.tags[tagField].text);
  };

  handleTextColor = (category, field) => {
    return this.template.content[category][field].textColor;
  };

  handleBorderColor = (category, field) => {
    return this.template.content[category][field].borderColor;
  };

  handleBgColor = (category, field) => {
    if (this.template.content[category] !== undefined && this.template.content[category][field] !== undefined) {
      return this.template.content[category][field].bgColor;
    } else {
      return "#FFFFFF";
    }
  };

  handleBgColorIndex = (category, field) => {
    if (this.template.content[category] !== undefined && this.template.content[category][field] !== undefined) {
      return this.template.content[category][field].index || 0;
    } else {
      return 0;
    }
  };

  handleDisabled = (category, field) => {
    if (this.template.content[category] !== undefined) {
      return this.template.content[category][field].disabled;
    } else {
      return false;
    }
  };

  handleUnsubscribeLink = (unsubscribeField) => {
    if (this.template[unsubscribeField] !== undefined) {
      return this.template[unsubscribeField];
    } else {
      return "";
    }
  };

  handleText = (textField) => {
    return this.renderText(
      textField,
      this.template.content.texts && this.template.content.texts[textField] ? this.template.content.texts[textField].text : ''
    );
  };
  handleTextAlign = (textField) => {
    if (this.template.content.texts[textField].align !== undefined) {
      return this.template.content.texts[textField].align;
    }
    return "left";
  };

  handleImagePosition = () => {
    if (this.statusMode === "NEWSLETTER-FORMAT") {
      return "static";
    } else {
      return "relative";
    }
  };

  handleImageHighlight = (imgField) => {
    return this.renderHighlightImage(imgField);
  };

  handleImageWidth = (imageField) => {
    return this.template.content.images[imageField].imgWidth;
  };

  handleImageSrc = (imageField) => {
    return this.template.content.images[imageField].imgSrc;
  };

  handleImageAlt = (imageField) => {
    const alt = this.template.content.images[imageField].imgAlt;
    return alt === 'image' ? '' : alt;
  };

  handleImageLink = (imageField) => {
    return !!this.template.content.images[imageField].link;
  };

  handleImageLinkUrl = (imageField) => {
    return this.template.content.images[imageField].url;
  };

  handleUrl = (category, field) => {

    if (this.template.content[category] !== undefined) {
      return this.template.content[category][field].link;
    }
    return "#";
  };

  handleNumberValue = (category) => {
    if (this.template.content[category] !== undefined) {
      return this.template.content[category];
    }
    return 0;
  };
}

export default Editor;
