import React from "react";
import axios from "axios";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DeleteBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #b71934;
  border-radius: 25px;
  margin-left: 10px;
  font-size: 16px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    background-color: #1b3815;
  }
`;

const EditBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #13ce66;
  border-radius: 25px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  border: none;

  transition: all 0.4s;
  &:hover {
    background-color: #fabe3c;
  }
`;

const Container = styled.div``;

const UserItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  align-items: center;

  padding: 10px 20px;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.05);
  position: relative;
  color: rgb(12, 57, 68);
  font-size: 16px;
  font-family: "Raleway", sans-serif;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    background: #fabe3c;
    border-radius: 1000px;
    width: 6px;
  }
`;

const UserListHeader = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #0c3944;
  padding: 20px;
  font-family: "Raleway", sans-serif;
`;

const ItemInfo = styled.div`
  flex: 2;
`;

const ItemAction = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

const ItemInfoLabel = styled.div`
  flex: 2;
`;

const ItemActionLabel = styled.div`
  flex: 1;
`;

const UserList = ({ userList, setDeleted }) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();

  const deleteUser = async (id) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_DOMAIN}/user/${id}`,
      {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      }
    );

    if (response.status === 200) {

      setDeleted(new Date());
    }
  };

  return (
    <Container>
      <ul>
        <UserListHeader>
          <ItemInfoLabel>{t("adminUserList.name")}</ItemInfoLabel>
          <ItemInfoLabel>{t("adminUserList.email")}</ItemInfoLabel>
          <ItemInfoLabel>{t("adminUserList.role")}</ItemInfoLabel>
          <ItemActionLabel>{t("adminUserList.actions")}</ItemActionLabel>
        </UserListHeader>
        {userList.map((element, index) => {
          return (
            <UserItem key={`user+${element._id}`}>
              <ItemInfo>{element.account.userName}</ItemInfo>
              <ItemInfo>{element.email}</ItemInfo>
              <ItemInfo>{element.role}</ItemInfo>
              <ItemAction>
                <EditBtn
                  onClick={() => {
                    history.push(`/user/edit/${element._id}`);
                  }}
                  className="submit-button"
                  type="submit"
                >
                  <FontAwesomeIcon icon="pencil-alt" />
                </EditBtn>
                <DeleteBtn
                  value={element._id}
                  onClick={(event) => {
                    deleteUser(element._id);
                  }}
                  className="submit-button"
                >
                  <FontAwesomeIcon icon="trash" />
                </DeleteBtn>
              </ItemAction>
            </UserItem>
          );
        })}
      </ul>
    </Container>
  );
};

export default UserList;
