import React, { useEffect, useRef } from "react";
import dragula from "react-dragula";

import { useSelector, useDispatch } from "react-redux";
import TemplateNewsletterItems from "../../DraggableColumns/TemplateNewsletterItems";
import { v1 as uuidv1 } from "uuid";
import TemplateModelItem from "../../DraggableColumns/TemplateModelItem";
import {
  Container,
  LeftColumn,
  LeftColumnWrapper,
  RightColumnWrapper,
  ScrollContainer,
  RightColumn,
} from "./styles";
import NewsletterBuilderMenu from "../../NewsletterBuilderMenu";
import templateDisplayer from "../../../utils/templateDisplayer.js";

const EditorTab = (props) => {
  const col1 = useRef();
  const col2 = useRef();
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const dropElement = (el, target, source, sibling) => {
    const elementId = el.id;

    if (el === null) {
      return;
    }

    if (el.parentNode === null) {
      return;
    }
    if (target.id === null) {
      return;
    }
    const start = newsletterBuilder.columns[source.id];
    const finish = newsletterBuilder.columns[target.id];
    const startTemplateIds = [...start.templateIds];
    const finishTemplateIds = [...finish.templateIds];
    let destinationIndex;

    if (start === finish) {
      if (sibling === null) {
        destinationIndex = finishTemplateIds.length;
      } else {
        destinationIndex = finishTemplateIds.indexOf(sibling.id);
      }

      const items = reorder(
        newsletterBuilder.columns[start.id].templateIds,
        finish.templateIds.indexOf(elementId),
        destinationIndex
      );

      dispatch({
        type: "REORDER_TEMPLATES",
        order: { templateIds: [...items], columnId: start.id },
      });

      return;
    }
    el.parentNode.removeChild(el);
    if (sibling === null) {
      destinationIndex = finishTemplateIds.length;
    } else {
      destinationIndex = finishTemplateIds.indexOf(sibling.id);
    }
    const newStart = { ...start, templateIds: startTemplateIds };
    const newId = uuidv1();

    const newTemplate = {
      id: newId,
      editionMode: true,
      model: newsletterBuilder.templates[elementId].model,
      content: JSON.parse(
        JSON.stringify(newsletterBuilder.templates[elementId].content)
      ),
    };

    finishTemplateIds.splice(destinationIndex, 0, newId);

    const newFinish = { ...finish, templateIds: finishTemplateIds };

    const newDataTemplates = newsletterBuilder.templates;

    newDataTemplates[newId] = newTemplate;

    dispatch({
      type: "MOVE_TEMPLATE_FROM_CATEGORY_TO_NEWSLETTER",
      data: {
        modelList: [...newStart.templateIds],
        newsLetterList: [...newFinish.templateIds],
        templates: { ...newDataTemplates },
      },
    });
    return;
  };

  useEffect(() => {
    const fetchDataCol = async () => {
      const drake = dragula([col1.current, col2.current], {
        copy: function (el, source) {
          return source === col1.current;
        },
        accepts: function (el, target) {
          return target !== col1.current;
        },
      });

      drake.on("drop", function (el, target, source, sibling) {
        dropElement(el, target, source, sibling);
      });

      drake.on("shadow", function (el, container, source) {
        el.classList.add("drag-bg-shadow");
      });

      drake.on("drag", function (el, source) { });
    };

    fetchDataCol();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      isVisible={newsletterBuilder.newsletterSelectedTab === "EditorTab"}
    >
      <NewsletterBuilderMenu></NewsletterBuilderMenu>
      <LeftColumnWrapper
        isVisible={newsletterBuilder.modelListColumn.isVisible}
      >
        <ScrollContainer>
          <LeftColumn ref={col1} id="template-list-column">
            <TemplateModelItem></TemplateModelItem>
          </LeftColumn>
        </ScrollContainer>
      </LeftColumnWrapper>
      <RightColumnWrapper>
        <ScrollContainer>
          {templateDisplayer(newsletterBuilder.header)}
          <RightColumn id="newsletter-column" className="container2" ref={col2}>
            <TemplateNewsletterItems></TemplateNewsletterItems>
          </RightColumn>
          {templateDisplayer(newsletterBuilder.footer)}
        </ScrollContainer>
      </RightColumnWrapper>
    </Container>
  );
};

export default EditorTab;
