const checkPassword = (pwd, t) => {
  const message = {
    color: "#1D562C",
    text: t("checkPassword.validityMessage"),
    isValid: true,
  };
  if (pwd.length === 0) {
    message.color = "#b71823";
    message.text = t("checkPassword.warning1");
    message.isValid = false;
  } else if (pwd.length < 8) {
    message.color = "#b71823";
    message.text = t("checkPassword.warning2");
    message.isValid = false;
  } else if (!/\d/.test(pwd)) {
    message.color = "#b71823";
    message.text = t("checkPassword.warning3");
    message.isValid = false;
  } else if (!/[a-z]/.test(pwd)) {
    message.color = "#b71823";
    message.text = t("checkPassword.warning4");
    message.isValid = false;
  } else if (!/[A-Z]/.test(pwd)) {
    message.color = "#b71823";
    message.text = t("checkPassword.warning5");
    message.isValid = false;
  }

  return message;
};

export default checkPassword;
