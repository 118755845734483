import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import templateDisplayer from "../../utils/templateDisplayer.js";

const Item = styled.div`
  border: 1px solid #ddd;

  & > :first-child {
    zoom: 0.5;

    pointer-events: none;
  }
`;

const TemplateModelItem = (props) => {
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const templateList = newsletterBuilder.columns[
    "template-list-column"
  ].templateIds.map((templateId) => {
    return newsletterBuilder.templates[templateId];
  });

  const renderModel = (templateId) => {
    const template = newsletterBuilder.templates[templateId];

    let model = templateDisplayer(template);
    return model;
  };

  return (
    <>
      {templateList.map((template, index) => {
        return (
          <Item id={template.id} key={`model-${template.id}`}>
            {renderModel(template.id)}
          </Item>
        );
      })}
    </>
  );
};

export default TemplateModelItem;
