import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div``;

const ParamList = styled.ul`
  display: flex;
`;

const ParamItem = styled.li`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: ${({ isSelected }) => (isSelected ? "green" : "#000")};
  cursor: pointer;
`;

const ParamsBar = (props) => {
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();
  return (
    <Container>
      <ParamList>
        <ParamItem
          isSelected={newsletterBuilder.newsletterSelectedTab === "EditorTab"}
          onClick={() => {
            dispatch({
              type: "CHANGE_NEWSLETTER_TAB_VISIBILITY",
              tab: "EditorTab",
            });
          }}
        >
          <FontAwesomeIcon icon="pencil-alt" />
        </ParamItem>
        <ParamItem
          isSelected={newsletterBuilder.newsletterSelectedTab === "HtmlDesktop"}
          onClick={() => {
            dispatch({
              type: "CHANGE_NEWSLETTER_TAB_VISIBILITY",
              tab: "HtmlDesktop",
            });
          }}
        >
          <FontAwesomeIcon icon="desktop" />
        </ParamItem>
        <ParamItem
          isSelected={newsletterBuilder.newsletterSelectedTab === "HtmlMobile"}
          onClick={() => {
            dispatch({
              type: "CHANGE_NEWSLETTER_TAB_VISIBILITY",
              tab: "HtmlMobile",
            });
          }}
        >
          <FontAwesomeIcon icon="mobile-alt" />
        </ParamItem>
        <ParamItem
          isSelected={newsletterBuilder.newsletterSelectedTab === "HtmlTab"}
          onClick={() => {
            dispatch({
              type: "CHANGE_NEWSLETTER_TAB_VISIBILITY",
              tab: "HtmlTab",
            });
          }}
          icon="file-code"
        >
          <FontAwesomeIcon icon="file-code" />
        </ParamItem>
      </ParamList>
    </Container>
  );
};

export default ParamsBar;
