import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const DownloadBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  height: 42px;
  background-color: #0c3944;
  font-weight: 700;
  border: none;
  font-family: "Raleway", sans-serif;
  transition: background-color 0.2s;
  border-radius: 25px;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  outline: none;
  transition: all 0.4s;
  &:hover {
    background-color: #86003e;
  }
`;

const HtmlFileDownload = (props) => {
  const { t } = useTranslation();
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);

  const downloadFile = () => {
    const element = document.createElement("a");
    const file = new Blob([newsletterBuilder.newsletterHtmlCode], {
      type: "text/html",
    });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.html";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <DownloadBtn onClick={downloadFile}>
      {t("htmlFileDownload.downloadBtn")}
    </DownloadBtn>
  );
};

export default HtmlFileDownload;
