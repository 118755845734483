import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import footerDisplayer from "../../utils/footerDisplayer.js";
import { useTranslation } from "react-i18next";
import { getColors } from "../../utils/getColors.js"

const ContainerWrapper = styled.section`
  background-color: #fff;
  padding: 20px;
`;

const Container = styled.div``;

const Title = styled.h1`
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 20px;
  font-family: "Raleway", sans-serif;
  color: #0c3944;
`;

const FooterContainerLeft = styled.div`
  min-height: 200px;
  padding: 20px;
  border: 2px dashed grey;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 600px;
  box-sizing: border-box;
`;

const SelectedFooter = styled.div`
  height: 100%;
  width: 100%;
  & > :first-child {
    zoom: 1;
    pointer-events: none;
  }
`;

const AddLinkBtn = styled.button`
  background-color: #075163;
  border-color: #075163;
  border-radius: 1000px;
  padding: 10px 20px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  transition: background-color 0.2s, border-color 0.2s;
  outline: none;
  &:hover {
    background-color: #003643;
    border-color: #003643;
  }
`;

const BtnlWrapper = styled.div`
  height: 100%;
  dispay: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DeleteBtn = styled.button`
  background-color: #075163;
  border-color: #075163;
  border-radius: 1000px;
  padding: 10px 20px;
  color: #fff;
  height: 50px;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  transition: background-color 0.2s, border-color 0.2s;
  outline: none;
  &:hover {
    background-color: #003643;
    border-color: #003643;
  }
`;

const LinkContainer = styled.div`
  margin-bottom: 20px;
`;

const FieldContainer = styled.div`
  margin-bottom: 20px;
`;

const BgColorContainer = styled.div`
  margin-top: 20px;
`;

const SocialIconWrapper = styled.div`
  width: 200px;
  margin-right: 20px;
`;

const SocialUrlWrapper = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const FormLabel = styled.label`
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  color: #0c3944;
  display: block;
  font-weight: bold;
`;

const SocialFormItem = styled.li`
  display: flex;
  align-items: flex-end;

  margin-bottom: 10px;
`;

const TextInput = styled.input`
  width: 100%;
  height: 52px;
  font-family: "Raleway", sans-serif;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e0e6ed;
  padding: 16px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  color: #000;
  line-height: 20px;
`;

const SelectField = styled.select`
  width: 100%;
  height: 52px;
  font-family: "Raleway", sans-serif;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e0e6ed;
  padding: 16px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  color: #000;
  line-height: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
`;

const FooterContainerRight = styled.div`
  width: 50%;
`;

const PickerBtn = styled.button`
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  background-color: ${({ color }) => color};
  border: 1px solid grey;

  border-radius: 80px;
  cursor: pointer;
  outline: none;
  transition: all 0.1s ease-in-out;
  &:hover {
    position: absolute;

    transform: scale(1.3);
    z-index: 100;
    box-shadow: inset rgba(0, 0, 0, 0) 2px 2px 12px, #ffffff 0 0 10px;
  }
`;

const ColorList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  & li {
    box-sizing: border-box;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 30px;
    height: 30px;
  }
`;

const ModalBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  height: 30px;

  background-color: #13ce66;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  border-color: #13ce66;
  font-family: "Raleway", sans-serif;
  transition: background-color 0.2s, border-color 0.2s;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  border: none;
  text-transform: uppercase;
  outline: none;
  transition: all 0.4s;
  &:hover {
    background-color: #fabe3c;
    border-color: #fabe3c;
  }
`;

const FooterAdmin = (props) => {
  const admin = useSelector((state) => state.admin);

  const socialIcons = [
    { iconName: "facebook", name: "Facebook" },
    // { iconName: "google-plus", name: "Googgle plus" },
    { iconName: "medium", name: "Medium" },
    { iconName: "youtube", name: "Youtube" },
    { iconName: "instagram", name: "Instagram" },
    { iconName: "linkedin", name: "Linkedin" },
  ];

  const renderSocialIcon = () => {
    return socialIcons.map((element, index) => {
      return (
        <option key={`icon-selector-${index})`} value={element.iconName}>
          {element.name}
        </option>
      );
    });
  };

  const renderLink = () => {
    return admin.category.footer.socialLinks.map((element, index) => {
      return (
        <SocialFormItem key={`icon-wrapper-${index}`}>
          <SocialIconWrapper>
            <FormLabel>{t("footerAdmin.linkLabel")}</FormLabel>
            <SelectField
              value={element.icon}
              onChange={(event) => {
                dispatch({
                  type: "UPDATE_SOCIAL_ICON_LINK",
                  data: {
                    index: index,
                    iconName: event.target.value,
                  },
                });
              }}
              name="icon"
            >
              {renderSocialIcon()}
            </SelectField>
          </SocialIconWrapper>
          <SocialUrlWrapper>
            <FormLabel>{t("footerAdmin.urlLabel")}</FormLabel>
            <TextInput
              onChange={(event) => {
                dispatch({
                  type: "UPDATE_SOCIAL_URL",
                  data: {
                    index: index,
                    url: event.target.value,
                  },
                });
              }}
              value={element.url}
            ></TextInput>
          </SocialUrlWrapper>
          <BtnlWrapper>
            <DeleteBtn
              onClick={(event) => {
                dispatch({
                  type: "REMOVE_SOCIAL_ICON_ITEM",
                  index: index,
                });
              }}
            >
              {t("footerAdmin.deleteBtn")}
            </DeleteBtn>
          </BtnlWrapper>
        </SocialFormItem>
      );
    });
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const defaultColors = getColors();

  const renderColorPicker = (type) => {
    return defaultColors.map((element, index) => {
      return (
        <li key={`bg-color-${index}`}>
          <PickerBtn
            value={element}
            onClick={() => {
              dispatch({
                type: type,
                bgColor: element,
                index: index
              });
            }}
            color={element}
          ></PickerBtn>
        </li>
      );
    });
  };

  return (
    <ContainerWrapper>
      <Title>{t("footer")}</Title>
      <FieldContainer>
        <ModalBtn
          onClick={(event) => {
            event.preventDefault();
            dispatch({
              type: "OPEN_ADMIN_MODAL",
              adminContent: "admin-crop-image",
              isFooter: true,
              modalData: {
                content: {
                  ...admin.category.footer.content["images"]["image-1"],
                  imgWidth: 0,
                  imgHeight: 0
                },
                fieldType: "images",
                fieldId: "image-1",
                crop: false
              },
            });
          }}
        >
          {t("footerAdmin.customImgBtn")}
        </ModalBtn>
      </FieldContainer>
      <FieldContainer>
        <FormLabel>{t("footerAdmin.imgWidthLabel")}</FormLabel>
        <TextInput type="number"
               value={admin.category.footer.content.imageWidth || 140}
               onChange={(event) => {
                  dispatch({
                    type: "CHANGE_FOOTER_IMAGE_WIDTH",
                    data: {
                      value: event.target.value
                    },
                  });
                }}
              />
      </FieldContainer>
      <LinkContainer>
        <ul>{renderLink()}</ul>

        <AddLinkBtn
          onClick={(event) => {
            dispatch({
              type: "ADD_SOCIAL_ICON_LINK",
              data: {
                icon: "facebook",
                url: "https://",
              },
            });
          }}
        >
          {t("footerAdmin.addLinkBtn")}
        </AddLinkBtn>
      </LinkContainer>

      <FieldContainer>
        <FormLabel>{t("footerAdmin.textLabel")}</FormLabel>
        <TextInput
          name="footerText"
          value={
            admin.category.footer.content["texts"] !== undefined && admin.category.footer.content["texts"]["text-1"] !== undefined
              ? admin.category.footer.content["texts"]["text-1"].text
              : ""
          }
          placeholder="Vous avez reçu cet email car vous avez croisé la route de makesense"
          onChange={(event) => {
            dispatch({
              type: "UPDATE_FOOTER_TEXT",
              field: "text-1",
              text: event.target.value,
            });
          }}
        ></TextInput>
      </FieldContainer>

      <FieldContainer>
        <FormLabel>{t("footerAdmin.linkTextLabel")}</FormLabel>
        <TextInput
          name="footerText"
          value={
            admin.category.footer.content["texts"] !== undefined && admin.category.footer.content["texts"]["text-2"] !== undefined
              ? admin.category.footer.content["texts"]["text-2"].text
              : ""
          }
          placeholder="Pour vous désinscrire c'est par ici"
          onChange={(event) => {
            dispatch({
              type: "UPDATE_FOOTER_TEXT",
              field: "text-2",
              text: event.target.value,
            });
          }}
        ></TextInput>
      </FieldContainer>

      <FieldContainer>
        <FormLabel>{t("footerAdmin.unsubscribeLabel")}</FormLabel>
        <TextInput
          name="unsubscribeLink"
          value={
            admin.category.footer["unsubscribeLink"] !== undefined
              ? admin.category.footer["unsubscribeLink"]
              : ""
          }
          placeholder="https://..."
          onChange={(event) => {
            dispatch({
              type: "UPDATE_UNSUBSCRIBE_LINK",
              url: event.target.value,
            });
          }}
        ></TextInput>
      </FieldContainer>

      <BgColorContainer>
        <FormLabel> {t("footerAdmin.backgroundLabel")}</FormLabel>
        <ColorList>{renderColorPicker("UPDATE_FOOTER_BG_COLOR")}</ColorList>
      </BgColorContainer>

      <BgColorContainer>
        <FormLabel> {t("footerAdmin.textColorLabel")}</FormLabel>
        <ColorList>{renderColorPicker("UPDATE_FOOTER_TEXT_COLOR")}</ColorList>
      </BgColorContainer>

      <Container>
        <FooterContainerLeft>
          <SelectedFooter>
            {footerDisplayer(admin.category.footer)}
          </SelectedFooter>
        </FooterContainerLeft>
        <FooterContainerRight></FooterContainerRight>
      </Container>
    </ContainerWrapper>
  );
};

export default FooterAdmin;
