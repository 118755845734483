import styled from "styled-components";
import { ToastContainer } from "react-toastify";

export const FormLabel = styled.label`
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const FormContainer = styled.form`
  padding: 40px;

  display: flex;
  flex-direction: column;
`;

export const TextInput = styled.input`
  width: 100%;
  height: 52px;
  font-family: "Raleway", sans-serif;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e0e6ed;
  padding: 16px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  color: #000;
  line-height: 20px;
`;

export const InputContainer = styled.div`
  margin-bottom: 20px;
`;

export const SelectInput = styled.select`
  width: 100%;
  height: 52px;
  font-family: "Raleway", sans-serif;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e0e6ed;
  padding: 16px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  color: #000;
  line-height: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
`;

export const SubmitBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  background-color: #13ce66;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  border-color: #13ce66;
  font-family: "Raleway", sans-serif;
  transition: background-color 0.2s, border-color 0.2s;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  border: none;
  text-transform: uppercase;
  outline: none;
  transition: all 0.4s;
  &:hover {
    background-color: #fabe3c;
    border-color: #fabe3c;
  }
  max-width: 400px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0.5)};
  ${({ isVisible }) => (isVisible ? "" : "pointer-events: none;")}
`;

export const FieldError = styled.div`
  font-size: 14px;
  line-height: 18.2px;
  color: #f21d1d;
  margin-top: 5px;
  font-family: "Raleway", sans-serif;
  visibility: ${({ isValid }) => (isValid ? "hidden" : "visible")};
`;

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: "toast-container",
  toastClassName: "toast",
  bodyClassName: "body",
  progressClassName: "progress",
})`
  /* .toast-container */
  width: 100%;

  /* .toast is passed to toastClassName */
  .toast {
    background-color: #f21d1d;
  }

  button[aria-label="close"] {
    display: none;
  }

  /* .body is passed to bodyClassName */
  .body {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: #fff;
  }

  /* .progress is passed to progressClassName */
  .progress {
  }
`;
