import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PickColor from "./PickColor";
import { useSelector, useDispatch } from "react-redux";

const EditField = styled.input`
  display: block;
  width: 100%;
  height: 35px;
  margin: 10px auto;
  background: #fff;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  padding: 5px;
  font-size: 16px;
  border: 2px solid #fff;
  transition: all 0.3s ease;
  border-radius: 5px;
  outline: none;
  &:focus {
    border: 2px solid #1abc9d;
  }
`;

const ContainerWrapper = styled.div`
  opacity: ${({ isVisible }) => (isVisible === true ? 1 : 0.2)};
  ${({ isVisible }) => (isVisible === true ? "" : "pointer-events: none;")}
`;

const Container = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

const LAbelContainer = styled.div`
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  width: 100px;
  margin-right: 10px;
  font-weight: bold;
`;

const FieldContainer = styled.div`
  flex: 1;
`;

const TitleEditor = ({ templateId, titleId }) => {
  const { t } = useTranslation();

  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();

  const { content } = { ...newsletterBuilder.templates[templateId] };
  const editionMode =
    newsletterBuilder.selectedTemplateId === templateId ? true : false;
  const { text, tagLine } = { ...content.titles[titleId] };

  const isTagLineRemoved = () => {
    if (tagLine === undefined) {
      return true;
    } else if (
      content.options.switchTag.isActived === false
    ) {
      return false;
    }

    return true;
  }

  const changeTitleColor = (event) => {
    const color = event.target.value;

    dispatch({
      type: "CHANGE_TITLE_COLOR",
      data: {
        templateId: templateId,
        titleId: titleId,
        color: color,
      },
    });
  };

  const renderTitle = () => {
    if (editionMode === true) {
      return (
        <ContainerWrapper
          isVisible={isTagLineRemoved()}
          onMouseEnter={() => {
            dispatch({
              type: "ADD_HIGHLIGHT_ON_FIELD",
              AttributeId: titleId,
            });
          }}
          onMouseLeave={() => {
            // if (isSelectedField === false) {
            dispatch({
              type: "REMOVE_HIGHLIGHT_ON_FIELD",
            });
            // }
          }}
        >
          <Container>
            <LAbelContainer> {t("titleEditor.textLabel")} </LAbelContainer>
            <FieldContainer>
              <EditField
                editionMode={editionMode}
                type="text"
                value={text}
                placeholder="title"
                // onFocus={() => {
                //   setIselectedField(true);
                // }}
                onChange={(event) => {
                  const newText = event.target.value;

                  dispatch({
                    type: "CHANGE_TITLE_TEXT",
                    data: {
                      templateId: templateId,
                      titleId: titleId,
                      text: newText,
                    },
                  });
                }}
              ></EditField>
            </FieldContainer>
          </Container>
          {newsletterBuilder.templates[templateId].content.titles[titleId]
            .color === undefined ? null : (
              <PickColor labelName="text" action={changeTitleColor}></PickColor>
            )}
        </ContainerWrapper>
      );
    } else {
      return text;
    }
  };

  return <>{renderTitle()}</>;
};

export default TitleEditor;
