import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Checkbox from "./Checkbox";

const ContainerWrapper = styled.div``;

const Container = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

const LAbelContainer = styled.div`
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  width: 100px;
  margin-right: 10px;
  font-weight: bold;
`;

const DividerLabel = styled.label`
  font-family: "Raleway", sans-serif;
  font-size: 16px;
`;

const FieldContainer = styled.div`
  flex: 1;
`;

const DividerEditor = ({ templateId, divider }) => {
  const { t } = useTranslation();
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();

  const { content } = { ...newsletterBuilder.templates[templateId] };
  const editionMode =
    newsletterBuilder.selectedTemplateId === templateId ? true : false;
  const { isActived } = { ...content.options[divider] };

  const changeDivider = () => {
    dispatch({
      type: "ADD_OR_REMOVE_DIVIDER",
      data: {
        templateId: templateId,
        isActived: !isActived,
      },
    });
  };

  const renderDividerField = () => {
    if (editionMode === true) {
      return (
        <ContainerWrapper>
          <Container>
            <LAbelContainer>{t("dividerEditor.separatorLabel")}</LAbelContainer>
            <FieldContainer>
              <DividerLabel htmlFor="divider">
                <Checkbox
                  id="divider"
                  onChange={changeDivider}
                  checked={isActived}
                ></Checkbox>
                {isActived === true
                  ? t("dividerEditor.removeSeparator")
                  : t("dividerEditor.addSeparator")}
              </DividerLabel>
            </FieldContainer>
          </Container>
        </ContainerWrapper>
      );
    } else {
      return null;
    }
  };

  return <>{renderDividerField()}</>;
};

export default DividerEditor;
