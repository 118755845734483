import React, { useState, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils/cropImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getRotatedImage } from "../../utils/rotateImage";
import { getOrientation } from "get-orientation/browser";
import { useSelector, useDispatch } from "react-redux";
import {
  CropperContainer,
  AddFile,
  InputFile,
  CropperInterface,
  SaveImageBtn,
  InterfaceSection,
  ImageSizeWrapper,
  SizeLabel,
  SizeText,
  LockBtn,

} from "./Styles";
import Slider from "../Slider";

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

const HandleImage = (props) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  let imgSrc = null;

  const { templateId, fieldType, fieldId } = { ...newsletterBuilder.modal };

  imgSrc =
    newsletterBuilder.templates[templateId].content[fieldType][fieldId].imgSrc;

  const [imageData, setImageData] = useState({
    imageSrc: imgSrc,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect:
      newsletterBuilder.templates[templateId].content[fieldType][fieldId]
        .imgWidth /
      newsletterBuilder.templates[templateId].content[fieldType][fieldId]
        .imgHeight,
    croppedAreaPixels: null,
    croppedImage: null,
    isCropping: false,
    originSize: null,
    unlockedRatio: false
  });

  const [sliderValue, setSliderValue] = useState(1);

  const handleSlideZoom = (event) => {
    const newZoomValue = event.target.value;
    const newImageData = { ...imageData };

    if (
      imageData.originSize.height / newZoomValue <
      newsletterBuilder.templates[templateId].content[fieldType][fieldId]
        .imgHeight ||
      imageData.originSize.width / newZoomValue <
      newsletterBuilder.templates[templateId].content[fieldType][fieldId]
        .imgWidth
    ) {
    } else {
      newImageData.zoom = newZoomValue;
      setImageData(newImageData);
      setSliderValue(newZoomValue);
    }
  };

  const changeLockedRatio = () => {
    const newImageData = { ...imageData };
    newImageData.unlockedRatio = !newImageData.unlockedRatio;
    setImageData(newImageData);
  };

  const onCropChange = (crop) => {
    const newImageData = { ...imageData };
    newImageData.crop = crop;
    setImageData(newImageData);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    const newImageData = { ...imageData };
    newImageData.croppedAreaPixels = croppedAreaPixels;

    setImageData(newImageData);
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const _URL = window.URL || window.webkitURL;
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      const img = new Image();
      img.src = _URL.createObjectURL(file);
      img.onload = async function () {



        if (
          this.width <
          newsletterBuilder.templates[templateId].content[fieldType][fieldId]
            .imgWidth ||
          (this.height <
            newsletterBuilder.templates[templateId].content[fieldType][fieldId]
              .imgHeight && imageData.unlockedRatio === false)
        ) {
          alert(t("handleImage.uploadWarning"));
        } else {
          const orientation = await getOrientation(file);
          const rotation = ORIENTATION_TO_ANGLE[orientation];
          if (rotation) {
            imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
          }



          const newImageData = { ...imageData };

          if (newImageData.unlockedRatio === true) {
            newImageData.aspect = this.width / this.height;
          }

          newImageData.imageSrc = imageDataUrl;
          newImageData.crop = { x: 0, y: 0 };
          newImageData.zoom = 1;
          newImageData.type = file.type;

          setImageData(newImageData);

          setSliderValue(1);
        }
      };

      // apply rotation if needed
    }
  };

  const cropImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageData.imageSrc,
        imageData.croppedAreaPixels,
        0,
        imageData.type
      );

      const formData = new FormData();

      formData.append("files", croppedImage);
      formData.append("nom", "kiki");
      const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/upload/image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      dispatch({
        type: "UPDATE_TEMPLATE_IMAGE_AND_REINIT_IMAGE_UPLOADER_MODAL",
        data: {
          templateId: templateId,
          fieldType: fieldType,
          fieldId: fieldId,
          imgSrc: response.data.imageUrl,
        },
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isCropping: false,
      });
    }
  };

  return (
    <CropperContainer>
      <div className="App">
        <div className="crop-container">
          {newsletterBuilder.modal.isVisible === true ? (
            <Cropper
              image={imageData.imageSrc}
              crop={imageData.crop}
              zoom={imageData.zoom}
              maxZoom={5}
              aspect={imageData.aspect}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onMediaLoaded={(mediaSize) => {
                const newImageData = { ...imageData };

                newImageData.originSize = {
                  width: mediaSize.naturalWidth,
                  height: mediaSize.naturalHeight,
                };
                setImageData(newImageData);
                // Adapt zoom based on media size to fit max height
              }}
              disableAutomaticStylesInjection={true}
            />
          ) : (
              <div></div>
            )}
        </div>
        <CropperInterface>
          <ImageSizeWrapper>
            <SizeLabel>{t("handleImage.minWidthLabel")}</SizeLabel>
            <SizeText unlockedRatio={false}>{`${newsletterBuilder.templates[templateId].content[fieldType][fieldId].imgWidth}px`}</SizeText>
            <SizeLabel>{t("handleImage.minHeightLabel")}</SizeLabel>
            <SizeText unlockedRatio={imageData.unlockedRatio}>
              {`${newsletterBuilder.templates[templateId].content[fieldType][fieldId].imgHeight}px`}
            </SizeText>

            <LockBtn unlockedRatio={imageData.unlockedRatio} onClick={changeLockedRatio}> {imageData.unlockedRatio === true ? <FontAwesomeIcon icon="unlock" /> : <FontAwesomeIcon icon="lock" />}   </LockBtn>
          </ImageSizeWrapper>
          <InterfaceSection>
            <AddFile onClick={handleClick}>
              {t("handleImage.uploadBtn")}
            </AddFile>
            <InputFile
              type="file"
              onChange={onFileChange}
              ref={hiddenFileInput}
            />
          </InterfaceSection>
          <InterfaceSection>
            <SaveImageBtn onClick={cropImage}>
              {t("handleImage.saveBtn")}
            </SaveImageBtn>
          </InterfaceSection>
          <InterfaceSection>
            <Slider
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
              min={1}
              max={5}
              step={0.1}
              action={handleSlideZoom}
            ></Slider>
          </InterfaceSection>
        </CropperInterface>
      </div>
    </CropperContainer>
  );
};

export default HandleImage;
