import React from "react";
import Editor from "../../utils/editFunctions";

const Template28 = ({ template, statusMode }) => {
    const editor = new Editor(template, statusMode);

    return (
        <>
            <div style={{ backgroundColor: "#FFFFFF" }}>
                {/*[if mso | IE]>
                    <table
                    align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
                    >
                    <tr>
                        <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
                <![endif]*/}
                <div
                    style={{
                        background: "#FFFFFF",
                        backgroundColor: "#FFFFFF",
                        margin: "0px auto",
                        maxWidth: "600px",
                    }}
                >
                    <table
                        align="center"
                        border={0}
                        cellPadding={0}
                        cellSpacing={0}
                        style={{
                            background: "#FFFFFF",
                            backgroundColor: "#FFFFFF",
                            width: "100%",
                        }}
                    >
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        direction: "ltr",
                                        fontSize: "0px",
                                        padding: `${editor.handleTopMargin()}  10% ${editor.handleBottomMargin()} 10%`,
                                        textAlign: "center",
                                    }}
                                >
                                    {/*[if mso | IE]>
                                        <table border="0" cellpadding="0" cellspacing="0">
                                            tr>
                                                <td align="center" style="vertical-align:top;width:580px;">
                                    <![endif]*/}
                                    <div
                                        className="mj-column-per-100 mj-outlook-group-fix"
                                        style={{
                                            fontSize: "0px",
                                            textAlign: "left",
                                            direction: "ltr",
                                            display: "inline-block",
                                            verticalAlign: "top",
                                            width: "100%",
                                        }}
                                    >
                                        <table
                                            border={0}
                                            cellPadding={0}
                                            cellSpacing={0}
                                            width="100%"
                                        >
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={{
                                                            backgroundColor: "#FFFFFF",
                                                            verticalAlign: "top",
                                                            padding: "0 0 0 0",
                                                        }}
                                                    >
                                                        <table
                                                            border={0}
                                                            cellPadding={0}
                                                            cellSpacing={0}
                                                            style={{}}
                                                            width="100%"
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <td
                                                                        align="center"
                                                                        style={{
                                                                            fontSize: "0px",
                                                                            padding: "0 0px 0 0px",
                                                                            wordBreak: "break-word",
                                                                        }}
                                                                    >
                                                                        <table
                                                                            border={0}
                                                                            cellPadding={0}
                                                                            cellSpacing={0}
                                                                            style={{
                                                                                borderCollapse: "collapse",
                                                                                borderSpacing: "0px",
                                                                            }}
                                                                        >
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td
                                                                                        style={{
                                                                                            width: "100%"
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            href={editor.handleEventLink(
                                                                                                "event-image-1"
                                                                                            )}

                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            <img
                                                                                                alt="event makesense"
                                                                                                height="auto"
                                                                                                src={editor.handleEventImg(
                                                                                                    "event-image-1"
                                                                                                )}
                                                                                                style={{
                                                                                                    border: 0,
                                                                                                    display: "block",
                                                                                                    outline: "none",
                                                                                                    textDecoration: "none",
                                                                                                    height: "auto",
                                                                                                    width: "100%",
                                                                                                    fontSize: "13px",
                                                                                                }}
                                                                                                width="100%"
                                                                                            />
                                                                                        </a>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/*[if mso | IE]>
                                                </td>
                                            </tr>
                                        </table>
                                    <![endif]*/}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/*[if mso | IE]>
                        </td>
                    </tr>
                </table>
                <![endif]*/}
            </div>
            {editor.handleDivider()}
        </>
    );
};

export default Template28;