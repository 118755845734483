const colors = [
    "#FFFFFF",
    "#FCF6A0",
    "#F3976B",
    "#E36164",
    "#9B084F",
    "#C1E94E",
    "#196C84",
    "#0C3944",
    "#70AF90",
    "#474136",
    "#C62E43",
    "#24673A",
    "#FFF30D",
    "#031733",
];

export const getColors = () => {
    return colors
}