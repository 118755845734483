import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PickBgColor from "./PickBgColor";
import { useSelector, useDispatch } from "react-redux";

const ContainerWrapper = styled.div`
  opacity: ${({ isVisible }) => (isVisible === true ? 1 : 0.2)};
  ${({ isVisible }) => (isVisible === true ? "" : "pointer-events: none;")}
`;

const EditField = styled.input`
  display: block;
  width: 100%;
  height: 35px;
  margin: 10px auto;
  background: #fff;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  padding: 5px;
  font-size: 16px;
  border: 2px solid #fff;
  transition: all 0.3s ease;
  border-radius: 5px;
  outline: none;
  &:focus {
    border: 2px solid #1abc9d;
  }
`;

const Container = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

const LAbelContainer = styled.div`
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  width: 100px;
  margin-right: 10px;
  font-weight: bold;
`;

const FieldContainer = styled.div`
  flex: 1;
`;

const TagEditor = ({ templateId, tagId }) => {
  const { t } = useTranslation();
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();

  const { content } = { ...newsletterBuilder.templates[templateId] };
  const editionMode =
    newsletterBuilder.selectedTemplateId === templateId ? true : false;
  const { text, tagLine } = { ...content.tags[tagId] };

  const isTagLineRemoved = () => {
    if (tagLine === undefined) {
      return true;
    } else if (
      content.options.switchTag.isActived === false
    ) {
      return false;
    }

    return true;
  }

  const changeBackgroundColor = (event) => {
    const bgColor = event.target.value;

    dispatch({
      type: "CHANGE_TAG_BACKGROUND_COLOR",
      data: {
        templateId: templateId,
        tagId: tagId,
        bgColor: bgColor,
      },
    });
  };

  const renderButton = () => {
    if (editionMode === true) {
      return (
        <ContainerWrapper
          isVisible={isTagLineRemoved()}
          onMouseEnter={() => {
            dispatch({
              type: "ADD_HIGHLIGHT_ON_FIELD",
              AttributeId: tagId,
            });
          }}
          onMouseLeave={() => {
            // if (isSelectedField === false) {
            dispatch({
              type: "REMOVE_HIGHLIGHT_ON_FIELD",
            });
            // }
          }}
        >
          <Container>
            <LAbelContainer>{t("tagEditor.textLabel")}</LAbelContainer>
            <FieldContainer>
              <EditField
                editionMode={editionMode}
                type="text"
                value={text}
                placeholder="tag text"
                onChange={(event) => {
                  const newText = event.target.value;

                  dispatch({
                    type: "CHANGE_TAG_TEXT",
                    data: {
                      templateId: templateId,
                      tagId: tagId,
                      text: newText,
                    },
                  });
                }}
              ></EditField>
            </FieldContainer>
          </Container>

          <PickBgColor
            labelName="background"
            action={changeBackgroundColor}
          ></PickBgColor>
        </ContainerWrapper>
      );
    } else {
      return text;
    }
  };

  return <>{renderButton()}</>;
};

export default TagEditor;
