import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Container, ArrowIcon, Title, BackBtn } from "./styles.js";

const Breadcrumb = ({ link, title }) => {
  return (
    <Container>
      <BackBtn to={link}>
        <ArrowIcon>
          <FontAwesomeIcon icon="arrow-left" />
        </ArrowIcon>
      </BackBtn>
      <Title>{title}</Title>
    </Container>
  );
};

export default Breadcrumb;
