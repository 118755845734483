import React from "react";

const Divider = (props) => {
  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      {/* divider */}
      {/*[if mso | IE]>
<table
   align="center" border="0" cellpadding="0" cellspacing="0" class="content-padding-outlook" style="width:600px;" width="600"
>
  <tr>
    <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]*/}
      <div
        className="content-padding"
        style={{ margin: "0px auto", maxWidth: "600px" }}
      >
        <table
          align="center"
          border={0}
          cellPadding={0}
          cellSpacing={0}
          style={{ width: "100%" }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  direction: "ltr",
                  fontSize: "0px",
                  padding: "0px 0",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                  textAlign: "center",
                }}
              >
                {/*[if mso | IE]>
            <table  border="0" cellpadding="0" cellspacing="0">

  <tr>

      <td
         class="" style="vertical-align:top;width:580px;"
      >
    <![endif]*/}
                <div
                  className="mj-column-per-100 mj-outlook-group-fix"
                  style={{
                    fontSize: "0px",
                    textAlign: "left",
                    direction: "ltr",
                    display: "inline-block",
                    verticalAlign: "top",
                    width: "100%",
                  }}
                >
                  <table
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td style={{ verticalAlign: "top", padding: 0 }}>
                          <table
                            border={0}
                            cellPadding={0}
                            cellSpacing={0}
                            style={{}}
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "0px",
                                    padding: "0px 25px 10px 25px ",
                                    paddingRight: 0,
                                    paddingLeft: 0,
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <p
                                    style={{
                                      borderTop: "solid 1px #E2E2E2",
                                      fontSize: "1px",
                                      margin: "0px auto",
                                      width: "100%",
                                    }}
                                  >
                                  </p>
                                  {/*[if mso | IE]>
  <table
     align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 1px #E2E2E2;font-size:1px;margin:0px auto;width:580px;"  width="580px"
  >
    <tr>
      <td style="height:0;line-height:0;">
        &nbsp;
      </td>
    </tr>
  </table>
<![endif]*/}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/*[if mso | IE]>
      </td>

  </tr>

            </table>
          <![endif]*/}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/*[if mso | IE]>
    </td>
  </tr>
</table>
<![endif]*/}
      {/* divider */}
    </div>
  );
};

export default Divider;
