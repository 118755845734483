import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import LoaderAnimation from "../../../components/LoaderAnimation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import {
  Header,
  List,
  ItemInfo,
  ItemAction,
  Title,
  CategoryNumber,
  CategoryItem,
  ListHeader,
  ItemActionLabel,
  ItemInfoLabel,
  AddTemplatesBtn,
  EditBtn,
  DeleteBtn,
  ItemPrompt,
  ConfirmDeleteBtn,
  CancelDeleteBtn
} from "./styles.js";

import moment from "moment";
import AdminLayout from "../../../components/AdminLayout";
moment.locale("fr");

const CategoryList = (props) => {
  const { t } = useTranslation();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleted, setDeleted] = useState(null);
  const user = useSelector((state) => state.user);

  const deleteUser = async (id) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_DOMAIN}/category/${id}`,
      {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      }
    );

    if (response.status === 200) {
      setDeleted(new Date());
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/categories`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await setCategoryList(response.data);

      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted]);

  return (
    <AdminLayout>
      {isLoading === true ? (
        <LoaderAnimation></LoaderAnimation>
      ) : (
          <>
            <Header>
              <Title>
                {t("adminCategoryList.title")}
                <CategoryNumber>
                  {isLoading === true ? " " : categoryList.length}
                </CategoryNumber>
              </Title>
              <AddTemplatesBtn to="/category/add">
                <FontAwesomeIcon icon="plus" />
                <span>{t("adminCategoryList.addTemplateBtn")}</span>
              </AddTemplatesBtn>
            </Header>
            <List>
              <ListHeader>
                <ItemInfoLabel>{t("adminCategoryList.name")}</ItemInfoLabel>

                <ItemInfoLabel>{t("adminCategoryList.createdAt")}</ItemInfoLabel>
                <ItemActionLabel>
                  {t("adminCategoryList.actions")}
                </ItemActionLabel>
              </ListHeader>
              {categoryList.map((element, index) => {
                return (
                  <CategoryItem key={`category+${index}`}>


                    {element.status !== undefined && element.status === "delete prompt" ? (
                      <>
                        <ItemInfo>
                          {t("adminCategoryList.deletePromptMessage1", { categoryTitle: element.title })}


                          <br />
                          {t("adminCategoryList.deletePromptMessage2")}




                        </ItemInfo>
                        <ItemPrompt>
                          <CancelDeleteBtn

                            onClick={() => {

                              const newCategoryList = [...categoryList];
                              delete newCategoryList[index].status;
                              setCategoryList(newCategoryList);
                              // deleteUser(element._id);
                            }}



                          >{t("adminCategoryList.no")}</CancelDeleteBtn>
                          <ConfirmDeleteBtn onClick={() => {

                            const newCategoryList = [...categoryList];
                            delete newCategoryList[index].status;
                            setCategoryList(newCategoryList);
                            deleteUser(element._id);
                          }}>{t("adminCategoryList.yes")}</ConfirmDeleteBtn>
                        </ItemPrompt>
                      </>

                    )



                      : (

                        <>

                          <ItemInfo>{element.title}</ItemInfo>
                          <ItemInfo>
                            {moment(element.createdDate).format("DD-MM-YYYY")}
                          </ItemInfo>
                          <ItemAction>
                            <EditBtn to={`/category/edit/${element._id}`}>
                              <FontAwesomeIcon icon="pencil-alt" />
                            </EditBtn>
                            <DeleteBtn


                              onClick={() => {

                                const newCategoryList = [...categoryList];
                                newCategoryList[index].status = "delete prompt";
                                setCategoryList(newCategoryList);
                                // dispatch({
                                //   type: "OPEN_ADMIN_MODAL",
                                //   adminContent: "admin-delete-category",
                                //   modalData: {
                                //     cateGoryId: element._id
                                //   },
                                // });





                                // deleteUser(element._id);
                              }}
                              className="submit-button"
                            >
                              <FontAwesomeIcon icon="trash" />
                            </DeleteBtn>
                          </ItemAction>
                        </>


                      )}



                  </CategoryItem>
                );
              })}
            </List>
          </>
        )
      }
    </AdminLayout>
  );
};

export default CategoryList;
