import React, { useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import {
  CenterContainer,
  Container,
  StyledToastContainer,
  LoginInput,
  InputContainer,
  FieldError,
  PasswordLink,
  FormContainer,
  SubmitBtn,
  FormLabel,
} from "./styles.js";
import axios from "axios";

const LoginPage = (props) => {
  const { t } = useTranslation();
  const [loginForm, setLoginForm] = useState({ email: "", password: "" });
  const [emailNotif, setEmailNotif] = useState({
    isValid: false,
    message: t("loginPage.emailRequiredMessage"),
  });

  const history = useHistory();

  const notifyAccountError = () => toast(t("loginPage.accountError"));

  const handleChange = (event) => {
    event.stopPropagation();
    const fieldValue = event.target.value;
    const fieldName = event.target.getAttribute("name");
    const newLoginForm = { ...loginForm };
    newLoginForm[fieldName] = fieldValue;

    setLoginForm(newLoginForm);
    if (fieldName === "email") {
      const newEmailNotif = { ...emailNotif };
      if (typeof fieldValue !== "undefined" && fieldValue !== "") {
        let lastAtPos = fieldValue.lastIndexOf("@");
        let lastDotPos = fieldValue.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fieldValue.indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            fieldValue.length - lastDotPos > 2
          )
        ) {
          newEmailNotif.message = t("loginPage.emailValididityMessage");
          setEmailNotif(newEmailNotif);
          newEmailNotif.isValid = false;
        } else {
          newEmailNotif.isValid = true;
          setEmailNotif(newEmailNotif);
        }
      } else {
        newEmailNotif.message = t("loginPage.emailRequiredMessage");
        newEmailNotif.isValid = false;
        setEmailNotif(newEmailNotif);
      }
    }
  };

  const resetLoginForm = () => {
    const newEmailNotif = { ...emailNotif };
    newEmailNotif.message = t("loginPage.emailRequiredMessage");
    newEmailNotif.isValid = false;
    setEmailNotif(newEmailNotif);
    const newLoginForm = { ...loginForm };
    newLoginForm.email = "";
    newLoginForm.password = "";
    setLoginForm(newLoginForm);
  };

  const dispatch = useDispatch();

  const sendLoginForm = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/user/log_in`,
        {
          email: loginForm.email,
          password: loginForm.password,
        }
      );

      if (response.status === 200 && response.data.token !== undefined) {
        Cookies.set("userToken", response.data.token, {
          expires: 2000,
          SameSite: "Strict",
        });
        Cookies.set("userRole", response.data.role, {
          expires: 2000,
          SameSite: "Strict",
        });
        Cookies.set("userId", response.data._id, {
          expires: 2000,
          SameSite: "Strict",
        });

        dispatch(
          { type: "GET_TOKEN" },
          {
            token: response.data.token,
            role: response.data.role,
            id: response.data._id,
          }
        );

        history.push("/");
      }
    } catch (error) {
      notifyAccountError();
      resetLoginForm();
    }
  };

  const formValid = () => {
    if (emailNotif.isValid === true && loginForm.password.length > 2) {
      return true;
    }
    return false;
  };

  return (
    <CenterContainer>
      <Container>
        <FormContainer className="login-form">
          <FormLabel htmlFor="email">{t("loginPage.emailLabel")}</FormLabel>
          <InputContainer>
            <LoginInput
              onChange={handleChange}
              type="email"
              id="email"
              name="email"
              value={loginForm.email}
            ></LoginInput>
            <FieldError isValid={emailNotif.isValid}>
              {emailNotif.message}
            </FieldError>
          </InputContainer>

          <FormLabel htmlFor="password">
            {t("loginPage.passwordLabel")}
          </FormLabel>
          <InputContainer>
            <LoginInput
              onChange={handleChange}
              type="password"
              id="password"
              name="password"
              value={loginForm.password}
            ></LoginInput>
          </InputContainer>
          <SubmitBtn
            isVisible={formValid()}
            onClick={sendLoginForm}
            className="submit-button"
            type="submit"
          >
            {t("loginPage.connectBtn")}
          </SubmitBtn>
        </FormContainer>
        <PasswordLink to="/resetPassword">
          {" "}
          {t("loginPage.forgotPasswordLink")}
        </PasswordLink>

        <StyledToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
      </Container>
    </CenterContainer>
  );
};

export default LoginPage;
