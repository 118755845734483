import React from "react";
import { MainContainer, ColLeft, ColRight } from "./styles";
import AdminSideBar from "../AdminSideBar";

const AdminLayout = ({ children }) => {
  return (
    <MainContainer>
      <ColLeft>
        <AdminSideBar></AdminSideBar>
      </ColLeft>
      <ColRight>{children}</ColRight>
    </MainContainer>
  );
};

export default AdminLayout;
