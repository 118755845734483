import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import headerDisplayer from "../../utils/headerDisplayer.js";
import { useTranslation } from "react-i18next";
import AddNewColorHeader from "./AddNewColorHeader.js";
import { getColors } from "../../utils/getColors.js";


const ContainerWrapper = styled.section`
  background-color: #fff;
  padding: 20px;
`;

const Container = styled.div``;

const BtnContainer = styled.div`
  display:flex;
  align-items:center;
  margin-bottom: 20px;
  margin-top: 20px;
`

const ShowHideBtn = styled.button`
  margin-left:20px;
  background-color: #075163;
  border-color: #075163;
  border-radius: 1000px;
  padding: 10px 20px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  transition: background-color 0.2s, border-color 0.2s;
  outline: none;
  &:hover {
    background-color: #003643;
    border-color: #003643;
  }
`;



const BgColorContainer = styled.div`
  margin-top: 20px;
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: 800;
  color: #0c3944;
  margin-bottom: 20px;
  font-family: "Raleway", sans-serif;
`;

const HeaderTemplate = styled.div`
  min-height: 200px;
  padding: 20px;
  border: 2px dashed grey;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  min-width: 600px;
  box-sizing: border-box;
`;

const SelectedHeader = styled.div`
  height: 100%;
  width: 100%;
  & > :first-child {
    zoom: 1;

    pointer-events: none;
  }
`;

const FormLabel = styled.label`
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  color: #0c3944;
  display: block;
  font-weight: bold;
`;

const ModalBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  height: 30px;

  background-color: #13ce66;
  font-weight: 700;
  border-width: 1px;
  border-style: solid;
  border-color: #13ce66;
  font-family: "Raleway", sans-serif;
  transition: background-color 0.2s, border-color 0.2s;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  border: none;
  text-transform: uppercase;
  outline: none;
  transition: all 0.4s;
  &:hover {
    background-color: #fabe3c;
    border-color: #fabe3c;
  }
`;

const PickerBtn = styled.button`
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  background-color: ${({ color }) => color};
  border: 1px solid grey;

  border-radius: 80px;
  cursor: pointer;
  outline: none;
  transition: all 0.1s ease-in-out;
  &:hover {
    position: absolute;

    transform: scale(1.3);
    z-index: 100;
    box-shadow: inset rgba(0, 0, 0, 0) 2px 2px 12px, #ffffff 0 0 10px;
  }
`;

const ColorList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  & li {
    box-sizing: border-box;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 30px;
    height: 30px;
  }
`;

const HeaderContainerRight = styled.div``;

const TextInput = styled.input`
  width: 100%;
  height: 52px;
  font-family: "Raleway", sans-serif;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e0e6ed;
  padding: 16px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  color: #000;
  line-height: 20px;
`;

const HeaderAdmin = (props) => {
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const defaultColors = getColors()

  const renderColorPicker = (dispatchType) => {
    return defaultColors.map((element, index) => {
      return (
        <li key={`bg-color-${index}`}>
          <PickerBtn
            value={element}
            onClick={() => {
              dispatch({
                type: dispatchType,
                bgColor: element,
              });
            }}
            color={element}
          ></PickerBtn>
        </li>
      );
    });
  };
  return (
    <ContainerWrapper>
      <Title>{t("header")}</Title>
      <Container>
        <HeaderTemplate>
          <SelectedHeader>
            {headerDisplayer(admin.category.header)}
          </SelectedHeader>
        </HeaderTemplate>
        <HeaderContainerRight>
          <div>
            <BtnContainer>
            <ModalBtn
              onClick={(event) => {
                event.preventDefault();
                if (admin.category.header.hasFullWidthImage) {
                  dispatch({
                    type: "OPEN_ADMIN_MODAL",
                    adminContent: "admin-crop-image",
                    modalData: {
                      content: {
                        ...(admin.category.header.content["images"]["image-3"] || admin.category.header.content["images"]["image-2"]),
                        imgWidth: 0,
                        imgHeight: 0
                      },
                      fieldType: "images",
                      fieldId: "image-3",
                      crop: false
                    },
                  });
                }
                else {
                  dispatch({
                    type: "OPEN_ADMIN_MODAL",
                    adminContent: "admin-crop-image",
                    modalData: {
                      content: admin.category.header.content["images"]["image-1"],
                      fieldType: "images",
                      fieldId: "image-1",
                    },
                  });
                }
              }}
            >
              {t("headerAdmin.titleImgBtn")}
            </ModalBtn>
              <ShowHideBtn onClick={() => {
                dispatch({
                  type: "SET_HEADER_FULL_IMAGE",
                  value: !admin.category.header.hasFullWidthImage
                });
              }} >{admin.category.header.hasFullWidthImage ? t("headerAdmin.fullWidthImgDisable") : t("headerAdmin.fullWidthImgEnable")}</ShowHideBtn>
            </BtnContainer>
            <BtnContainer>
              <ModalBtn
                onClick={(event) => {
                  event.preventDefault();

                  dispatch({
                    type: "OPEN_ADMIN_MODAL",
                    adminContent: "admin-crop-image",
                    modalData: {
                      content: admin.category.header.content["images"]["image-2"],
                      fieldType: "images",
                      fieldId: "image-2",
                    },
                  });
                }}
              >
                {t("headerAdmin.backgroundImgBtn")}
              </ModalBtn>
              {admin.category.header.hasSecondImage === true || admin.category.header.hasSecondImage === undefined ? (
                <ShowHideBtn onClick={() => {
                  dispatch({
                    type: "HIDE_HEADER_IMAGE",

                  })
                }} > {t("headerAdmin.hideImageBtn")}</ShowHideBtn>
              ) : (<ShowHideBtn onClick={() => {
                dispatch({
                  type: "SHOW_HEADER_IMAGE",

                })
              }} >{t("headerAdmin.showImageBtn")} </ShowHideBtn>)}
            </BtnContainer>
          </div>
          <BgColorContainer>
            <FormLabel>{t("headerAdmin.backgroundLabel")}</FormLabel>
            <ColorList>{renderColorPicker("UPDATE_HEADER_BG_COLOR")}</ColorList>
          </BgColorContainer>
          <AddNewColorHeader></AddNewColorHeader>
          <BtnContainer>
          <FormLabel>{t("headerAdmin.archiveLink")}</FormLabel>
          <ShowHideBtn onClick={() => {
            console.log('onclick');
            dispatch({
              type: "SET_HEADER_ARCHIVE_LINK",
              value: !admin.category.header.hasArchiveLink
            });
          }} >{admin.category.header.hasArchiveLink ? t("headerAdmin.archiveLinkDisable") : t("headerAdmin.archiveLinkEnable")}</ShowHideBtn>
          </BtnContainer>
          {
            admin.category.header.hasArchiveLink ? (
              <>
              <BgColorContainer>
              <FormLabel>{t("headerAdmin.archiveLinkTextLabel")}</FormLabel>
              <TextInput type="text"
                         placeholder="Lire cette newsletter en ligne"
                         value={admin.category.header.archiveLinkText}
                         onChange={(event) => {
                           dispatch({
                             type: "SET_HEADER_ARCHIVE_LINK_TEXT",
                             value: event.target.value
                           });
                         }}
              />
              </BgColorContainer>
              <BgColorContainer>
              <FormLabel>{t("headerAdmin.archiveLinkUrlLabel")}</FormLabel>
              <TextInput type="text"
                         placeholder="*|ARCHIVE|*"
                         value={admin.category.header.archiveLinkUrl}
                         onChange={(event) => {
                           dispatch({
                             type: "SET_HEADER_ARCHIVE_LINK_URL",
                             value: event.target.value
                           });
                         }}
              />
              </BgColorContainer>
              <BgColorContainer>
                <FormLabel>{t("headerAdmin.archiveLinkColorLabel")}</FormLabel>
                <ColorList>{renderColorPicker("SET_HEADER_ARCHIVE_LINK_COLOR")}</ColorList>
              </BgColorContainer>
              </>
            ) : null
          }

        </HeaderContainerRight>
      </Container>
    </ContainerWrapper>
  );
};

export default HeaderAdmin;
