import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import getCroppedImg from "../../utils/cropImage";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import d2i from "dom-to-image";
import EventSvg from "../templates/EventSvg";




const CanvasContainer = styled.div`
  margin-bottom: 20px;
`;

const SubmitButton = styled.button`
  background-color: #13ce66;

  border-radius: 1000px;
  padding: 10px 20px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  border: none;
  text-transform: uppercase;
  transition: background-color 0.2s, border-color 0.2s;
  outline: none;
  &:hover {
    background-color: #fabe3c;
  }
`;

const TextInput = styled.input`
  width: 100%;
  height: 52px;
  font-family: "Raleway", sans-serif;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #e0e6ed;
  padding: 16px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  color: #000;
  line-height: 20px;
  margin-bottom: 20px;
`;

const InputGroup = styled.div`
margin-right: 20px;
`;

const FormLine = styled.div`
  display:flex;
  align-items: center;
`;

const FormLabel = styled.label`
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  color: #0c3944;
  display: block;
  font-weight: bold;
`;





const EventImage = (props) => {
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const { templateId, fieldType, fieldId } = { ...newsletterBuilder.modal };
  const [eventForm, setEventForm] = useState({
    ...newsletterBuilder.templates[templateId].content[fieldType][fieldId]
  }
  );
  const [newImage, setNewImage] = useState(null)

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const imageContainer = useRef();




  const saveImage = async (image) => {
    try {

      const formData = await new FormData();
      const scale = 1;

      const newImage = await getCroppedImg(image.src, {
        width: image.width * scale,
        height: image.height * scale,
        x: 0,
        y: 0,
      });


      formData.append("files", newImage);
      formData.append("nom", "kiki");

      const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/upload/image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );


      dispatch({
        type: "UPDATE_TEMPLATE_EVENT_IMAGE",
        data: {
          templateId: templateId,
          fieldType: fieldType,
          fieldId: fieldId,
          imgSrc: response.data.imageUrl,
          templateLink: eventForm.templateLink,
          line1: eventForm.line1,
          line2: eventForm.line2,
          line3: eventForm.line3,
          day: eventForm.day,
          month: eventForm.month,

        },
      });
    } catch (e) {
      console.error(e);
    }
  };


  const handleChange = (event) => {
    const type = event.target.name;
    const fieldValue = event.target.value;
    const newEventForm = { ...eventForm };
    newEventForm[type] = fieldValue;

    setEventForm(newEventForm)


  }


  useEffect(() => {

    const el2 = imageContainer.current
    const scale = 2;
    d2i
      .toPng(el2, {
        width: el2.clientWidth * scale,
        height: el2.clientHeight * scale,
        style: {
          transform: 'scale(' + scale + ')',
          transformOrigin: 'top left'
        }
      }
      )
      .then(function (dataUrl) {

        var img = new Image();
        img.src = dataUrl;

        setNewImage(img);
        document.getElementById("imageLocation").innerHTML = "";
        document.getElementById("imageLocation").appendChild(img);

      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });












  }, [eventForm]);
  return (
    <>
      <FormLine>
        <InputGroup>
          <FormLabel htmlFor="day">{t("eventImage.dayLabel")}</FormLabel>

          <TextInput
            type="text"
            id="day"
            value={eventForm.day}
            name="day"
            onChange={handleChange}
          ></TextInput>

        </InputGroup>


        <InputGroup>
          <FormLabel htmlFor="month">{t("eventImage.monthLabel")}</FormLabel>
          <TextInput
            type="text"
            value={eventForm.month}
            name="month"
            id="month"
            onChange={handleChange}
          ></TextInput>
        </InputGroup>

        <InputGroup>
          <FormLabel htmlFor="templateLink">{t("eventImage.linkLabel")}</FormLabel>
          <TextInput
            type="text"
            value={eventForm.templateLink}
            name="templateLink"
            id="templateLink"
            onChange={handleChange}
          ></TextInput>


        </InputGroup>
      </FormLine>
      <FormLabel htmlFor="line1">{t("eventImage.line1")}</FormLabel>
      <TextInput
        type="text"
        value={eventForm.line1}
        id="line1"
        name="line1"
        onChange={handleChange}
      ></TextInput>
      <FormLabel htmlFor="line2">{t("eventImage.line2")}</FormLabel>
      <TextInput
        type="text"
        value={eventForm.line2}
        name="line2"
        id="line2"
        onChange={handleChange}
      ></TextInput>
      <FormLabel htmlFor="line3">{t("eventImage.line3")}</FormLabel>
      <TextInput
        type="text"
        value={eventForm.line3}
        name="line3"
        id="line3"
        onChange={handleChange}
      ></TextInput>
      <SubmitButton
        onClick={() => {
          saveImage(newImage);
        }}
      >
        {t("eventImage.submitBtn")}
      </SubmitButton>


      <CanvasContainer ref={imageContainer}>
        <EventSvg   {...eventForm}  ></EventSvg>
      </CanvasContainer>
      <div id="imageLocation" style={{ display: "none" }}>

      </div>

    </>
  );
};

export default EventImage;
