import React from "react";
import Header2 from "../components/templates/Header2";
import Footer from "../components/templates/Footer";
import Template1 from "../components/templates/Template1";
import Template2 from "../components/templates/Template2";
import Template3 from "../components/templates/Template3";
import Template4 from "../components/templates/Template4";
import Template5 from "../components/templates/Template5";
import Template6 from "../components/templates/Template6";
import Template7 from "../components/templates/Template7";
import Template8 from "../components/templates/Template8";
import Template9 from "../components/templates/Template9";
import Template10 from "../components/templates/Template10";

import Template11 from "../components/templates/Template11";
import Template12 from "../components/templates/Template12";
import Template13 from "../components/templates/Template13";
import Template14 from "../components/templates/Template14";
import Template15 from "../components/templates/Template15";
import Template16 from "../components/templates/Template16";
import Template17 from "../components/templates/Template17";
import Template18 from "../components/templates/Template18";
import Template19 from "../components/templates/Template19";
import Template20 from "../components/templates/Template20";
import Template21 from "../components/templates/Template21";
import Template22 from "../components/templates/Template22";
import Template23 from "../components/templates/Template23";
import Template24 from "../components/templates/Template24";
import Template25 from "../components/templates/Template25";
import Template26 from "../components/templates/Template26";
import Template27 from "../components/templates/Template27";
import Template28 from "../components/templates/Template28";

const templateDisplayer = (template, statusMode = "NEWSLETTER-FORMAT") => {
  const templateToDisplay = [];
  switch (template.model) {
    case "model-footer-1":
      templateToDisplay.push(
        <Footer
          key={`template-${template.id}`}
          statusMode={statusMode}
          template={template}
        ></Footer>
      );
      break;

    case "model-header-2":
      templateToDisplay.push(
        <Header2
          key={`template-${template.id}`}
          statusMode={statusMode}
          template={template}
        ></Header2>
      );
      break;

    case "model1":
      templateToDisplay.push(
        <Template1
          key={`template-${template.id}`}
          statusMode={statusMode}
          template={template}
        ></Template1>
      );
      break;
    case "model2":
      templateToDisplay.push(
        <Template2
          key={`template-${template.id}`}
          statusMode={statusMode}
          template={template}
        ></Template2>
      );
      break;
    case "model3":
      templateToDisplay.push(
        <Template3
          key={`template-${template.id}`}
          statusMode={statusMode}
          template={template}
        ></Template3>
      );
      break;
    case "model4":
      templateToDisplay.push(
        <Template4
          key={`template-${template.id}`}
          statusMode={statusMode}
          template={template}
        ></Template4>
      );
      break;
    case "model5":
      templateToDisplay.push(
        <Template5
          key={`template-${template.id}`}
          statusMode={statusMode}
          template={template}
        ></Template5>
      );
      break;
    case "model6":
      templateToDisplay.push(
        <Template6
          key={`template-${template.id}`}
          statusMode={statusMode}
          template={template}
        ></Template6>
      );
      break;
    case "model7":
      templateToDisplay.push(
        <Template7
          key={`template-${template.id}`}
          statusMode={statusMode}
          template={template}
        ></Template7>
      );
      break;
    case "model8":
      templateToDisplay.push(
        <Template8
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template8>
      );
      break;
    case "model9":
      templateToDisplay.push(
        <Template9
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template9>
      );
      break;

    case "model10":
      templateToDisplay.push(
        <Template10
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template10>
      );
      break;

    case "model11":
      templateToDisplay.push(
        <Template11
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template11>
      );
      break;
    case "model12":
      templateToDisplay.push(
        <Template12
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template12>
      );
      break;
    case "model13":
      templateToDisplay.push(
        <Template13
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template13>
      );
      break;
    case "model14":
      templateToDisplay.push(
        <Template14
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template14>
      );
      break;
    case "model15":
      templateToDisplay.push(
        <Template15
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template15>
      );
      break;
    case "model16":
      templateToDisplay.push(
        <Template16
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template16>
      );
      break;
    case "model17":
      templateToDisplay.push(
        <Template17
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template17>
      );
      break;
    case "model18":
      templateToDisplay.push(
        <Template18
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template18>
      );
      break;
    case "model19":
      templateToDisplay.push(
        <Template19
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template19>
      );
      break;
    case "model20":
      templateToDisplay.push(
        <Template20
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template20>
      );
      break;

    case "model21":
      templateToDisplay.push(
        <Template21
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template21>
      );
      break;

    case "model22":
      templateToDisplay.push(
        <Template22
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template22>
      );
      break;

    case "model23":
      templateToDisplay.push(
        <Template23
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template23>
      );
      break;

    case "model24":
      templateToDisplay.push(
        <Template24
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template24>
      );
      break;

    case "model25":
      templateToDisplay.push(
        <Template25
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template25>
      );
      break;
    case "model26":
      templateToDisplay.push(
        <Template26
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template26>
      );
      break;
    case "model27":
      templateToDisplay.push(
        <Template27
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template27>
      );
      break;
    case "model28":
      templateToDisplay.push(
        <Template28
          key={`template-${template.id}`}
          template={template}
          statusMode={statusMode}
        ></Template28>
      );
      break;
    default:
      templateToDisplay.push(null);
  }

  return templateToDisplay;
};

export default templateDisplayer;
