import React from "react";
import Editor from "../../utils/editFunctions";

const Template6 = ({ template, statusMode }) => {
  const editor = new Editor(template, statusMode);
  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        {/* template 6 */}
        {/*[if mso | IE]>
<table
   align="center" border="0" cellpadding="0" cellspacing="0" class="content-padding-outlook" style="width:600px;" width="600"
>
  <tr>
    <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]*/}
        <div
          className="content-padding"
          style={{ margin: "0px auto", maxWidth: "600px" }}
        >
          <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
              background: editor.handleTemplateBgColor(),
              backgroundColor: editor.handleTemplateBgColor(),
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    direction: "ltr",
                    fontSize: "0px",
                    padding: `${editor.handleTopMargin()} 10% ${editor.handleBottomMargin()} 10%`,
                    textAlign: "center",
                  }}
                >
                  {/*[if mso | IE]>
            <table border="0" cellpadding="0" cellspacing="0">

  <tr>

      <td
         class="small-box-shadow-outlook" style="vertical-align:top;width:278.4px;"
      >
    <![endif]*/}
                  <div
                    className="mj-column-per-48 mj-outlook-group-fix small-box-shadow"
                    style={{
                      boxShadow: "7px 7px 5px #0000000D",
                      borderRadius: "4px",

                      fontSize: "0px",
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #EAEAEA",
                        borderRadius: "4px",
                      }}
                    >
                      <table
                        border={0}
                        cellPadding={0}
                        cellSpacing={0}
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                verticalAlign: "top",
                                padding: 0,
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <table
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                style={{}}
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "0px",
                                        padding: "0px",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        width="100%"
                                        border={0}
                                        style={{
                                          color: "#000000",
                                          fontFamily:
                                            "Ubuntu, Helvetica, Arial, sans-serif",
                                          fontSize: "0px",
                                          lineHeight: "0px",
                                          tableLayout: "auto",
                                          width: "100%",
                                          border: "none",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width="75px">
                                              <table
                                                align="left"
                                                border={0}
                                                cellSpacing={0}
                                                cellPadding={0}
                                                width="75px"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        padding: 0,
                                                        position: `${editor.handleImagePosition()}`,
                                                        width: `${editor.handleImageWidth(
                                                          "image-1"
                                                        )}px`,
                                                      }}
                                                    >
                                                      <a
                                                        href={editor.handleUrl(
                                                          "buttons",
                                                          "button-1"
                                                        )}
                                                        style={editor.handleLinkSelected(
                                                          "button-1"
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        <img
                                                          height="auto"
                                                          src={editor.handleImageSrc(
                                                            "image-1"
                                                          )}
                                                          alt={editor.handleImageAlt(
                                                            "image-1"
                                                          )}
                                                          style={{
                                                            border: 0,
                                                            borderRadius:
                                                              "4px 0 0 4px",
                                                            display: "block",
                                                            outline: "none",
                                                            textDecoration:
                                                              "none",
                                                            width: "100%",
                                                            height: "auto",
                                                            fontSize: "13px",
                                                            padding: "0px",
                                                          }}
                                                          width={editor.handleImageWidth(
                                                            "image-1"
                                                          )}
                                                        />
                                                      </a>
                                                      {editor.handleImageHighlight(
                                                        "image-1"
                                                      )}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td
                                              style={{
                                                padding: 0,
                                                width: "20px",
                                              }}
                                            />
                                            <td>
                                              <table
                                                align="left"
                                                border={0}
                                                cellSpacing={0}
                                                cellPadding={0}
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style={{
                                                        padding: "0 0 0 0",
                                                        wordBreak: "break-word",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          background: "#ffffff",
                                                          color: "#000",
                                                          fontFamily:
                                                            '"Raleway", "Arial-Black", sans-serif',
                                                          fontSize: "14px",
                                                          lineHeight: "14px",
                                                          fontWeight: 900,
                                                          margin: 0,
                                                          textDecoration:
                                                            "none",
                                                          textTransform: "none",
                                                          paddingBottom: "5px",
                                                        }}
                                                      >
                                                        {editor.handleTitle(
                                                          "title-1"
                                                        )}
                                                      </p>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style={{
                                                        padding: "0 0 0 0",
                                                        wordBreak: "break-word",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          background: "#ffffff",
                                                          color: "#000",
                                                          fontFamily:
                                                            '"Lato", Arial, sans-serif',
                                                          fontSize: "12px",
                                                          lineHeight: "12px",
                                                          fontWeight: "normal",
                                                          margin: 0,
                                                          textDecoration:
                                                            "none",
                                                          textTransform: "none",
                                                          paddingBottom: "5px",
                                                        }}
                                                      >
                                                        {editor.handleTitle(
                                                          "title-2"
                                                        )}
                                                      </p>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        padding: 0,
                                                        width: "75px",
                                                      }}
                                                    >
                                                      <a
                                                        href={editor.handleUrl(
                                                          "buttons",
                                                          "button-1"
                                                        )}
                                                        style={editor.handleLinkSelected(
                                                          "button-1"
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        <img
                                                          height={15}
                                                          alt="pink arrow button"
                                                          src="https://makesense-outil-newsletter.s3-eu-west-1.amazonaws.com/pink-btn_poefa1.png"
                                                          style={{
                                                            border: 0,
                                                            borderRadius: "4px",
                                                            display: "block",
                                                            outline: "none",
                                                            textDecoration:
                                                              "none",
                                                            height: "15px",
                                                            width: "15px",
                                                            fontSize: "13px",
                                                          }}
                                                          width={15}
                                                        />
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/*[if mso | IE]>
      </td>

      <td
         class="" style="vertical-align:top;width:15px;"
      >
    <![endif]*/}
                  <div
                    className="mj-column-per-4 mj-outlook-group-fix"
                    style={{
                      fontSize: "0px",
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%",
                    }}
                  >
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              verticalAlign: "top",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              style={{}}
                              width="100%"
                            ></table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*[if mso | IE]>
      </td>

      <td
         class="small-box-shadow-outlook" style="vertical-align:top;width:278.4px;"
      >
    <![endif]*/}
                  <div
                    className="mj-column-per-48 mj-outlook-group-fix small-box-shadow"
                    style={{
                      boxShadow: "7px 7px 5px #0000000D",
                      borderRadius: "4px",

                      fontSize: "0px",
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #EAEAEA",
                        borderRadius: "4px",
                      }}
                    >
                      <table
                        border={0}
                        cellPadding={0}
                        cellSpacing={0}
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                verticalAlign: "top",
                                padding: 0,
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <table
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                style={{}}
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "0px",
                                        padding: "0px",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        width="100%"
                                        border={0}
                                        style={{
                                          color: "#000000",
                                          fontFamily:
                                            "Ubuntu, Helvetica, Arial, sans-serif",
                                          fontSize: "0px",
                                          lineHeight: "0px",
                                          tableLayout: "auto",
                                          width: "100%",
                                          border: "none",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width="75px">
                                              <table
                                                align="left"
                                                border={0}
                                                cellSpacing={0}
                                                cellPadding={0}
                                                width="75px"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        padding: 0,
                                                        position: `${editor.handleImagePosition()}`,
                                                        width: `${editor.handleImageWidth(
                                                          "image-2"
                                                        )}px`,
                                                      }}
                                                    >
                                                      <a
                                                        href={editor.handleUrl(
                                                          "buttons",
                                                          "button-2"
                                                        )}
                                                        style={editor.handleLinkSelected(
                                                          "button-2"
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        <img
                                                          height="auto"
                                                          src={editor.handleImageSrc(
                                                            "image-2"
                                                          )}
                                                          alt={editor.handleImageAlt(
                                                            "image-2"
                                                          )}
                                                          style={{
                                                            border: 0,
                                                            borderRadius:
                                                              "4px 0 0 4px",
                                                            display: "block",
                                                            outline: "none",
                                                            textDecoration:
                                                              "none",
                                                            width: "100%",
                                                            height: "auto",
                                                            fontSize: "13px",
                                                            padding: "0px",
                                                          }}
                                                          width={editor.handleImageWidth(
                                                            "image-2"
                                                          )}
                                                        />
                                                      </a>
                                                      {editor.handleImageHighlight(
                                                        "image-2"
                                                      )}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td
                                              style={{
                                                padding: 0,
                                                width: "20px",
                                              }}
                                            />
                                            <td>
                                              <table
                                                align="left"
                                                border={0}
                                                cellSpacing={0}
                                                cellPadding={0}
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style={{
                                                        padding: "0 0 0 0",
                                                        wordBreak: "break-word",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          background: "#ffffff",
                                                          color: "#000",
                                                          fontFamily:
                                                            '"Raleway", "Arial-Black", sans-serif',
                                                          fontSize: "14px",
                                                          lineHeight: "14px",
                                                          fontWeight: 900,
                                                          margin: 0,
                                                          textDecoration:
                                                            "none",
                                                          textTransform: "none",
                                                          paddingBottom: "5px",
                                                        }}
                                                      >
                                                        {editor.handleTitle(
                                                          "title-3"
                                                        )}
                                                      </p>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style={{
                                                        padding: "0 0 0 0",
                                                        wordBreak: "break-word",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          background: "#ffffff",
                                                          color: "#000",
                                                          fontFamily:
                                                            '"Lato", Arial, sans-serif',
                                                          fontSize: "12px",
                                                          lineHeight: "12px",
                                                          fontWeight: "normal",
                                                          margin: 0,
                                                          textDecoration:
                                                            "none",
                                                          textTransform: "none",
                                                          paddingBottom: "5px",
                                                        }}
                                                      >
                                                        {editor.handleTitle(
                                                          "title-4"
                                                        )}
                                                      </p>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        padding: 0,
                                                        width: "75px",
                                                      }}
                                                    >
                                                      <a
                                                        href={editor.handleUrl(
                                                          "buttons",
                                                          "button-2"
                                                        )}
                                                        style={editor.handleLinkSelected(
                                                          "button-2"
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        <img
                                                          height={15}
                                                          alt="pink arrow button"
                                                          src="https://res.cloudinary.com/dnl53glfq/image/upload/v1601237540/pink-btn_poefa1.png"
                                                          style={{
                                                            border: 0,
                                                            borderRadius: "4px",
                                                            display: "block",
                                                            outline: "none",
                                                            textDecoration:
                                                              "none",
                                                            height: "15px",
                                                            width: "15px",
                                                            fontSize: "13px",
                                                          }}
                                                          width={15}
                                                        />
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/*[if mso | IE]>
      </td>

  </tr>

            </table>
          <![endif]*/}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*[if mso | IE]>
    </td>
  </tr>
</table>
<![endif]*/}
        {/* template 6 */}
      </div>
      {editor.handleDivider()}
    </>
  );
};

export default Template6;
