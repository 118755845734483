import React from "react";
import Editor from "../../utils/editFunctions";

const Template23 = ({ template, statusMode }) => {
  const editor = new Editor(template, statusMode);

  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        {/*[if mso | IE]>
        <table
          align="center" border="0" cellpadding="0" cellspacing="0" class="content-padding-outlook" style="width:600px;" width="600"
        >
          <tr>
            <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
        <![endif]*/}
        <div
          className="content-padding"
          style={{
            background: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            margin: "0px auto",
            maxWidth: "600px",
          }}
        >
          <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
              background: editor.handleTemplateBgColor(),
              backgroundColor: editor.handleTemplateBgColor(),
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    direction: "ltr",
                    fontSize: "0px",
                    padding: `${editor.handleTopMargin()} 10% ${editor.handleBottomMargin()} 10%`,
                    textAlign: "center",
                  }}
                >
                  {/*[if mso | IE]>
                    <table border="0" cellpadding="0" cellspacing="0">
                      <tr>
                        <td style="vertical-align:top;width:278.4px;">
                  <![endif]*/}
                  <div
                    className="mj-column-per-48 mj-outlook-group-fix"
                    style={{
                      fontSize: "0px",
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%",
                    }}
                  >
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              verticalAlign: "top",
                              padding: 0,
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              style={{}}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="left"
                                    className="small-box-shadow"
                                    style={{
                                      boxShadow: "7px 7px 5px #0000000D",
                                      borderRadius: "4px",
                                      fontSize: "0px",
                                      padding: "0px",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #EAEAEA",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        width={215}
                                        border={0}
                                        style={{
                                          tableLayout: "auto",
                                          width: "215px",
                                          border: "none",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td>
                                              <table
                                                border={0}
                                                cellSpacing={0}
                                                cellPadding={0}
                                                width="75px"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        padding: 0,

                                                        position: `${editor.handleImagePosition()}`,
                                                        width: `${editor.handleImageWidth(
                                                          "image-1"
                                                        )}px`,
                                                      }}
                                                    >
                                                      {" "}
                                                      <a
                                                        href={editor.handleUrl(
                                                          "buttons",
                                                          "button-1"
                                                        )}
                                                        style={editor.handleLinkSelected(
                                                          "button-1"
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        <img
                                                          height="auto"
                                                          src={editor.handleImageSrc(
                                                            "image-1"
                                                          )}
                                                          alt={editor.handleImageAlt(
                                                            "image-1"
                                                          )}
                                                          style={{
                                                            border: 0,
                                                            borderRadius:
                                                              "4px 0px 0px 4px",
                                                            display: "block",
                                                            outline: "none",
                                                            textDecoration:
                                                              "none",
                                                            height: "75px",
                                                            width: "75px",
                                                            fontSize: "13px",
                                                          }}
                                                          width={editor.handleImageWidth(
                                                            "image-1"
                                                          )}
                                                        />
                                                      </a>
                                                      {editor.handleImageHighlight(
                                                        "image-1"
                                                      )}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td
                                              style={{
                                                padding: 0,
                                                width: "20px",
                                              }}
                                            />
                                            <td>
                                              <table
                                                border={0}
                                                cellSpacing={0}
                                                cellPadding={0}
                                                width="120px"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style={{
                                                        fontSize: "0px",
                                                        padding: "0 0 0 0",
                                                        paddingBottom: "5px",
                                                        wordBreak: "break-word",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          display:
                                                            "inline-block",

                                                          color: "#0C3944",
                                                          fontFamily:
                                                            '"Raleway", "Arial-Black", sans-serif',
                                                          fontSize: "11px",
                                                          lineHeight: "12px",
                                                          fontWeight: "bold",
                                                          textAlign: editor.handleTextAlign("text-1"),
                                                          margin: 0,
                                                          textDecoration:
                                                            "none",
                                                          textTransform: "none",
                                                          msoPaddingAlt: "0px",
                                                        }}
                                                      >
                                                        {editor.handleText(
                                                          "text-1"
                                                        )}
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*[if mso | IE]>
      </td>

      <td
         class="" style="vertical-align:top;width:23.2px;"
      >
    <![endif]*/}
                  <div
                    className="mj-column-per-4 mj-outlook-group-fix"
                    style={{
                      fontSize: "0px",
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%",
                    }}
                  >
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      style={{ verticalAlign: "top" }}
                      width="100%"
                    ></table>
                  </div>
                  {/*[if mso | IE]>
                  </td>
                  <td style="vertical-align:top;width:278.4px;"
                  >
                  <![endif]*/}
                  <div
                    className="mj-column-per-48 mj-outlook-group-fix"
                    style={{
                      fontSize: "0px",
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%",
                    }}
                  >
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              verticalAlign: "top",
                              padding: 0,
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="left"
                                    className="small-box-shadow"
                                    style={{
                                      boxShadow: "7px 7px 5px #0000000D",
                                      fontSize: "0px",
                                      borderRadius: "4px",
                                      padding: "0px",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #EAEAEA",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        width={215}
                                        border={0}
                                        style={{
                                          tableLayout: "auto",
                                          width: "215px",
                                          border: "none",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td>
                                              <table
                                                border={0}
                                                cellSpacing={0}
                                                cellPadding={0}
                                                width="75px"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        padding: 0,
                                                        position: `${editor.handleImagePosition()}`,
                                                        width: `${editor.handleImageWidth(
                                                          "image-2"
                                                        )}px`,
                                                      }}
                                                    >
                                                      <a
                                                        href={editor.handleUrl(
                                                          "buttons",
                                                          "button-2"
                                                        )}
                                                        style={editor.handleLinkSelected(
                                                          "button-2"
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        <img
                                                          height="auto"
                                                          src={editor.handleImageSrc(
                                                            "image-2"
                                                          )}
                                                          alt={editor.handleImageAlt(
                                                            "image-2"
                                                          )}
                                                          style={{
                                                            border: 0,
                                                            borderRadius:
                                                              "4px 0px 0px 4px",
                                                            display: "block",
                                                            outline: "none",
                                                            textDecoration:
                                                              "none",
                                                            height: "75px",
                                                            width: "75px",
                                                            fontSize: "13px",
                                                          }}
                                                          width={editor.handleImageWidth(
                                                            "image-2"
                                                          )}
                                                        />
                                                      </a>
                                                      {editor.handleImageHighlight(
                                                        "image-2"
                                                      )}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                            <td
                                              style={{
                                                padding: 0,
                                                width: "20px",
                                              }}
                                            />
                                            <td>
                                              <table
                                                border={0}
                                                cellSpacing={0}
                                                cellPadding={0}
                                                width="120px"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      align="left"
                                                      style={{
                                                        fontSize: "0px",
                                                        padding: "0 0 0 0",
                                                        paddingBottom: "5px",
                                                        wordBreak: "break-word",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          display:
                                                            "inline-block",

                                                          color: "#0C3944",
                                                          fontFamily:
                                                            '"Raleway", "Arial-Black", sans-serif',
                                                          fontSize: "11px",
                                                          lineHeight: "12px",
                                                          fontWeight: "bold",
                                                          margin: 0,
                                                          textDecoration: "none",
                                                          textAlign: editor.handleTextAlign("text-2"),
                                                          textTransform: "none",
                                                          msoPaddingAlt: "0px",
                                                        }}
                                                      >
                                                        {editor.handleText(
                                                          "text-2"
                                                        )}
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*[if mso | IE]>
                        </td>
                      </tr>
                    </table>
                   <![endif]*/}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*[if mso | IE]>
            </td>
          </tr>
        </table>
        <![endif]*/}
      </div>
      {editor.handleDivider()}
    </>
  );
};

export default Template23;
