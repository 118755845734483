import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import EditorInterface from "./editorFields/EditorInterFace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  width: 0px;
  height: calc(100vh - 180px);
  position: relative;
  z-index: 20;
`;

const MenuWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 0px 40px 0px 0px;
  height: calc(100vh - 60px);
  position: absolute;
  top: -120px;
  left: ${({ isVisible }) => (isVisible ? "0px" : "-520px")};
  transition: all 0.4s ease;
  box-sizing: border-box;
  width: 500px;
  background-color: #003643;
  padding: 50px 20px 0px 20px;
  box-shadow: 2px 2px 10px 0px #003643;
`;

const AdminContainer = styled.div`
  height: calc(100vh - 110px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  left: 0;

  position: relative;
  box-sizing: border-box;
`;

const HideMenuBtn = styled.button`
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 2000;
  background-color: #fff;
  border: none;
  border-radius: 30px;
  height: 30px;
  width: 30px;
  outline: none;
  display: flex;
  align-items: center;
  font-size: 24px;
  justify-content: center;
  color: #003643;
  cursor: pointer;
`;

const NewsletterBuilderMenu = (props) => {
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();

  return (
    <Container>
      <MenuWrapper isVisible={newsletterBuilder.newsletterMenu.isVisible}>
        <HideMenuBtn
          onClick={() => {
            dispatch({
              type: "HIDE_NEWSLETTER_MENU",
            });
          }}
        >
          <FontAwesomeIcon icon="times" />
        </HideMenuBtn>
        <AdminContainer>
          {newsletterBuilder.selectedTemplateId !== null ? (
            <EditorInterface
              template={
                newsletterBuilder.templates[
                  newsletterBuilder.selectedTemplateId
                ]
              }
            ></EditorInterface>
          ) : (
            ""
          )}
        </AdminContainer>
      </MenuWrapper>
    </Container>
  );
};

export default NewsletterBuilderMenu;
