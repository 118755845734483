import React, { useState, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../utils/cropImage";
import { getRotatedImage } from "../../../utils/rotateImage";
import { getOrientation } from "get-orientation/browser";
import { useSelector, useDispatch } from "react-redux";
import {
  CropperContainer,
  AddFile,
  InputFile,
  CropperInterface,
  SaveImageBtn,
  InterfaceSection,
  ImageSizeWrapper,
  SizeLabel,
  SizeText,
} from "./Styles";
import Slider from "../../Slider";
import styled from 'styled-components';

const CenteredContainer = styled.div`
  height: 100%;
  display:flex;
  align-items:center;
  justify-content: center;
  background-color: #ddd;
`

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

const AdminHandleImage = (props) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  let imgSrc = null;

  const { fieldType, fieldId, content } = { ...admin.modal.modalData }; //       templateId: templateId,

  imgSrc = content.imgSrc;

  const [imageData, setImageData] = useState({
    imageSrc: imgSrc,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect:
      admin.modal.modalData.content.imgWidth /
      admin.modal.modalData.content.imgHeight,
    croppedAreaPixels: null,
    croppedImage: null,
    isCropping: false,
    originSize: null,
  });

  const [sliderValue, setSliderValue] = useState(1);

  const handleSlideZoom = (event) => {
    const newZoomValue = event.target.value;
    const newImageData = { ...imageData };

    if (
      imageData.originSize.height / newZoomValue <
      admin.modal.modalData.content.imgHeight ||
      imageData.originSize.width / newZoomValue <
      admin.modal.modalData.content.imgWidth
    ) {
    } else {
      newImageData.zoom = newZoomValue;
      setImageData(newImageData);
      setSliderValue(newZoomValue);
    }
  };

  const onCropChange = (crop) => {
    const newImageData = { ...imageData };
    newImageData.crop = crop;
    setImageData(newImageData);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    const newImageData = { ...imageData };
    newImageData.croppedAreaPixels = croppedAreaPixels;

    setImageData(newImageData);
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const _URL = window.URL || window.webkitURL;
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      const img = new Image();
      img.src = _URL.createObjectURL(file);
      img.onload = async function () {
        if (
          this.width < admin.modal.modalData.content.imgWidth ||
          this.height < admin.modal.modalData.content.imgHeight
        ) {
          alert(t("handleImage.uploadWarning"));
        } else {
          const orientation = await getOrientation(file);
          const rotation = ORIENTATION_TO_ANGLE[orientation];
          if (rotation) {
            imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
          }
          const newImageData = { ...imageData };

          newImageData.imageSrc = imageDataUrl;
          newImageData.crop = { x: 0, y: 0 };
          newImageData.zoom = 1;
          newImageData.type = file.type;

          setImageData(newImageData);

          setSliderValue(1);
        }
      };

      // apply rotation if needed
    }
  };

  const cropImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageData.imageSrc,
        imageData.croppedAreaPixels,
        0,
        imageData.type,
        admin.modal.modalData.crop === false
      );

      const formData = new FormData();

      formData.append("files", croppedImage);
      formData.append("nom", "kiki");
      const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/upload/image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      dispatch({
        type: props.footer ? "UPDATE_FOOTER_IMAGE" : "UPDATE_HEADER_IMAGE",
        data: {
          fieldType: fieldType,
          fieldId: fieldId,
          imgSrc: response.data.imageUrl,
        },
      });
    } catch (e) {
      console.error(e);
      this.setState({
        isCropping: false,
      });
    }
  };

  return (
    <CropperContainer>
      <div className="App">
        <div className="crop-container">
          {admin.modal.adminContent === "admin-crop-image" && admin.modal.modalData.crop !== false ? (
            <Cropper
              image={imageData.imageSrc}
              crop={imageData.crop}
              zoom={imageData.zoom}
              maxZoom={5}
              aspect={imageData.aspect}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onMediaLoaded={(mediaSize) => {
                const newImageData = { ...imageData };

                newImageData.originSize = {
                  width: mediaSize.naturalWidth,
                  height: mediaSize.naturalHeight,
                };
                setImageData(newImageData);
              }}
              disableAutomaticStylesInjection={true}
            />
          ) : (
              <CenteredContainer>
                <img src={imageData.imageSrc} alt="preview" style={{maxWidth: '100%'}} />
              </CenteredContainer>
            )}
        </div>
        <CropperInterface>
          <ImageSizeWrapper>
            <SizeLabel>{t("handleImage.minWidthLabel")}</SizeLabel>
            <SizeText>{`${admin.modal.modalData.content.imgWidth}px`}</SizeText>
            <SizeLabel>{t("handleImage.minHeightLabel")}</SizeLabel>
            <SizeText>
              {`${admin.modal.modalData.content.imgHeight}px`}
            </SizeText>
          </ImageSizeWrapper>

          <InterfaceSection>
            <AddFile onClick={handleClick}>
              {t("handleImage.uploadBtn")}
            </AddFile>
            <InputFile
              type="file"
              onChange={onFileChange}
              ref={hiddenFileInput}
            />
          </InterfaceSection>
          <InterfaceSection>
            <SaveImageBtn onClick={cropImage}>
              {t("handleImage.saveBtn")}
            </SaveImageBtn>
          </InterfaceSection>
          {admin.modal.modalData.crop !== false ? (
            <InterfaceSection>
              <Slider
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
                min={1}
                max={5}
                step={0.1}
                action={handleSlideZoom}
              ></Slider>
            </InterfaceSection>
          ) : null}
        </CropperInterface>
      </div>
    </CropperContainer>
  );
};

export default AdminHandleImage;
