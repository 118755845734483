import React from "react";
import Editor from "../../utils/editFunctions";

const Template20 = ({ template, statusMode }) => {
  const editor = new Editor(template, statusMode);

  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        {/*[if mso | IE]>
<table
   align="center" border="0" cellpadding="0" cellspacing="0" class="content-padding-outlook" style="width:600px;" width="600"
>
  <tr>
    <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]*/}
        <div
          className="content-padding"
          style={{
            background: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            margin: "0px auto",
            maxWidth: "600px",
          }}
        >
          <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
              background: editor.handleTemplateBgColor(),
              backgroundColor: editor.handleTemplateBgColor(),
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    direction: "ltr",
                    fontSize: "0px",
                    padding: `${editor.handleTopMargin()}  10% ${editor.handleBottomMargin()} 10%`,
                    textAlign: "center",
                  }}
                >
                  {/*[if mso | IE]>
                  <table border="0" cellpadding="0" cellspacing="0">
                    <tr>
                      <td style="vertical-align:top;width:174px;">
                  <![endif]*/}
                  <div
                    className="mj-column-per-30 mj-outlook-group-fix"
                    style={{
                      fontSize: "0px",
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%",
                    }}
                  >
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              verticalAlign: "top",
                              padding: "0 0 10px 0",
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              style={{}}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="center"
                                    style={{
                                      fontSize: "0px",
                                      padding: "0 0 10px 0",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <table
                                      border={0}
                                      cellPadding={0}
                                      cellSpacing={0}
                                      style={{
                                        borderCollapse: "collapse",
                                        borderSpacing: "0px",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              position: `${editor.handleImagePosition()}`,
                                              width: `${editor.handleImageWidth(
                                                "image-1"
                                              )}px`,
                                            }}
                                          >
                                            <a
                                              href={editor.handleUrl(
                                                "buttons",
                                                "button-1"
                                              )}
                                              style={editor.handleLinkSelected(
                                                "button-1"
                                              )}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <img
                                                height="auto"
                                                src={editor.handleImageSrc(
                                                  "image-1"
                                                )}
                                                alt={editor.handleImageAlt(
                                                  "image-1"
                                                )}
                                                style={{
                                                  border: 0,
                                                  borderRadius: "20px",
                                                  display: "block",
                                                  outline: "none",
                                                  textDecoration: "none",
                                                  height: "auto",
                                                  width: "100%",
                                                  fontSize: "13px",
                                                }}
                                                width={editor.handleImageWidth(
                                                  "image-1"
                                                )}
                                              /></a>
                                            {editor.handleImageHighlight(
                                              "image-1"
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*[if mso | IE]>
                    </td>
                    <td style="vertical-align:top;width:34.8px;">
                  <![endif]*/}
                  <div
                    className="mj-column-per-6 mj-outlook-group-fix"
                    style={{
                      fontSize: "0px",
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%",
                    }}
                  >
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      style={{ verticalAlign: "top" }}
                      width="100%"
                    ></table>
                  </div>
                  {/*[if mso | IE]>
                  </td>
                  <td style="vertical-align:top;width:371.2px;">
                <![endif]*/}
                  <div
                    className="mj-column-per-64 mj-outlook-group-fix"
                    style={{
                      fontSize: "0px",
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%",
                    }}
                  >
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              verticalAlign: "top",
                              padding: "0 0 10px 0",
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              width="100%"
                            >
                              <tbody>
                                {template.content.options.switchTag === undefined || template.content.options.switchTag.isActived === true ? (
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "0px",
                                        padding: "0 0 0 0",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "16px",
                                          lineHeight: "24px",
                                          textAlign: "left",
                                        }}
                                      >
                                        <table
                                          width="100%"
                                          border={0}
                                          cellSpacing={0}
                                          cellPadding={0}
                                        >
                                          <tbody>
                                            <tr>
                                              <td>
                                                <table
                                                  border={0}
                                                  cellSpacing={0}
                                                  cellPadding={0}
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <span
                                                          style={{
                                                            fontFamily:
                                                              '"Raleway", "Arial-Black", sans-serif',
                                                            fontSize: "11px",
                                                            fontWeight: "bold",
                                                            color: "#7A7A7A",
                                                            textTransform:
                                                              "uppercase",
                                                            lineHeight: "24px",
                                                          }}
                                                        >
                                                          {editor.handleTitle(
                                                            "title-1"
                                                          )}
                                                        </span>
                                                      </td>
                                                      <td>
                                                        <table
                                                          border={0}
                                                          cellSpacing={0}
                                                          cellPadding={0}
                                                        >
                                                          {/* tag button */}
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                align="left"
                                                                vertical-align="middle"
                                                                style={{
                                                                  fontSize: "0px",
                                                                  padding:
                                                                    "0 0 0 0",
                                                                  wordBreak:
                                                                    "break-word",
                                                                }}
                                                              >
                                                                <table
                                                                  border={0}
                                                                  cellPadding={0}
                                                                  cellSpacing={0}
                                                                  style={{
                                                                    borderCollapse:
                                                                      "separate",
                                                                    lineHeight:
                                                                      "100%",
                                                                  }}
                                                                >
                                                                  <tbody>
                                                                    <tr>
                                                                      <td width="10px" />
                                                                      <td
                                                                        align="center"
                                                                        bgcolor={editor.handleBgColor(
                                                                          "tags",
                                                                          "tag-1"
                                                                        )}
                                                                        style={{
                                                                          border:
                                                                            "none",
                                                                          borderRadius:
                                                                            "100px",
                                                                          cursor:
                                                                            "auto",
                                                                          msoPaddingAlt:
                                                                            "4px 20px",
                                                                          background: editor.handleBgColor(
                                                                            "tags",
                                                                            "tag-1"
                                                                          ),
                                                                        }}
                                                                        valign="middle"
                                                                      >
                                                                        <div
                                                                          style={{
                                                                            display:
                                                                              "inline-block",
                                                                            background: editor.handleBgColor(
                                                                              "tags",
                                                                              "tag-1"
                                                                            ),
                                                                            color:
                                                                              "#ffffff",
                                                                            fontFamily:
                                                                              '"Raleway", "Arial-Black", sans-serif',
                                                                            fontSize:
                                                                              "10px",
                                                                            fontWeight:
                                                                              "bold",
                                                                            lineHeight:
                                                                              "120%",
                                                                            margin: 0,
                                                                            textDecoration:
                                                                              "none",
                                                                            textTransform:
                                                                              "none",
                                                                            padding:
                                                                              "4px 20px",
                                                                            msoPaddingAlt:
                                                                              "0px",
                                                                            borderRadius:
                                                                              "100px",
                                                                          }}
                                                                        >
                                                                          {editor.handleTagText(
                                                                            "tag-1"
                                                                          )}
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                              </td>
                                                            </tr>
                                                            {/* tag button */}
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>) : null}
                                <tr>
                                  <td
                                    align="left"
                                    style={{
                                      fontSize: "0px",
                                      padding: "0px 0 0 0",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontFamily:
                                          '"Raleway", "Arial-Black", sans-serif',
                                        fontSize: "16px",
                                        fontWeight: 900,

                                        lineHeight: "25px",
                                        textAlign: "left",
                                        color: "#0C3944",
                                      }}
                                    >
                                      {editor.handleTitle("title-2")}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="left"
                                    style={{
                                      fontSize: "0px",
                                      padding: "4px 0 0 0",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontFamily: '"Lato", Arial, sans-serif',
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        lineHeight: "25px",
                                        textAlign: editor.handleTextAlign("text-1"),
                                        color: "#0C3944",
                                      }}
                                    >
                                      {editor.handleText("text-1")}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="left"
                                    style={{
                                      fontSize: "0px",
                                      padding: "10px 0 10px 0",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontFamily:
                                          '"Raleway", "Arial-Black", sans-serif',
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "25px",
                                        textAlign: "left",
                                        color: "#0C3944",
                                      }}
                                    >
                                      <a
                                        style={{
                                          color: editor.handleTextColor(
                                            "buttons",
                                            "button-1"
                                          ),
                                        }}
                                        href={editor.handleUrl(
                                          "buttons",
                                          "button-1"
                                        )}
                                      >
                                        {editor.handleBtnText("button-1")}
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*[if mso | IE]>
                      </td>
                    </tr>
                  </table>
                <![endif]*/}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*[if mso | IE]>
            </td>
          </tr>
        </table>
        <![endif]*/}
      </div>
      {editor.handleDivider()}
    </>
  );
};

export default Template20;
