import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LoaderAnimation from "../../../components/LoaderAnimation";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Header, UserNumber, Title, AddUserBtn } from "./styles";
import UserList from "../../../components/AdminUser/UserList";
import AdminLayout from "../../../components/AdminLayout";
import { useTranslation } from "react-i18next";

const UserAdmin = (props) => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [userList, setUserList] = useState([]);
  const [deleted, setDeleted] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/users`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await setUserList(response.data);

      await setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted]);

  return (
    <AdminLayout>
      <Header>
        <Title>
          {t("adminUserList.title")}
          <UserNumber>{isLoading === true ? " " : userList.length}</UserNumber>
        </Title>

        <AddUserBtn to="/user/add">
          <FontAwesomeIcon icon="user-plus" />
          <span>{t("adminUserList.addUserBtn")}</span>
        </AddUserBtn>
      </Header>
      {isLoading === true ? (
        <LoaderAnimation></LoaderAnimation>
      ) : (
        <UserList setDeleted={setDeleted} userList={userList}></UserList>
      )}
    </AdminLayout>
  );
};

export default UserAdmin;
