import React from "react";
import Editor from "../../utils/editFunctions";

const Header2 = ({ template, statusMode }) => {
  const editor = new Editor(template, statusMode);
  const fullWidthImageId = editor.template.content.images["image-3"]
    ? "image-3"
    : "image-2";

  return (
    <div>
      <div style={{ backgroundColor: "#FFF" }}>
        {/* header */}
        <table
          align="center"
          border={0}
          cellPadding={0}
          cellSpacing={0}
          style={{ width: "100%" }}
        >
          <tbody>
            <tr>
              <td>
                {/*[if mso | IE]>
<table
   align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:1200px;" width="1200"
>
  <tr>
    <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]*/}
                <div style={{ margin: "0px auto", maxWidth: "1200px" }}>
                  <table
                    align="center"
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    style={{ width: "100%" }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            direction: "ltr",
                            fontSize: "0px",
                            padding: "0",
                            textAlign: "center",
                          }}
                        >
                          {/*[if mso | IE]>
            <table  border="0" cellpadding="0" cellspacing="0">

  <tr>

      <td
         class="" style="vertical-align:top;width:1200px;"
      >
    <![endif]*/}
                          <div
                            className="mj-column-per-100 mj-outlook-group-fix"
                            style={{
                              fontSize: "0px",
                              textAlign: "left",
                              direction: "ltr",
                              display: "inline-block",
                              verticalAlign: "top",
                              width: "100%",
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      backgroundColor: editor.handleBgColor(
                                        "backgroundColors",
                                        "backgroundColor-1"
                                      ),
                                      verticalAlign: "top",
                                      padding: 0,
                                    }}
                                  >
                                    <table
                                      border={0}
                                      cellPadding={0}
                                      cellSpacing={0}
                                      style={{}}
                                      width="100%"
                                    >
                                      <tbody>
                                        {template.hasArchiveLink ? (
                                          <tr>
                                            <td
                                              align="center"
                                              style={{
                                                fontSize: "16px",
                                                paddingTop: "20px",
                                              }}
                                            >
                                              <a
                                                style={{
                                                  fontFamily: '"Raleway", "Arial-Black", sans-serif',
                                                  color:
                                                    template.archiveLinkColor ||
                                                    "#fff",
                                                }}
                                                href={
                                                  template.archiveLinkUrl ||
                                                  "*|ARCHIVE|*"
                                                }
                                              >
                                                {template.archiveLinkText ||
                                                  "Lire cette newsletter en ligne"}
                                              </a>
                                            </td>
                                          </tr>
                                        ) : null}
                                        {template.hasFullWidthImage ? (
                                          <tr>
                                            <td
                                              align="center"
                                              style={{
                                                fontSize: "0px",
                                                padding: "0px 25px",
                                                paddingRight: "0px",
                                                paddingBottom: "0px",
                                                paddingLeft: "0px",
                                                wordBreak: "break-word",
                                              }}
                                            >
                                              <table
                                                border={0}
                                                cellPadding={0}
                                                cellSpacing={0}
                                                style={{
                                                  borderCollapse: "collapse",
                                                  borderSpacing: "0px",
                                                }}
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        position: `${editor.handleImagePosition()}`,
                                                        width: `${editor.handleImageWidth(
                                                          fullWidthImageId
                                                        )}px`,
                                                      }}
                                                    >
                                                      {/* eslint-disable-next-line */}
                                                      <a href="#">
                                                        <img
                                                          height="auto"
                                                          src={editor.handleImageSrc(
                                                            fullWidthImageId
                                                          )}
                                                          alt={editor.handleImageAlt(
                                                            fullWidthImageId
                                                          )}
                                                          style={{
                                                            border: "none",
                                                            display: "block",
                                                            outline: "none",
                                                            textDecoration:
                                                              "none",
                                                            height: "auto",
                                                            width: "100%",
                                                            fontSize: "13px",
                                                          }}
                                                          width={editor.handleImageWidth(
                                                            fullWidthImageId
                                                          )}
                                                        />
                                                      </a>
                                                      {editor.handleImageHighlight(
                                                        fullWidthImageId
                                                      )}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        ) : (
                                          <tr>
                                            <td
                                              align="center"
                                              style={{
                                                fontSize: "0px",
                                                padding: 0,
                                                wordBreak: "break-word",
                                              }}
                                            >
                                              <table
                                                border={0}
                                                cellPadding={0}
                                                cellSpacing={0}
                                                style={{
                                                  borderCollapse: "collapse",
                                                  borderSpacing: "0px",
                                                }}
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        position: `${editor.handleImagePosition()}`,
                                                        width: `${editor.handleImageWidth(
                                                          "image-1"
                                                        )}px`,
                                                      }}
                                                    >
                                                      {/* eslint-disable-next-line */}
                                                      <a href="#">
                                                        <img
                                                          height="auto"
                                                          src={editor.handleImageSrc(
                                                            "image-1"
                                                          )}
                                                          alt={editor.handleImageAlt(
                                                            "image-1"
                                                          )}
                                                          style={{
                                                            border: 0,
                                                            display: "block",
                                                            outline: "none",
                                                            textDecoration:
                                                              "none",
                                                            height: "auto",
                                                            width: "100%",
                                                            fontSize: "13px",
                                                          }}
                                                          width={editor.handleImageWidth(
                                                            "image-1"
                                                          )}
                                                        />
                                                      </a>
                                                      {editor.handleImageHighlight(
                                                        "image-1"
                                                      )}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        )}
                                        {template.hasSecondImage === true ||
                                        template.hasSecondImage ===
                                          undefined ? (
                                          <tr>
                                            <td
                                              align="center"
                                              style={{
                                                fontSize: "0px",
                                                padding: "0px 25px",
                                                paddingRight: "0px",
                                                paddingBottom: "0px",
                                                paddingLeft: "0px",
                                                wordBreak: "break-word",
                                              }}
                                            >
                                              <table
                                                border={0}
                                                cellPadding={0}
                                                cellSpacing={0}
                                                style={{
                                                  borderCollapse: "collapse",
                                                  borderSpacing: "0px",
                                                }}
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{
                                                        position: `${editor.handleImagePosition()}`,
                                                        width: `${editor.handleImageWidth(
                                                          "image-2"
                                                        )}px`,
                                                      }}
                                                    >
                                                      {/* eslint-disable-next-line */}
                                                      <a href="#">
                                                        <img
                                                          height="auto"
                                                          src={editor.handleImageSrc(
                                                            "image-2"
                                                          )}
                                                          alt={editor.handleImageAlt(
                                                            "image-2"
                                                          )}
                                                          style={{
                                                            border: "none",
                                                            display: "block",
                                                            outline: "none",
                                                            textDecoration:
                                                              "none",
                                                            height: "auto",
                                                            width: "100%",
                                                            fontSize: "13px",
                                                          }}
                                                          width={editor.handleImageWidth(
                                                            "image-2"
                                                          )}
                                                        />
                                                      </a>
                                                      {editor.handleImageHighlight(
                                                        "image-2"
                                                      )}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        ) : null}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/*[if mso | IE]>
      </td>

  </tr>

            </table>
          <![endif]*/}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/*[if mso | IE]>
    </td>
  </tr>
</table>
<![endif]*/}
              </td>
            </tr>
          </tbody>
        </table>
        {/* header */}
      </div>
    </div>
  );
};

export default Header2;
