import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import Cookies from "js-cookie";
import App from "./App";
import "./helpers/i18n";

import * as serviceWorker from "./serviceWorker";
const templates = require("./datas/templates.json");
const footer = require("./datas/footer.json");

const handleOrderTemplate = (templateArray) => {
  return Object.keys(templates).reduce((newArray, templateKey) => {
    if (templateArray.indexOf(templateKey) !== -1) {
      newArray.push(templateKey);
    }

    return newArray;
  }, []);
};

const tokenFromCookie = Cookies.get("userToken");
const roleFromCookie = Cookies.get("userRole");
const userIdFromCookie = Cookies.get("userId");

const userReducer = (
  state = {
    modal: { isOpen: false },
    id: userIdFromCookie !== undefined ? userIdFromCookie : null,
    email: "",
    password: "",
    token: tokenFromCookie !== undefined ? tokenFromCookie : null,
    role: roleFromCookie !== undefined ? roleFromCookie : null,
  },
  action
) => {
  const newState = { ...state };
  switch (action.type) {
    case "GET_TOKEN":
      newState.token = Cookies.get("userToken");
      newState.role = Cookies.get("userRole");
      newState.id = Cookies.get("userId");
      newState.modal.isOpen = false;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "LOG_OUT":
      Cookies.remove("userToken");
      Cookies.remove("userRole");
      Cookies.remove("userId");
      newState.id = null;
      newState.token = null;
      newState.role = null;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    default:
      return state;
    // eslint-disable-next-line no-unreachable
  }
};

const adminReducer = (
  state = {
    modal: { isOpen: false, adminContent: "empty", modalData: null },
    category: {
      title: "",
      country: "fr",
      header: null,
      headerContent: {},
      footer: null,
      templates: [],
    },
  },
  action
) => {
  const newState = { ...state };
  switch (action.type) {
    case "OPEN_ADMIN_MODAL":
      newState.modal.isOpen = true;
      newState.modal.adminContent = action.adminContent;
      newState.modal.modalData = action.modalData;
      newState.modal.isFooter = action.isFooter;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "HIDE_HEADER_IMAGE":
      newState.category.header.hasSecondImage = false;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "SHOW_HEADER_IMAGE":
      newState.category.header.hasSecondImage = true;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "SET_HEADER_FULL_IMAGE":
      newState.category.header.hasFullWidthImage = action.value;
      if (!newState.category.header.content.images['image-3']) {
        newState.category.header.content.images['image-3'] = Object.assign({}, newState.category.header.content.images['image-2']);
      }
      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "CLOSE_ADMIN_MODAL":
      newState.modal.isOpen = false;
      newState.modal.adminContent = "empty";
      newState.modal.modalData = null;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "EDIT_CATEGORY_TITLE":
      newState.category.title = action.title;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "UPDATE_SOCIAL_ICON_LINK":
      newState.category.footer.socialLinks[action.data.index]["icon"] =
        action.data.iconName;

      return newState;
    case "UPDATE_SOCIAL_URL":
      newState.category.footer.socialLinks[action.data.index]["url"] =
        action.data.url;

      return newState;
    case "UPDATE_UNSUBSCRIBE_LINK":
      newState.category.footer["unsubscribeLink"] = action.url;

      return newState;

    case "SET_FOOTER_CUSTOM_IMAGE":
      newState.category.footer.content.hasCustomImage = action.value;
      if (action.value && (!newState.category.footer.content["images"] || !newState.category.footer.content["images"]["image-1"])) {
        newState.category.footer.content.imageWidth = footer["footer-1"].content.imageWidth;
        newState.category.footer.content.images = Object.assign({}, footer["footer-1"].content.images);
      }

      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "CHANGE_FOOTER_IMAGE_WIDTH":
      newState.category.footer.content.imageWidth = action.data.value;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "ADD_SOCIAL_ICON_LINK":
      newState.category.footer.socialLinks.push(action.data);

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "REMOVE_SOCIAL_ICON_ITEM":
      newState.category.footer.socialLinks.splice(action.index, 1);

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "UPDATE_HEADER_IMAGE":
      newState.category.header.content[action.data.fieldType][
        action.data.fieldId
      ].imgSrc = action.data.imgSrc;
      newState.modal.isOpen = false;
      newState.modal.adminContent = "empty";
      newState.modal.modalData = null;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "UPDATE_FOOTER_IMAGE":
      newState.category.footer.content[action.data.fieldType][
        action.data.fieldId
      ].imgSrc = action.data.imgSrc;
      newState.modal.isOpen = false;
      newState.modal.adminContent = "empty";
      newState.modal.modalData = null;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "UPDATE_HEADER_BG_COLOR":
      if (newState.category.header.content["backgroundColors"] !== undefined) {
        newState.category.header.content["backgroundColors"][
          "backgroundColor-1"
        ].bgColor = action.bgColor;
      } else {
        newState.category.header.content.backgroundColors = {
          "backgroundColor-1": {
            model: "backgroundColor",
            bgColor: action.bgColor,
          },
        };
      }
      newState.category.footer.content["backgroundColors"]["textColor-1"].bgColor = action.bgColor;
      newState.category.footer.content["backgroundColors"]["textColor-1"].index = action.index;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "SET_HEADER_ARCHIVE_LINK":
      newState.category.header.hasArchiveLink = action.value;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "SET_HEADER_ARCHIVE_LINK_URL":
      newState.category.header.archiveLinkUrl = action.value;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "SET_HEADER_ARCHIVE_LINK_TEXT":
      newState.category.header.archiveLinkText = action.value;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "SET_HEADER_ARCHIVE_LINK_COLOR":
      newState.category.header.archiveLinkColor = action.bgColor;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "UPDATE_FOOTER_TEXT":
      if (newState.category.footer.content["texts"] === undefined) {
        newState.category.footer.content["texts"] = {};
      }
      if (newState.category.footer.content["texts"][action.field] === undefined) {
        newState.category.footer.content["texts"][action.field] = {
          model: "text"
        };
      }
      newState.category.footer.content["texts"][action.field].text = action.text;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "UPDATE_FOOTER_BG_COLOR":

      if (newState.category.footer.content["backgroundColors"] === undefined) {
        newState.category.footer.content["backgroundColors"] = {};
      }
      if (newState.category.footer.content["backgroundColors"]["backgroundColor-1"] === undefined) {
        newState.category.footer.content["backgroundColors"]["backgroundColor-1"] = {
          model: "backgroundColor"
        };
      }
      newState.category.footer.content["backgroundColors"]["backgroundColor-1"].bgColor = action.bgColor;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "UPDATE_FOOTER_TEXT_COLOR":
      if (newState.category.footer.content["backgroundColors"] === undefined) {
        newState.category.footer.content["backgroundColors"] = {};
      }
      if (newState.category.footer.content["backgroundColors"]["textColor-1"] === undefined) {
        newState.category.footer.content["backgroundColors"]["textColor-1"] = {
          model: "backgroundColor"
        };
      }
      newState.category.footer.content["backgroundColors"]["textColor-1"].bgColor = action.bgColor;
      newState.category.footer.content["backgroundColors"]["textColor-1"].index = action.index;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "ADD_CATEGORY_TEMPLATE":
      newState.category.templates.push(action.templateName);
      newState.category.templates = handleOrderTemplate(
        newState.category.templates
      );
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "ADD_ALL_TEMPLATES":
      newState.category.templates = action.templates;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "EDIT_CATEGORY":
      newState.category = action.category;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "REMOVE_CATEGORY_TEMPLATE":
      newState.category.templates = newState.category.templates.filter(
        (name) => name !== action.templateName
      );
      newState.category.templates = handleOrderTemplate(
        newState.category.templates
      );
      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    default:
      return state;
    // eslint-disable-next-line no-unreachable
  }
};

const newsletterBuilderReducer = (
  state = {
    categoryId: null,
    categoryTitle: "",
    newsletterId: null,
    newsletterTitle: "",
    templates: {},
    header: null,
    footer: null,
    columns: {
      "template-list-column": {
        id: "template-list-column",
        title: "newsletter 1",
        templateIds: [],
      },
      "newsletter-column": {
        id: "newsletter-column",
        title: "newsletter 1",
        templateIds: [],
      },
    },
    columnOrder: ["template-list-column", "newsletter-column"],
    modal: {
      isVisible: false,
      templateId: null,
      fieldType: null,
      fieldId: null,
      frontContent: "empty",
    },
    selectedAttributeId: null,
    selectedTemplateId: null,
    editorMode: { isVisible: true },
    selectedDraftEditor: null,
    modelListColumn: { isVisible: true },
    newsletterColumn: { isVisible: true },
    newsletterMenu: { isVisible: false },
    newsletterSelectedTab: "EditorTab",
    newsletterHtmlCode: "",
    htmlCode: { isVisible: false },
  },
  action
) => {
  const newState = { ...state };
  switch (action.type) {
    case "UPDATE_HTML_CODE_FOR_NEWSLETTER":
      newState.newsletterHtmlCode = action.htmlCode;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "TOGGLE_MODEL_LIST_COLUMN_VISIBILITY":
      newState.modelListColumn.isVisible = !newState.modelListColumn.isVisible;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "TOGGLE_NEWSLETTER_MENU_VISIBILITY":
      newState.newsletterMenu.isVisible = !newState.newsletterMenu.isVisible;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "SHOW_NEWSLETTER_MENU":
      newState.newsletterMenu.isVisible = true;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "HIDE_NEWSLETTER_MENU":
      newState.newsletterMenu.isVisible = false;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "SHOW_NEWSLETTER_HTML_CODE":
      newState.newsletterMenu.isVisible = !newState.newsletterMenu.isVisible;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "SHOW_MOBILE_PREVIEW":
      newState.newsletterMenu.isVisible = !newState.newsletterMenu.isVisible;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "SHOW_DESKTOP_PREVIEW":
      newState.newsletterMenu.isVisible = !newState.newsletterMenu.isVisible;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "SHOW_MODEL_LIST_COLUMN":
      newState.modelListColumn.isVisible = true;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "SHOW_DRAFT_EDITOR":
      newState.selectedDraftEditor = action.textId;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_TEXT_ALIGN":
      newState.templates[action.data.templateId].content.texts[
        action.data.textId
      ].align = action.data.align;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "RESET_NEWLETTER_BUILDER":
      newState.categoryId = null;
      newState.categoryTitle = "";
      newState.newsletterId = null;
      newState.header = null;
      newState.footer = null;
      newState.newsletterTitle = "";
      newState.templates = {};
      newState.columns["template-list-column"].templateIds = [];
      newState.columns["newsletter-column"].templateIds = [];
      newState.newsletterMenu.isVisible = false;
      newState.newsletterSelectedTab = "EditorTab";
      newState.updateHtmlCode = "";
      newState.selectedTemplateId = null;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "UPDATE_NEWSLETTER_TITLE":
      newState.newsletterTitle = action.title;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "CHANGE_NEWSLETTER_TAB_VISIBILITY":
      newState.newsletterSelectedTab = action.tab;
      if (action.tab === "HtmlTab") {
        newState.updateHtmlCode = Date.now();
      }

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "LOAD_TEMPLATES_IN_MODEL_AND_NEWSLETTER_COLUMN":
      const category = action.data.category;
      const newsletterTemplates = action.data.newsletterTemplates;
      newState.newsletterId = action.data.newsletterId;
      newState.newsletterTitle = action.data.newsletterTitle;
      newState.categoryId = category._id;
      newState.categoryTitle = category.title;

      const categoryTemplates = category.templates.reduce(
        (object, template) => {
          object[template] = templates[template];

          return object;
        },
        {}
      );
      newState.templates = { ...categoryTemplates, ...newsletterTemplates };
      newState.header = category.header;
      newState.footer = category.footer;
      newState.columns["template-list-column"].templateIds = category.templates;

      if (newsletterTemplates !== undefined && newsletterTemplates !== null) {
        newState.columns["newsletter-column"].templateIds = Object.keys(
          newsletterTemplates
        );
      }


      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "REORDER_TEMPLATES":
      newState.columns[action.order.columnId].templateIds =
        action.order.templateIds;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "MOVE_TEMPLATE_FROM_CATEGORY_TO_NEWSLETTER":
      newState.templates = { ...action.data.templates };
      newState.columns["template-list-column"].templateIds =
        action.data.modelList;
      newState.columns["newsletter-column"].templateIds =
        action.data.newsLetterList;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "SELECT_TEMPLATE":
      newState.selectedTemplateId = action.templateId;
      newState.selectedDraftEditor = null;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "OPEN_AND_INIT_IMAGE_UPLOADER":
      newState.templates.selectedTemplateId = action.data.templateId;

      newState.modal.isVisible = true;
      newState.modal.templateId = action.data.templateId;
      newState.modal.fieldType = "images";
      newState.modal.fieldId = action.data.imageId;
      newState.modal.frontContent = "image-cropper";

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "OPEN_AND_INIT_CANVAS_IMAGE_TITLE":
      newState.templates.selectedTemplateId = action.data.templateId;

      newState.modal.isVisible = true;
      newState.modal.templateId = action.data.templateId;
      newState.modal.fieldType = "canvas-images";
      newState.modal.fieldId = action.data.imageId;
      newState.modal.frontContent = "canvas-image-title";

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "OPEN_AND_INIT_EVENT_IMAGE":
      newState.templates.selectedTemplateId = action.data.templateId;

      newState.modal.isVisible = true;
      newState.modal.templateId = action.data.templateId;
      newState.modal.fieldType = "event-images";
      newState.modal.fieldId = action.data.imageId;
      newState.modal.frontContent = "event-image-generator";

      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "CLOSE_IMAGE_UPLOADER_MODAL":
      newState.modal.isVisible = false;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "UPDATE_TEMPLATE_IMAGE_AND_REINIT_IMAGE_UPLOADER_MODAL":
      newState.templates[action.data.templateId].content[action.data.fieldType][
        action.data.fieldId
      ].imgSrc = action.data.imgSrc;
      newState.modal.isVisible = false;
      newState.modal.frontContent = "empty";
      newState.modal.templateId = null;
      newState.modal.fieldType = null;
      newState.modal.fieldId = null;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "UPDATE_TEMPLATE_CANVAS_IMAGE_TITLE":
      newState.templates[action.data.templateId].content[action.data.fieldType][
        action.data.fieldId
      ].imgSrc = action.data.imgSrc;
      newState.templates[action.data.templateId].content[action.data.fieldType][
        action.data.fieldId
      ].text = action.data.text;
      newState.templates[action.data.templateId].content[action.data.fieldType][
        action.data.fieldId
      ].imgWidth = action.data.imgWidth;
      newState.templates[action.data.templateId].content[action.data.fieldType][
        action.data.fieldId
      ].color = action.data.color;
      newState.templates[action.data.templateId].content[action.data.fieldType][
        action.data.fieldId
      ].bgColor = action.data.bgColor;

      newState.modal.isVisible = false;
      newState.modal.frontContent = "empty";
      newState.modal.templateId = null;
      newState.modal.fieldType = null;
      newState.modal.fieldId = null;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "UPDATE_TEMPLATE_EVENT_IMAGE":

      const newEventContentObject = {
        ...newState.templates[action.data.templateId].content[action.data.fieldType][
        action.data.fieldId
        ]
      }
      newEventContentObject.imgSrc = action.data.imgSrc;
      newEventContentObject.templateLink = action.data.templateLink;
      newEventContentObject.line1 = action.data.line1;
      newEventContentObject.line2 = action.data.line2;
      newEventContentObject.line3 = action.data.line3;
      newEventContentObject.day = action.data.day;
      newEventContentObject.month = action.data.month;

      newState.templates[action.data.templateId].content[action.data.fieldType][
        action.data.fieldId
      ] = newEventContentObject;
      newState.modal.isVisible = false;
      newState.modal.frontContent = "empty";
      newState.modal.templateId = null;
      newState.modal.fieldType = null;
      newState.modal.fieldId = null;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_TITLE_COLOR":
      newState.templates[action.data.templateId].content.titles[
        action.data.titleId
      ].color = action.data.color;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "ADD_OR_REMOVE_DIVIDER":
      newState.templates[
        action.data.templateId
      ].content.options.divider.isActived = action.data.isActived;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "SWITCH_TAG_LINE":
      newState.templates[
        action.data.templateId
      ].content.options.switchTag.isActived = action.data.isActived;
      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_TEMPLATE_BG_COLOR":
      newState.templates[
        action.data.templateId
      ].content.options.templateBgColor.color = action.data.color;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_TEMPLATE_MARGIN_BOTTOM":
      newState.templates[
        action.data.templateId
      ].content.options.marginBottom.type = action.data.type;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_TEMPLATE_MARGIN_TOP":
      newState.templates[
        action.data.templateId
      ].content.options.marginTop.type = action.data.type;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_TITLE_TEXT":
      newState.templates[action.data.templateId].content.titles[
        action.data.titleId
      ].text = action.data.text;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_TEXT":
      newState.templates[action.data.templateId].content.texts[
        action.data.textId
      ].text = action.data.text;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_BUTTON_BACKGROUND_COLOR":
      newState.templates[action.data.templateId].content.buttons[
        action.data.btnId
      ].bgColor = action.data.bgColor;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_BUTTON_URL":
      newState.templates[action.data.templateId].content.buttons[
        action.data.btnId
      ].link = action.data.link;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_BUTTON_TEXT":
      newState.templates[action.data.templateId].content.buttons[
        action.data.btnId
      ].text = action.data.text;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_BTN_TEXT_COLOR":
      newState.templates[action.data.templateId].content.buttons[
        action.data.btnId
      ].textColor = action.data.textColor;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "CHANGE_BTN_BORDER_COLOR":
      newState.templates[action.data.templateId].content.buttons[
        action.data.btnId
      ].borderColor = action.data.borderColor;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_BUTTON_DISABLED":
      newState.templates[action.data.templateId].content.buttons[
        action.data.btnId
        ].disabled = action.data.disabled;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_IMAGE_DISABLED":
      newState.templates[action.data.templateId].content.images[
        action.data.imageId
        ].disabled = action.data.disabled;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;



    case "CHANGE_IMAGE_LINK":
      newState.templates[action.data.templateId].content.images[
        action.data.imageId
        ].link = action.data.link;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_IMAGE_LINK_URL":
      newState.templates[action.data.templateId].content.images[
        action.data.imageId
        ].url = action.data.url;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_BOX_BACKGROUND_COLOR":
      newState.templates[action.data.templateId].content.backgroundColors[
        action.data.bgId
      ].bgColor = action.data.bgColor;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;
    case "CHANGE_TAG_BACKGROUND_COLOR":
      newState.templates[action.data.templateId].content.tags[
        action.data.tagId
      ].bgColor = action.data.bgColor;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "CHANGE_TAG_TEXT":
      newState.templates[action.data.templateId].content.tags[
        action.data.tagId
      ].text = action.data.text;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "ADD_HIGHLIGHT_ON_FIELD":
      newState.selectedAttributeId = action.AttributeId;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "REMOVE_HIGHLIGHT_ON_FIELD":
      newState.selectedAttributeId = null;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "DELETE_NEWLETTER_ITEM":
      const index = newState.columns["newsletter-column"].templateIds.indexOf(
        action.templateId
      );
      newState.columns["newsletter-column"].templateIds.splice(index, 1);
      if (action.templateId === newState.selectedTemplateId) {
        newState.newsletterMenu.isVisible = false;
        newState.selectedTemplateId = null;
      }
      delete newState.templates[action.templateId];

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    case "MOVE_NEWLETTER_ITEM":
      const moveNumber = action.data.direction === "up" ? -1 : 1;

      const newTdemplateIds = [
        ...newState.columns["newsletter-column"].templateIds,
      ];

      const position = newTdemplateIds.indexOf(action.data.templateId);

      const [removed] = newTdemplateIds.splice(position, 1);

      newTdemplateIds.splice(position + moveNumber, 0, removed);

      newState.columns["newsletter-column"].templateIds = newTdemplateIds;

      return newState;
      // eslint-disable-next-line no-unreachable
      break;

    default:
      return state;
    // eslint-disable-next-line no-unreachable
  }
};

const store = createStore(
  combineReducers({
    user: userReducer,
    admin: adminReducer,
    newsletterBuilder: newsletterBuilderReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

window.store = store;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
