import React from "react";
import Editor from "../../utils/editFunctions";

const Template25 = ({ template, statusMode }) => {
  const editor = new Editor(template, statusMode);

  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        {/*[if mso | IE]>
        <table
          align="center" border="0" cellpadding="0" cellspacing="0" class="content-padding-outlook" style="width:600px;" width="600"
        >
          <tr>
            <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
        <![endif]*/}
        <div
          className="content-padding"
          style={{ margin: "0px auto", maxWidth: "600px" }}
        >
          <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
              background: editor.handleTemplateBgColor(),
              backgroundColor: editor.handleTemplateBgColor(),
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    direction: "ltr",
                    fontSize: "0px",
                    padding: `${editor.handleTopMargin()} 10% ${editor.handleBottomMargin()} 10%`,
                    paddingLeft: "10%",
                    paddingRight: "10%",
                    textAlign: "center",
                  }}
                >
                  {/*[if mso | IE]>
                  <table  border="0" cellpadding="0" cellspacing="0">
                    <tr>
                      <td
                        class="box-shadow-outlook" width="600px"
                      >
                        <table
                          align="center" border="0" cellpadding="0" cellspacing="0" class="box-shadow-outlook" style="width:580px;" width="580"
                        >
                          <tr>
                            <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
                  <![endif]*/}
                  <div
                    className="box-shadow"
                    style={{
                      boxShadow: "7px 7px 5px #00000026",
                      margin: "0px auto",
                      maxWidth: "580px",
                    }}
                  >
                    <table
                      align="center"
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      style={{ width: "100%" }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              direction: "ltr",
                              fontSize: "0px",
                              padding: 0,
                              textAlign: "center",
                            }}
                          >
                            {/*[if mso | IE]>
                            <table border="0" cellpadding="0" cellspacing="0">
                            <tr>
                                <td style="vertical-align:top;width:255.2px;">
                            <![endif]*/}
                            <div
                              className="mj-column-per-44 mj-outlook-group-fix"
                              style={{
                                fontSize: "0px",
                                textAlign: "left",
                                direction: "ltr",
                                display: "inline-block",
                                verticalAlign: "top",
                                width: "100%",
                              }}
                            >
                              <table
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        verticalAlign: "top",
                                        padding: "10px 0px",
                                      }}
                                    >
                                      <table
                                        border={0}
                                        cellPadding={0}
                                        cellSpacing={0}
                                        style={{}}
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style={{
                                                fontSize: "0px",
                                                padding: "0px",
                                                wordBreak: "break-word",
                                              }}
                                            >
                                              <table
                                                cellPadding={0}
                                                cellSpacing={0}
                                                width="100%"
                                                border={0}
                                                style={{
                                                  color: "#000000",
                                                  fontFamily:
                                                    "Ubuntu, Helvetica, Arial, sans-serif",
                                                  fontSize: "13px",
                                                  lineHeight: "22px",
                                                  tableLayout: "auto",
                                                  width: "100%",
                                                  border: "none",
                                                }}
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      width="32%"
                                                      align="center"
                                                    >
                                                      {/* calendrier */}
                                                      <table
                                                        border={0}
                                                        cellPadding={0}
                                                        cellSpacing={0}
                                                        style={{
                                                          borderCollapse:
                                                            "collapse",
                                                          borderSpacing: "0px",
                                                        }}
                                                        width="100%"
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                height: "8px",
                                                              }}
                                                            ></td>
                                                          </tr>

                                                          <tr>
                                                            <td align="center">
                                                              <div
                                                                style={{
                                                                  height: "8px",
                                                                  backgroundColor:
                                                                    "#ED9870",
                                                                  width: "28px",
                                                                  borderRadius:
                                                                    "4px 4px 0px 0px",
                                                                }}
                                                              ></div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td align="center">
                                                              <div
                                                                style={{
                                                                  height: "2px",
                                                                  backgroundColor:
                                                                    "#9C9897",
                                                                  width: "28px",
                                                                }}
                                                              ></div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td align="center">
                                                              <div
                                                                style={{
                                                                  height: "4px",
                                                                  backgroundColor:
                                                                    "#EDEDED",
                                                                  width: "28px",
                                                                }}
                                                              ></div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td align="center">
                                                              <table
                                                                border={0}
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                width="28px"
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  borderSpacing:
                                                                    "0px",
                                                                  width: "28px",
                                                                }}
                                                              >
                                                                <tbody>
                                                                  <tr>
                                                                    <td>
                                                                      <div
                                                                        style={{
                                                                          fontSize:
                                                                            "16px",
                                                                          fontFamily:
                                                                            '"Raleway", "Arial-Black", sans-serif',
                                                                          color:
                                                                            "#000000",
                                                                          lineHeight:
                                                                            "15px",
                                                                          fontWeight:
                                                                            "bold",
                                                                          wordBreak:
                                                                            "break-word",
                                                                          backgroundColor:
                                                                            "#EDEDED",
                                                                          textAlign:
                                                                            "center",
                                                                        }}
                                                                      >
                                                                        {editor.handleTitle(
                                                                          "title-1"
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td>
                                                                      <div
                                                                        style={{
                                                                          height:
                                                                            "6px",
                                                                          backgroundColor:
                                                                            "#EDEDED",
                                                                          width:
                                                                            "28px",
                                                                          borderRadius:
                                                                            "0px 0px 4px 4px",
                                                                        }}
                                                                      ></div>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                height: "4px",
                                                              }}
                                                            ></td>
                                                          </tr>
                                                          <tr>
                                                            <td align="center">
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    "10px",
                                                                  fontFamily:
                                                                    '"Raleway", "Arial-Black", sans-serif',
                                                                  color:
                                                                    "#0C3944",
                                                                  lineHeight:
                                                                    "15px",
                                                                  fontWeight:
                                                                    "bold",
                                                                  wordBreak:
                                                                    "break-word",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {editor.handleTitle(
                                                                  "title-2"
                                                                )}
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                    <td width="2%">
                                                      <div
                                                        style={{
                                                          backgroundColor:
                                                            "#EDEFF3",
                                                          height: "25px",
                                                          width: "3px",
                                                          borderRadius: "4px",
                                                        }}
                                                      />
                                                    </td>
                                                    <td width="6%" />
                                                    <td width="60%">
                                                      <table
                                                        border={0}
                                                        cellPadding={0}
                                                        cellSpacing={0}
                                                        style={{
                                                          borderCollapse:
                                                            "collapse",
                                                          borderSpacing: "0px",
                                                        }}
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                position: `${editor.handleImagePosition()}`,
                                                                width: `${editor.handleImageWidth(
                                                                  "image-1"
                                                                )}px`,
                                                              }}
                                                            >
                                                              <img
                                                                height="auto"
                                                                src={editor.handleImageSrc(
                                                                  "image-1"
                                                                )}
                                                                alt={editor.handleImageAlt(
                                                                  "image-1"
                                                                )}
                                                                style={{
                                                                  border: 0,
                                                                  display:
                                                                    "block",
                                                                  outline:
                                                                    "none",
                                                                  textDecoration:
                                                                    "none",
                                                                  height:
                                                                    "auto",
                                                                  width: "100%",
                                                                  fontSize:
                                                                    "13px",
                                                                  borderRadius:
                                                                    "10px",
                                                                }}
                                                                width={editor.handleImageWidth(
                                                                  "image-1"
                                                                )}
                                                              />
                                                              {editor.handleImageHighlight(
                                                                "image-1"
                                                              )}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {/*[if mso | IE]>
      </td>

      <td
         class="" style="vertical-align:top;width:17.4px;"
      >
    <![endif]*/}
                            <div
                              className="mj-column-per-3 mj-outlook-group-fix"
                              style={{
                                fontSize: "0px",
                                textAlign: "left",
                                direction: "ltr",
                                display: "inline-block",
                                verticalAlign: "top",
                                width: "100%",
                              }}
                            >
                              <table
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        verticalAlign: "top",
                                        padding: "5px",
                                      }}
                                    >
                                      <table
                                        border={0}
                                        cellPadding={0}
                                        cellSpacing={0}
                                        width="100%"
                                      ></table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {/*[if mso | IE]>
                              </td>
                              <td style="vertical-align:top;width:307.4px;">
                            <![endif]*/}
                            <div
                              className="mj-column-per-53 mj-outlook-group-fix"
                              style={{
                                fontSize: "0px",
                                textAlign: "left",
                                direction: "ltr",
                                display: "inline-block",
                                verticalAlign: "top",
                                width: "100%",
                              }}
                            >
                              <table
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        verticalAlign: "top",
                                        padding: "10px 0px",
                                      }}
                                    >
                                      <table
                                        border={0}
                                        cellPadding={0}
                                        cellSpacing={0}
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style={{
                                                fontSize: "0px",
                                                padding: "0px",
                                                wordBreak: "break-word",
                                              }}
                                            >
                                              <table
                                                cellPadding={0}
                                                cellSpacing={0}
                                                width="100%"
                                                border={0}
                                                style={{
                                                  color: "#000000",
                                                  fontFamily:
                                                    "Ubuntu, Helvetica, Arial, sans-serif",
                                                  fontSize: "16px",
                                                  lineHeight: "25px",
                                                  tableLayout: "auto",
                                                  width: "100%",
                                                  border: "none",
                                                }}
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style={{ witdh: "64%" }}
                                                    >
                                                      <table
                                                        width="100%"
                                                        border={0}
                                                        cellSpacing={0}
                                                        cellPadding={0}
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                fontSize: "0px",
                                                                wordBreak:
                                                                  "break-word",
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    "10px",
                                                                  fontFamily:
                                                                    '"Raleway", "Arial-Black", sans-serif',
                                                                  color:
                                                                    "#0C3944",
                                                                  lineHeight:
                                                                    "15px",
                                                                  fontWeight:
                                                                    "bold",
                                                                  wordBreak:
                                                                    "break-word",
                                                                  width:
                                                                    "158px",
                                                                }}
                                                              >
                                                                {editor.handleTitle(
                                                                  "title-3"
                                                                )}
                                                              </div>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td
                                                              style={{
                                                                fontSize: "0px",
                                                                wordBreak:
                                                                  "break-word",
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  fontSize:
                                                                    "10px",
                                                                  fontFamily:
                                                                    '"Raleway", "Arial-Black", sans-serif',
                                                                  color:
                                                                    "#0C3944",
                                                                  lineHeight:
                                                                    "15px",
                                                                  fontWeight:
                                                                    "normal",
                                                                  wordBreak:
                                                                    "break-word",
                                                                  width:
                                                                    "158px",
                                                                }}
                                                              >
                                                                {editor.handleTitle(
                                                                  "title-4"
                                                                )}
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                    <td
                                                      style={{
                                                        witdh: "4%",
                                                        height: "62px",
                                                      }}
                                                    ></td>
                                                    <td
                                                      style={{ witdh: "32%" }}
                                                      align="left"
                                                      vertical-align="middle"
                                                    >
                                                      <table
                                                        border={0}
                                                        cellPadding={0}
                                                        cellSpacing={0}
                                                        style={{
                                                          borderCollapse:
                                                            "separate",
                                                          lineHeight: "100%",
                                                        }}
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              align="center"
                                                              bgcolor={editor.handleBgColor(
                                                                "buttons",
                                                                "button-1"
                                                              )}
                                                              style={{
                                                                border: "none",
                                                                borderRadius:
                                                                  "100px",
                                                                cursor: "auto",
                                                                msoPaddingAlt:
                                                                  "4px 10px",
                                                                background: editor.handleBgColor(
                                                                  "buttons",
                                                                  "button-1"
                                                                ),
                                                              }}
                                                              valign="middle"
                                                            >
                                                              <a
                                                                href={editor.handleUrl(
                                                                  "buttons",
                                                                  "button-1"
                                                                )}
                                                                style={{
                                                                  display:
                                                                    "inline-block",
                                                                  background: editor.handleBgColor(
                                                                    "buttons",
                                                                    "button-1"
                                                                  ),
                                                                  color: editor.handleTextColor(
                                                                    "buttons",
                                                                    "button-1"
                                                                  ),
                                                                  fontFamily:
                                                                    '"Raleway", "Arial-Black", sans-serif',
                                                                  fontSize:
                                                                    "10px",
                                                                  fontWeight:
                                                                    "bold",
                                                                  lineHeight:
                                                                    "14px",
                                                                  margin: 0,
                                                                  textDecoration:
                                                                    "none",
                                                                  textTransform:
                                                                    "none",
                                                                  padding:
                                                                    "4px 10px",
                                                                  msoPaddingAlt:
                                                                    "0px",
                                                                  borderRadius:
                                                                    "100px",
                                                                }}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                              >
                                                                {editor.handleBtnText(
                                                                  "button-1"
                                                                )}
                                                              </a>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {/*[if mso | IE]>
                                </td>
                              </tr>
                            </table>
                          <![endif]*/}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*[if mso | IE]>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                <![endif]*/}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*[if mso | IE]>
            </td>
          </tr>
        </table>
        <![endif]*/}
      </div>
      {editor.handleDivider()}
    </>
  );
};

export default Template25;
