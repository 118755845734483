import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const ModalBtn = styled.button`
  margin-left: 10px;
  background-color: #075163;
  border: none;
  border-radius: 1000px;
  padding: 10px 20px;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  transition: background-color 0.2s, border-color 0.2s;
  outline: none;
  &:hover {
    background-color: #0d2a35;
  }
`;

const CanvasImageEditor = ({ templateId, imageId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <ModalBtn
      onClick={(event) => {
        event.preventDefault();

        dispatch({
          type: "OPEN_AND_INIT_CANVAS_IMAGE_TITLE",
          data: {
            templateId: templateId,
            imageId: imageId,
          },
        });
      }}
    >
      {t("canvasImageEditor.modalBtn")}
    </ModalBtn>
  );
};

export default CanvasImageEditor;
