import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import PickBgColor from "./PickBgColor";

const ContainerWrapper = styled.div``;

const TemplateBgColorEditor = ({ templateId, bgColor }) => {
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();

  const editionMode =
    newsletterBuilder.selectedTemplateId === templateId ? true : false;

  const changeBgTempateColor = (event) => {
    const bgColor = event.target.value;
    dispatch({
      type: "CHANGE_TEMPLATE_BG_COLOR",
      data: {
        templateId: templateId,
        color: bgColor,
      },
    });
  };

  const renderDividerField = () => {
    if (editionMode === true) {
      return (
        <ContainerWrapper>
          <PickBgColor
            labelName="background"
            action={changeBgTempateColor}
            newColors={["#FFFFFF", "#F7F7F7"]}
          ></PickBgColor>
        </ContainerWrapper>
      );
    } else {
      return null;
    }
  };

  return <>{renderDividerField()}</>;
};

export default TemplateBgColorEditor;
