import React from "react";
import Editor from "../../utils/editFunctions";

const Template9 = ({ template, data, setData }) => {
  const editor = new Editor(template);
  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        {/* template 9 */}
        {/*[if mso | IE]>
      <table
         align="center" border="0" cellpadding="0" cellspacing="0" class="content-padding" style="width:600px;" width="600"
      >
        <tr>
          <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
      <![endif]*/}
        <div
          className="content-padding"
          style={{
            background: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            margin: "0px auto",
            maxWidth: "600px",
          }}
        >
          <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
              background: editor.handleTemplateBgColor(),
              backgroundColor: editor.handleTemplateBgColor(),
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    direction: "ltr",
                    fontSize: "0px",
                    padding: `${editor.handleTopMargin()} 10% ${editor.handleBottomMargin()} 10%`,
                    textAlign: "center",
                  }}
                >
                  {/*[if mso | IE]>
                  <table border="0" cellpadding="0" cellspacing="0">

        <tr>

            <td
               class="" style="vertical-align:top;width:580px;"
            >
          <![endif]*/}
                  <div
                    className="mj-column-per-100 mj-outlook-group-fix"
                    style={{
                      fontSize: "0px",
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%",
                    }}
                  >
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td style={{ verticalAlign: "top", padding: 0 }}>
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              style={{}}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="center"
                                    style={{
                                      fontSize: "0px",
                                      padding: "10px 25px",
                                      paddingRight: 0,
                                      paddingLeft: 0,
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontFamily:
                                          '"Raleway", "Arial-Black",  sans-serif',
                                        fontSize: "32px",
                                        fontWeight: 800,
                                        lineHeight: "32px",
                                        textAlign: "center",
                                        color: editor.handleTitleColor(
                                          "title-1",
                                          "#E36164"
                                        ),
                                      }}
                                    >
                                      {editor.handleTitle("title-1")}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*[if mso | IE]>
            </td>

        </tr>

                  </table>
                <![endif]*/}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*[if mso | IE]>
          </td>
        </tr>
      </table>
      <![endif]*/}
        {/* template 9 */}
      </div>
      {editor.handleDivider()}
    </>
  );
};

export default Template9;
