import React from "react";
import Editor from "../../utils/editFunctions";

const Template27 = ({ template, statusMode }) => {
    const editor = new Editor(template, statusMode);
    return (
        <>
            <div style={{ backgroundColor: "#FFFFFF" }}>
                {/*[if mso | IE]>
                <table
                align="center" border="0" cellpadding="0" cellspacing="0" class="content-padding-outlook" style="width:600px;" width="600"
                >
                    <tr>
                        <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
                <![endif]*/}
                <div
                    className="content-padding"
                    style={{ margin: "0px auto", maxWidth: "600px" }}
                >
                    <table
                        align="center"
                        border={0}
                        cellPadding={0}
                        cellSpacing={0}
                        style={{
                            background: editor.handleTemplateBgColor(),
                            backgroundColor: editor.handleTemplateBgColor(),
                            width: "100%",
                        }}
                    >
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        direction: "ltr",
                                        fontSize: "0px",
                                        padding: `${editor.handleTopMargin()} 10% ${editor.handleBottomMargin()} 10%`,
                                        textAlign: "center",
                                    }}
                                >
                                    {/*[if mso | IE]>
                                        <table  border="0" cellpadding="0" cellspacing="0">

                                        <tr>

                                            <td
                                                class="small-box-shadow-outlook" style="vertical-align:top;width:278.4px;"
                                            >
                                    <![endif]*/}
                                    <div
                                        className="mj-column-per-48 mj-outlook-group-fix small-box-shadow"
                                        style={{
                                            boxShadow: "7px 7px 5px #0000000D",
                                            borderRadius: "4px",

                                            fontSize: "0px",
                                            textAlign: "left",
                                            direction: "ltr",
                                            display: "inline-block",
                                            verticalAlign: "top",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                border: "1px solid #EAEAEA",
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <table
                                                border={0}
                                                cellPadding={0}
                                                cellSpacing={0}
                                                width="100%"
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                verticalAlign: "top",
                                                                padding: 0,
                                                                paddingTop: "0px",
                                                                paddingBottom: "0px",
                                                            }}
                                                        >
                                                            <table
                                                                border={0}
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                width="100%"
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="left"
                                                                            style={{
                                                                                fontSize: "0px",
                                                                                padding: "0px",
                                                                                wordBreak: "break-word",
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={0}
                                                                                cellSpacing={0}
                                                                                width="100%"
                                                                                border={0}
                                                                                style={{
                                                                                    color: "#000000",
                                                                                    fontFamily:
                                                                                        "Ubuntu, Helvetica, Arial, sans-serif",
                                                                                    fontSize: "0px",
                                                                                    lineHeight: "0px",
                                                                                    tableLayout: "auto",
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>

                                                                                        <td
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                width: "25px",
                                                                                            }}

                                                                                        >
                                                                                        </td>
                                                                                        <td>
                                                                                            <table
                                                                                                align="left"
                                                                                                border={0}
                                                                                                cellSpacing={0}
                                                                                                cellPadding={0}
                                                                                            >
                                                                                                <tbody>
                                                                                                    <tr>


                                                                                                        <td
                                                                                                            align="left"
                                                                                                            style={{
                                                                                                                padding: "10px 0 0 0",
                                                                                                                wordBreak: "break-word",
                                                                                                            }}
                                                                                                        >

                                                                                                            <table
                                                                                                                align="left"
                                                                                                                border={0}
                                                                                                                cellSpacing={0}
                                                                                                                cellPadding={0}
                                                                                                            >
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <td valign="middle" style={{ verticalAlign: "middle", height: "25px" }}>
                                                                                                                            <a
                                                                                                                                href={editor.handleUrl(
                                                                                                                                    "buttons",
                                                                                                                                    "button-1"
                                                                                                                                )}
                                                                                                                                style={editor.handleLinkSelected(
                                                                                                                                    "button-1"
                                                                                                                                )}
                                                                                                                                target="_blank"
                                                                                                                                rel="noopener noreferrer"
                                                                                                                            >
                                                                                                                                <img width="12" height="6" src="https://makesense-outil-newsletter.s3-eu-west-1.amazonaws.com/ressources-link.png" alt="lien ressource"></img>
                                                                                                                            </a>
                                                                                                                        </td>
                                                                                                                        <td style={{ width: "10px" }}></td>
                                                                                                                        <td valign="middle" style={{ verticalAlign: "middle" }}>
                                                                                                                            <a
                                                                                                                                href={editor.handleUrl(
                                                                                                                                    "buttons",
                                                                                                                                    "button-1"
                                                                                                                                )}
                                                                                                                                style={{ textDecoration: "none" }}

                                                                                                                                target="_blank"
                                                                                                                                rel="noopener noreferrer"
                                                                                                                            >
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        display:
                                                                                                                                            "inline-block",
                                                                                                                                        background: "#ffffff",
                                                                                                                                        color: "#000",
                                                                                                                                        fontFamily:
                                                                                                                                            '"Raleway", "Arial-Black", sans-serif',
                                                                                                                                        fontSize: "16px",
                                                                                                                                        lineHeight: "25px",
                                                                                                                                        fontWeight: 900,
                                                                                                                                        margin: 0,
                                                                                                                                        textDecoration:
                                                                                                                                            "none",
                                                                                                                                        textTransform: "none",

                                                                                                                                        verticalAlign: "middle"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {editor.handleTitle(
                                                                                                                                        "title-1"
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                            </a>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </tbody>
                                                                                                            </table>





                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            align="left"
                                                                                                            style={{
                                                                                                                padding: "0 0 10px 0",
                                                                                                                wordBreak: "break-word",
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    display:
                                                                                                                        "inline-block",
                                                                                                                    background: "#ffffff",
                                                                                                                    color: "#000",
                                                                                                                    fontFamily:
                                                                                                                        '"Lato", Arial, sans-serif',
                                                                                                                    fontSize: "16px",
                                                                                                                    lineHeight: "25px",
                                                                                                                    fontWeight: "normal",
                                                                                                                    margin: 0,
                                                                                                                    textAlign: editor.handleTextAlign("text-1"),
                                                                                                                    textDecoration:
                                                                                                                        "none",
                                                                                                                    textTransform: "none",
                                                                                                                    paddingBottom: "5px",
                                                                                                                }}
                                                                                                            >
                                                                                                                {editor.handleText("text-1")}
                                                                                                            </div>

                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                width: "25px",
                                                                                            }}

                                                                                        >
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/*[if mso | IE]>
      </td>

      <td
         class="" style="vertical-align:top;width:15px;"
      >
    <![endif]*/}
                                    <div
                                        className="mj-column-per-4 mj-outlook-group-fix"
                                        style={{
                                            fontSize: "0px",
                                            textAlign: "left",
                                            direction: "ltr",
                                            display: "inline-block",
                                            verticalAlign: "top",
                                            width: "100%",
                                        }}
                                    >
                                        <table
                                            border={0}
                                            cellPadding={0}
                                            cellSpacing={0}
                                            width="100%"
                                        >
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={{
                                                            verticalAlign: "top",
                                                            paddingTop: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    >
                                                        <table
                                                            border={0}
                                                            cellPadding={0}
                                                            cellSpacing={0}
                                                            width="100%"
                                                        ></table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/*[if mso | IE]>
                                        </td>
                                        <td
                                            class="small-box-shadow-outlook" style="vertical-align:top;width:278.4px;"
                                        >
                                    <![endif]*/}
                                    <div
                                        className="mj-column-per-48 mj-outlook-group-fix small-box-shadow"
                                        style={{
                                            boxShadow: "7px 7px 5px #0000000D",
                                            borderRadius: "4px",

                                            fontSize: "0px",
                                            textAlign: "left",
                                            direction: "ltr",
                                            display: "inline-block",
                                            verticalAlign: "top",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                border: "1px solid #EAEAEA",
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <table
                                                border={0}
                                                cellPadding={0}
                                                cellSpacing={0}
                                                width="100%"
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                verticalAlign: "top",
                                                                padding: 0,
                                                                paddingTop: "0px",
                                                                paddingBottom: "0px",
                                                            }}
                                                        >
                                                            <table
                                                                border={0}
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                width="100%"
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            align="left"
                                                                            style={{
                                                                                fontSize: "0px",
                                                                                padding: "0px",
                                                                                wordBreak: "break-word",
                                                                            }}
                                                                        >
                                                                            <table
                                                                                cellPadding={0}
                                                                                cellSpacing={0}
                                                                                width="100%"
                                                                                border={0}
                                                                                style={{
                                                                                    color: "#000000",
                                                                                    fontFamily:
                                                                                        "Ubuntu, Helvetica, Arial, sans-serif",
                                                                                    fontSize: "0px",
                                                                                    lineHeight: "0px",
                                                                                    tableLayout: "auto",
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>

                                                                                        <td
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                width: "25px",
                                                                                            }}

                                                                                        >
                                                                                        </td>
                                                                                        <td>
                                                                                            <table
                                                                                                align="left"
                                                                                                border={0}
                                                                                                cellSpacing={0}
                                                                                                cellPadding={0}
                                                                                            >
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            align="left"
                                                                                                            style={{
                                                                                                                padding: "10px 0 0 0",
                                                                                                                wordBreak: "break-word",
                                                                                                            }}
                                                                                                        >
                                                                                                            <table
                                                                                                                align="left"
                                                                                                                border={0}
                                                                                                                cellSpacing={0}
                                                                                                                cellPadding={0}
                                                                                                            >
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <td valign="middle" style={{ verticalAlign: "middle", height: "25px" }}>
                                                                                                                            <a
                                                                                                                                href={editor.handleUrl(
                                                                                                                                    "buttons",
                                                                                                                                    "button-2"
                                                                                                                                )}
                                                                                                                                style={editor.handleLinkSelected(
                                                                                                                                    "button-2"
                                                                                                                                )}
                                                                                                                                target="_blank"
                                                                                                                                rel="noopener noreferrer"
                                                                                                                            >
                                                                                                                                <img width="12" height="6" src="https://makesense-outil-newsletter.s3-eu-west-1.amazonaws.com/ressources-link.png" alt="lien ressource"></img>
                                                                                                                            </a>
                                                                                                                        </td>
                                                                                                                        <td style={{ width: "10px" }}></td>
                                                                                                                        <td valign="middle" style={{ verticalAlign: "middle" }}>

                                                                                                                            <a href={editor.handleUrl(
                                                                                                                                "buttons",
                                                                                                                                "button-2"
                                                                                                                            )}
                                                                                                                                style={{ textDecoration: "none" }}

                                                                                                                                target="_blank"
                                                                                                                                rel="noopener noreferrer"
                                                                                                                            >
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        display:
                                                                                                                                            "inline-block",
                                                                                                                                        background: "#ffffff",
                                                                                                                                        color: "#000",
                                                                                                                                        fontFamily:
                                                                                                                                            '"Raleway", "Arial-Black", sans-serif',
                                                                                                                                        fontSize: "14px",
                                                                                                                                        lineHeight: "14px",
                                                                                                                                        fontWeight: 900,
                                                                                                                                        margin: 0,
                                                                                                                                        textDecoration:
                                                                                                                                            "none",
                                                                                                                                        textTransform: "none",

                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {editor.handleTitle(
                                                                                                                                        "title-2"
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                            </a>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </tbody>
                                                                                                            </table>


                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            align="left"
                                                                                                            style={{
                                                                                                                padding: "0 0 10px 0",
                                                                                                                wordBreak: "break-word",
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    display:
                                                                                                                        "inline-block",
                                                                                                                    background: "#ffffff",
                                                                                                                    color: "#000",
                                                                                                                    fontFamily:
                                                                                                                        '"Lato", Arial, sans-serif',
                                                                                                                    fontSize: "16px",
                                                                                                                    lineHeight: "25px",
                                                                                                                    fontWeight: "normal",
                                                                                                                    textAlign: editor.handleTextAlign("text-2"),
                                                                                                                    margin: 0,
                                                                                                                    textDecoration:
                                                                                                                        "none",
                                                                                                                    textTransform: "none",
                                                                                                                    paddingBottom: "5px",
                                                                                                                }}
                                                                                                            >
                                                                                                                {editor.handleText("text-2")}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                width: "25px",
                                                                                            }}

                                                                                        >
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/*[if mso | IE]>
                                                </td>
                                            </tr>
                                        </table>
                                    <![endif]*/}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/*[if mso | IE]>
                            </td>
                        </tr>
                    </table>
                <![endif]*/}

            </div>
            {editor.handleDivider()}
        </>
    );
};

export default Template27;
