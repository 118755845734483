import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import SuperAdminNav from "./SuperAdminNav";
import { useHistory } from "react-router-dom";
import LanguageSelector from "./header/LanguageSelector";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Container = styled.header`
  height: 120px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #eee;
`;
const HeaderContainer = styled.header`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;

  box-sizing: border-box;
  background-color: #eee;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const SignInBtn = styled(Link)`
  font-size: 12px;
  border: none;
  text-decoration: underline;
  outline: none;
  background: none;
  display: inline-block;
  margin-right: 10px;
  color: #000000;
`;

const SignOutBtn = styled.button`
  font-size: 12px;
  border: none;
  text-decoration: underline;
  outline: none;
  background: none;
  display: inline-block;
  margin-right: 10px;
  color: #000000;
`;

const Header = (props) => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const displayMenu = () => {
    if (
      (user.token !== null && user.role === "super-admin") ||
      user.role === "editor"
    ) {
      return <SuperAdminNav></SuperAdminNav>;
    } else {
      return <div></div>;
    }
  };

  return (
    <Container>
      <HeaderContainer>
        {displayMenu()}
        <Link to="/">
          <img width="300px" alt="logo" src="/logo-newsletter.svg"></img>
        </Link>
        {/* {user.token !== null ? <SuperAdminNav></SuperAdminNav> : <div></div>} */}
        <HeaderRight>
          {user.token !== null ? (
            <SignOutBtn
              onClick={() => {
                dispatch({ type: "LOG_OUT" });
                history.push("/");
              }}
            >
              {t("signOut")}
            </SignOutBtn>
          ) : (
            <SignInBtn to="/login">{t("signIn")}</SignInBtn>
          )}

          {/* <nav>
        <ul>
          <li>
            <button onClick={showEditor}>Mode édition</button>
          </li>

          <li>
            <button onClick={showHtmlCode}>Code newsletter</button>
          </li>

          <li>
            <button onClick={toggleModels}>display hide models</button>
          </li>
        </ul>
      </nav> */}
          <LanguageSelector></LanguageSelector>
        </HeaderRight>
      </HeaderContainer>
    </Container>
  );
};

export default Header;
