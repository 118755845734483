import React from "react";

import Header2 from "../components/templates/Header2";

const headerDisplayer = (template, data, setData) => {
  let headerToDisplay;

  switch (template.model) {

    case "model-header-2":
      headerToDisplay = (
        <Header2
          key={`template-${template.id}`}
          template={template}
          statusMode="NEWSLETTER-FORMAT"
        ></Header2>
      );
      break;

    default:
      headerToDisplay = null;
  }

  return headerToDisplay;
};

export default headerDisplayer;
