import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./App.css";
import "./css/event.css";
import "./css/prism-theme.css";
import "./css/reset.css";
import "./css/loader.css";
import "./css/animatedIcons.css";
import "./css/dragula.css";
import "./css/react-easy-crop.css";

import Header from "./components/Header";
import Footer from "./components/Footer";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  faPencilAlt,
  faFileImage,
  faCaretUp,
  faCaretRight,
  faCaretDown,
  faTimes,
  faCamera,
  faLink,
  faTrash,
  faAngleUp,
  faAngleDown,
  faEye,
  faEyeSlash,
  faCheck,
  faUser,
  faUserPlus,
  faChevronRight,
  faDesktop,
  faMobileAlt,
  faFileCode,
  faPlus,
  faHome,
  faCogs,
  faArrowLeft,
  faSquare,
  faAlignRight,
  faAlignCenter,
  faAlignLeft,
  faUnlock,
  faLock

} from "@fortawesome/free-solid-svg-icons";
import Modal from "./components/Modal";

import AdminModal from "./components/adminCategory/AdminModal";
import UserAdmin from "./containers/Admin/UserAdmin";
import CreateUser from "./containers/Admin/CreateUser";
import EditUser from "./containers/Admin/EditUser";
import Home from "./containers/Home";
import { useSelector } from "react-redux";
import NewsletterAdd from "./containers/NewsletterAdd";
import NewsletterEdit from "./containers/NewsletterEdit";
import CategoryList from "./containers/Admin/CategoryList";
import CategoryCreate from "./containers/Admin/CategoryCreate";
import CategoryEdit from "./containers/Admin/CategoryEdit";
import ResetPassword from "./containers/ResetPassword";
import ResetPasswordSended from "./containers/ResetPasswordSended";
import ChangePassword from "./containers/ChangePassword";
import LoginPage from "./containers/LoginPage";


library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faPencilAlt,
  faFileImage,
  faCaretUp,
  faCaretRight,
  faCaretDown,
  faTimes,
  faCamera,
  faLink,
  faTrash,
  faAngleUp,
  faAngleDown,
  faEye,
  faEyeSlash,
  faCheck,
  faUser,
  faUserPlus,
  faChevronRight,
  faDesktop,
  faMobileAlt,
  faFileCode,
  faPlus,
  faHome,
  faCogs,
  faArrowLeft,
  faSquare,
  faAlignRight,
  faAlignCenter,
  faAlignLeft,
  faUnlock,
  faLock
);

// import TryMjml from "./containers/TryMjml";

function App() {
  const user = useSelector((state) => state.user);
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const admin = useSelector((state) => state.admin);
  const isAdmin =
    user.token !== null && user.role === "super-admin" ? true : false;

  const isConnected =
    (user.token !== null && user.role === "super-admin") ||
      user.role === "editor"
      ? true
      : false;

  return (
    <Suspense fallback="loading">
      <Router>
        <Header></Header>

        <main className="container">
          <Switch>
            <Route path="/login">
              <LoginPage></LoginPage>
            </Route>

            <Route path="/category/add">
              {isAdmin ? (
                <CategoryCreate></CategoryCreate>
              ) : (
                  <Redirect to="/login"></Redirect>
                )}
            </Route>
            <Route path="/category/edit/:categoryId">
              {isAdmin ? (
                <CategoryEdit></CategoryEdit>
              ) : (
                  <Redirect to="/login"></Redirect>
                )}
            </Route>
            <Route path="/category/list">
              <CategoryList></CategoryList>
            </Route>
            <Route path="/user/list">
              {isAdmin ? (
                <UserAdmin></UserAdmin>
              ) : (
                  <Redirect to="/login"></Redirect>
                )}
            </Route>
            <Route path="/user/add">
              {isAdmin ? (
                <CreateUser></CreateUser>
              ) : (
                  <Redirect to="/login"></Redirect>
                )}
            </Route>
            <Route path="/user/edit/:userId">
              {isAdmin ? (
                <EditUser></EditUser>
              ) : (
                  <Redirect to="/login"></Redirect>
                )}
            </Route>

            <Route path="/newsletter/create/:categoryId">
              {isConnected ? (
                <NewsletterAdd></NewsletterAdd>
              ) : (
                  <Redirect to="/login"></Redirect>
                )}
            </Route>
            <Route path="/newsletter/edit/:newsletterId">
              {isConnected ? (
                <NewsletterEdit></NewsletterEdit>
              ) : (
                  <Redirect to="/login"></Redirect>
                )}
            </Route>
            <Route path="/resetPassword">
              <ResetPassword></ResetPassword>
            </Route>
            <Route path="/successResetPassword">
              <ResetPasswordSended></ResetPasswordSended>
            </Route>
            <Route path="/changePassword/:resetToken">
              <ChangePassword></ChangePassword>
            </Route>

            <Route path="/">
              {isConnected ? <Home></Home> : <Redirect to="/login"></Redirect>}
            </Route>
          </Switch>
        </main>
        <Footer></Footer>
      </Router>

      {newsletterBuilder.modal.isVisible === true ? <Modal></Modal> : null}

      {admin.modal.isOpen === true ? <AdminModal></AdminModal> : null}
    </Suspense>
  );
}

export default App;
