import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Checkbox from "./Checkbox";

const ContainerWrapper = styled.div``;

const Container = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

const LAbelContainer = styled.div`
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  width: 100px;
  margin-right: 10px;
  font-weight: bold;
`;

const DividerLabel = styled.label`
  font-family: "Raleway", sans-serif;
  font-size: 16px;
`;

const FieldContainer = styled.div`
  flex: 1;
`;

const SwitchTagEditor = ({ templateId, switchTag }) => {
    const { t } = useTranslation();
    const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
    const dispatch = useDispatch();

    const { content } = { ...newsletterBuilder.templates[templateId] };
    const editionMode = newsletterBuilder.selectedTemplateId === templateId ? true : false;
    const { isActived } = { ...content.options[switchTag] };

    const switchTagLine = () => {
        dispatch({
            type: "SWITCH_TAG_LINE",
            data: {
                templateId: templateId,
                isActived: !isActived,
            },
        });
    };

    const renderSwitchField = () => {
        if (editionMode === true) {
            return (
                <ContainerWrapper>
                    <Container>
                        <LAbelContainer>{t("switchTagEditor.tagLineLabel")}</LAbelContainer>
                        <FieldContainer>
                            <DividerLabel htmlFor="tagLine">
                                <Checkbox
                                    id="tagLine"
                                    onChange={switchTagLine}
                                    checked={isActived}
                                ></Checkbox>
                                {isActived === true
                                    ? t("switchTagEditor.removeTagLine")
                                    : t("switchTagEditor.addTagLine")}
                            </DividerLabel>
                        </FieldContainer>
                    </Container>
                </ContainerWrapper>
            );
        } else {
            return null;
        }
    };

    return <>{renderSwitchField()}</>;
};

export default SwitchTagEditor;
