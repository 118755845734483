import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: 60px;
  display: flex;
  font-family: Raleway, sans-serif;
  font-size: 26px;

  font-weight: 800;
  align-items: center;
`;

export const ArrowIcon = styled.div`
  color: #0c3944;
  padding-right: 20px;

  position: relative;

  &::after {
    border-right: 1px solid #e0e6ed;
    border-color: #075163;
    border-width: 2px;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    border-color: #e0e6ed;
  }
`;

export const Title = styled.div`
  color: #0c3944;
  font-family: Raleway, sans-serif;
  font-size: 26px;

  font-weight: 800;
`;

export const BackBtn = styled(Link)`
  display: flex;
  position: relative;
  align-items: center;
  margin-right: 20px;
  height: 40px;
  width: 45px;
  box-sizing: border-box;
`;
