import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FormLabel,
  BtnWrapper,
  FormContainer,
  TextInput,
  SelectInput,
  SubmitBtn,
  FieldError,
  InputContainer,
  StyledToastContainer,
} from "./styles";
import { useHistory } from "react-router-dom";
import LoaderAnimation from "../../../components/LoaderAnimation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdminLayout from "../../../components/AdminLayout";
import Breadcrumb from "../../../components/Breadcrumb";
import CategorySelect from "../../../components/CategorySelect";
import { toast } from "react-toastify";

const EditUser = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState(true);
  const user = useSelector((state) => state.user);
  const { userId } = useParams();
  const [userForm, setUserForm] = useState({
    id: null,
    email: "",
    userName: "",
    role: "editor",
    categories: []
  });

  const [emailNotif, setEmailNotif] = useState({
    isValid: true,
    message: t("editUser.emailRequiredMessage"),
  });

  const notifyEmailError = () => toast(t("editUser.emailErrorNotif"));

  const [userData, setUserData] = useState({});

  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.getAttribute("name");
    const newUserForm = { ...userForm };

    newUserForm[fieldName] = fieldValue;

    setUserForm(newUserForm);
    if (fieldName === "email") {
      const newEmailNotif = { ...emailNotif };
      if (typeof fieldValue !== "undefined" && fieldValue !== "") {
        let lastAtPos = fieldValue.lastIndexOf("@");
        let lastDotPos = fieldValue.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fieldValue.indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            fieldValue.length - lastDotPos > 2
          )
        ) {
          newEmailNotif.message = t("editUser.emailValididityMessage");
          setEmailNotif(newEmailNotif);
          newEmailNotif.isValid = false;
        } else {
          newEmailNotif.isValid = true;
          setEmailNotif(newEmailNotif);
        }
      } else {
        newEmailNotif.message = t("editUser.emailRequiredMessage");
        newEmailNotif.isValid = false;
        setEmailNotif(newEmailNotif);
      }
    }
  };

  const senduserForm = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_DOMAIN}/user/sign_up/${userForm.id}`,
        {
          id: userForm.id,
          userName: userForm.userName,
          email: userForm.email,
          role: userForm.role,
          categories: userForm.categories
        }
      );

      if (response.status === 200) {
        await history.push("/user/list");
      }
    } catch (error) {
      notifyEmailError();
    }
  };

  const addUserCategory = (category) => {
    if (userForm.categories.indexOf(category) === -1) {
      const newUserForm = {...userForm};

      newUserForm.categories = [...userForm.categories, category];
      setUserForm(newUserForm);
    }
  }
  const removeUserCategory = (category) => {
    const index = userForm.categories.indexOf(category);
    if (index >= 0) {
      const newUserForm = {...userForm};
      newUserForm.categories.splice(index, 1);
      setUserForm(newUserForm);
    }
  }

  const formValid = () => {
    if (emailNotif.isValid === true && userForm.userName.length > 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/user/${userId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await setUserForm({
        id: response.data._id,
        email: response.data.email,
        userName: response.data.account.userName,
        role: response.data.role,
        categories: response.data.categories || []
      });
      await setUserData(response.data);
      await setIsloading(false);
    };

    //

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminLayout>
      {isLoading === true ? (
        <LoaderAnimation></LoaderAnimation>
      ) : (
        <>
          <Breadcrumb
            title={t("editUser.title")}
            link="/user/list"
          ></Breadcrumb>
          <FormContainer className="login-form">
            <FormLabel>
              <FontAwesomeIcon icon="user" /> {userData.account.userName}
            </FormLabel>
            <InputContainer>
              <TextInput
                onChange={handleChange}
                type="text"
                id="userName"
                placeholder={t("editUser.userNamePlaceHolder")}
                name="userName"
                value={userForm.userName}
              ></TextInput>
            </InputContainer>
            <FormLabel>{t("editUser.emailLabel")}</FormLabel>
            <InputContainer>
              <TextInput
                onChange={handleChange}
                type="email"
                id="email"
                placeholder={t("editUser.emailLabel")}
                name="email"
                value={userForm.email}
              ></TextInput>
              <FieldError isValid={emailNotif.isValid}>
                {emailNotif.message}
              </FieldError>
            </InputContainer>

            <FormLabel>{t("editUser.roleLabel")}</FormLabel>
            <InputContainer>
              <SelectInput
                value={userForm.role}
                name="role"
                onChange={handleChange}
              >
                <option value="editor">Editor</option>
                <option value="super-admin">Super Admin</option>
              </SelectInput>
            </InputContainer>

            { userForm.role === 'editor' ? (
              <>
                <FormLabel>{t("editUser.categoriesLabel")}</FormLabel>
                <CategorySelect categories={userForm.categories} onAdd={addUserCategory} onRemove={removeUserCategory}></CategorySelect>
              </>
            ) : null }

            <BtnWrapper>
              <SubmitBtn
                isVisible={formValid()}
                onClick={senduserForm}
                className="submit-button"
                type="submit"
              >
                {t("editUser.editBtn")}
              </SubmitBtn>
            </BtnWrapper>
          </FormContainer>
          <StyledToastContainer
            position="bottom-center"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
          />
        </>
      )}
    </AdminLayout>
  );
};

export default EditUser;
