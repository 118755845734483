import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Container,
  FormContainer,
  FormInput,
  FormLabel,
  InputContainer,
  SubmitBtn,
  FieldError,
  Title,
  StyledToastContainer,
  InfoMessage,
  WarningTitle,
} from "./styles.js";
import axios from "axios";
import LoaderAnimation from "../../components/LoaderAnimation.js";
import checkPassword from "../../helpers/checkPassword";

const ChangePassword = (props) => {
  const { t } = useTranslation();
  const [passwordState, setPasswordState] = useState({ password: "" });
  const [passwordNotif, setPasswordNotif] = useState(checkPassword("", t));
  const [passwordResetValidity, setPasswordResetValidity] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const { resetToken } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsloading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/resetPassword/check/${resetToken}`
        );
        console.log(response);
        if (response.data.isResetValid === true) {
          setPasswordResetValidity(true);
        }
      } catch (error) { }
      await setIsloading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const notifyAccountError = () =>
    toast(t("changePassword.The password change has expired"));

  const handlePassword = (event) => {
    event.stopPropagation();
    const fieldValue = event.target.value;
    const newPasswordState = { ...passwordState };

    newPasswordState.password = fieldValue;
    setPasswordState(newPasswordState);

    setPasswordNotif(checkPassword(fieldValue, t));
  };

  const changePassword = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/resetPassword/change/${resetToken}`,
        {
          password: passwordState.password,
        }
      );

      if (response.status === 200) {
        if (response.data.message === "ok") {
          history.push(`/`);
        } else if (response.data.message === "error password") {
          notifyAccountError();
        }
      }
    } catch (error) { }

    resetForm();
  };

  const resetForm = () => { };

  return (
    <Container>
      <FormContainer>
        <Title>{t("changePassword.title")}</Title>
        {isLoading === true ? (
          <LoaderAnimation></LoaderAnimation>
        ) : passwordResetValidity === true ? (
          <>
            <FormLabel htmlFor="newPassword">
              {t("changePassword.passwordLabel")}
            </FormLabel>
            <InputContainer>
              <FormInput
                onChange={handlePassword}
                type="password"
                id="newPassword"
                name="password"
                value={passwordState.password}
                placeholder={t("changePassword.passwordPlaceholder")}
              ></FormInput>
              <FieldError textColor={passwordNotif.color}>
                {passwordNotif.text}
              </FieldError>
            </InputContainer>
            <SubmitBtn
              isVisible={passwordNotif.isValid}
              onClick={changePassword}
              className="submit-button"
              type="submit"
            >
              {t("changePassword.submitBtn")}
            </SubmitBtn>
          </>
        ) : (
              <div>
                <WarningTitle>{t("changePassword.warningTitle")}</WarningTitle>

                <InfoMessage>{t("changePassword.infoMessage")}</InfoMessage>
              </div>
            )}
      </FormContainer>

      <StyledToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </Container>
  );
};

export default ChangePassword;
