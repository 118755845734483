import React from "react";
import Editor from "../../utils/editFunctions";

const Template1 = ({ template, statusMode }) => {
  const editor = new Editor(template, statusMode);

  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        {/* template 1 */}
        {/*[if mso | IE]>
  <table
     align="center" border="0" cellpadding="0" cellspacing="0" class="content-padding" style="width:600px;" width="600"
  >
    <tr>
      <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
  <![endif]*/}
        <div
          className="content-padding"
          style={{
            background: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            margin: "0px auto",
            maxWidth: "600px",
          }}
        >
          <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
              background: editor.handleTemplateBgColor(),
              backgroundColor: editor.handleTemplateBgColor(),
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    direction: "ltr",
                    fontSize: "0px",

                    padding: `${editor.handleTopMargin()} 10% ${editor.handleBottomMargin()} 10%`,
                    textAlign: "center",
                  }}
                >
                  {/*[if mso | IE]>
              <table border="0" cellpadding="0" cellspacing="0">

    <tr>

        <td
           class="" style="vertical-align:top;width:580px;"
        >
      <![endif]*/}
                  <div
                    className="mj-column-per-100 mj-outlook-group-fix"
                    style={{
                      fontSize: "0px",
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%",
                    }}
                  >
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              verticalAlign: "top",
                              padding: "0 0px 0 0px",
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              style={{}}
                              width="100%"
                            >
                              <tbody>
                                {editor.hasAnActiveTitle("title-1") === true ? (
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "0px",
                                        padding: "0 0 10px 0",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontFamily:
                                            '"Raleway",  "Arial-Black", sans-serif',
                                          fontSize: "21px",
                                          fontWeight: "900",
                                          lineHeight: "25px",
                                          textAlign: "left",
                                          color: "#0C3944",
                                        }}
                                      >
                                        {editor.handleTitle("title-1")}
                                      </div>
                                    </td>
                                  </tr>
                                ) : null}
                                <tr>
                                  <td
                                    align="left"
                                    style={{
                                      fontSize: "0px",
                                      padding: "0 0 0 0",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontFamily: '"Lato", arial, sans-serif',
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "25px",
                                        textAlign: editor.handleTextAlign("text-1"),
                                        color: "#183F4B",
                                      }}
                                    >
                                      {editor.handleText("text-1")}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*[if mso | IE]>
        </td>

    </tr>

              </table>
            <![endif]*/}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*[if mso | IE]>
      </td>
    </tr>
  </table>
  <![endif]*/}
        {/* template 1 */}
      </div>
      {editor.handleDivider()}
    </>
  );
};

export default Template1;
