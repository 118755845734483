import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

const LAbelContainer = styled.div`
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  width: 100px;
  margin-right: 10px;
  font-weight: bold;
`;

const FieldContainer = styled.div`
  flex: 1;
`;

const EditField = styled.input`
  display: block;
  width: 100%;
  height: 35px;
  margin: 10px auto;
  background: #fff;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  padding: 5px;
  font-size: 16px;
  border: 2px solid #fff;
  transition: all 0.3s ease;
  border-radius: 5px;
  outline: none;
  &:focus {
    border: 2px solid #1abc9d;
  }
`;

const UrlEditor = ({ action, link }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <LAbelContainer>{t("urlEditor.LinkLabel")}</LAbelContainer>
      <FieldContainer>
        <EditField type="url" value={link} onChange={action}></EditField>
      </FieldContainer>
    </Container>
  );
};

export default UrlEditor;
