import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  FormLabel,
  BtnWrapper,
  FormContainer,
  TextInput,
  SelectInput,
  SubmitBtn,
  FieldError,
  InputContainer,
  StyledToastContainer,
} from "./styles";
import axios from "axios";
import { useHistory } from "react-router-dom";
import AdminLayout from "../../../components/AdminLayout";
import Breadcrumb from "../../../components/Breadcrumb";
import CategorySelect from '../../../components/CategorySelect';

const CreateUser = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userForm, setUserForm] = useState({
    id: null,
    email: "",
    password: "",
    userName: "",
    role: "editor",
    categories: []
  });

  const [emailNotif, setEmailNotif] = useState({
    isValid: false,
    message: t("createUser.emailRequiredMessage"),
  });

  const formValid = () => {
    if (
      emailNotif.isValid === true &&
      userForm.password.length > 0 &&
      userForm.userName.length > 0
    ) {
      return true;
    }
    return false;
  };

  const notifyEmailError = () => toast(t("createUser.emailErrorNotif"));

  const senduserForm = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/user/sign_up`,
        {
          userName: userForm.userName,
          email: userForm.email,
          password: userForm.password,
          role: userForm.role,
          categories: userForm.categories
        }
      );

      if (response.status === 200) {
        await resetUserForm();
        await history.push("/user/list");
      }
    } catch (error) {
      notifyEmailError();
    }
  };

  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.getAttribute("name");
    const newUserForm = { ...userForm };

    newUserForm[fieldName] = fieldValue;
    setUserForm(newUserForm);
    if (fieldName === "email") {
      const newEmailNotif = { ...emailNotif };
      if (typeof fieldValue !== "undefined" && fieldValue !== "") {
        let lastAtPos = fieldValue.lastIndexOf("@");
        let lastDotPos = fieldValue.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fieldValue.indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            fieldValue.length - lastDotPos > 2
          )
        ) {
          newEmailNotif.message = t("createUser.emailValididityMessage");
          setEmailNotif(newEmailNotif);
          newEmailNotif.isValid = false;
        } else {
          newEmailNotif.isValid = true;
          setEmailNotif(newEmailNotif);
        }
      } else {
        newEmailNotif.message = t("createUser.emailRequiredMessage");
        newEmailNotif.isValid = false;
        setEmailNotif(newEmailNotif);
      }
    }
  };

  const resetUserForm = () => {
    const newUserForm = { ...userForm };
    newUserForm.id = null;
    newUserForm.userName = "";
    newUserForm.email = "";
    newUserForm.password = "";
    newUserForm.role = "editor";
    newUserForm.categories = [];
    setUserForm(newUserForm);
  };

  const addUserCategory = (category) => {
    if (userForm.categories.indexOf(category) === -1) {
      const newUserForm = {...userForm};

      newUserForm.categories = [...userForm.categories, category];
      setUserForm(newUserForm);
    }
  }
  const removeUserCategory = (category) => {
    const index = userForm.categories.indexOf(category);
    if (index >= 0) {
      const newUserForm = {...userForm};
      newUserForm.categories.splice(index, 1);
      setUserForm(newUserForm);
    }
  }

  return (
    <AdminLayout>
      <Breadcrumb title={t("createUser.title")} link="/user/list"></Breadcrumb>
      <FormContainer className="login-form">
        <FormLabel>{t("createUser.userNamePlaceHolder")}</FormLabel>
        <InputContainer>
          <TextInput
            onChange={handleChange}
            type="text"
            id="userName"
            placeholder={t("createUser.userNamePlaceHolder")}
            name="userName"
            value={userForm.userName}
          ></TextInput>
        </InputContainer>
        <FormLabel>{t("createUser.emailLabel")}</FormLabel>
        <InputContainer>
          <TextInput
            onChange={handleChange}
            type="email"
            id="email"
            placeholder={t("createUser.emailLabel")}
            name="email"
            value={userForm.email}
          ></TextInput>
          <FieldError isValid={emailNotif.isValid}>
            {emailNotif.message}
          </FieldError>
        </InputContainer>

        <FormLabel>{t("createUser.passwordLabel")}</FormLabel>
        <InputContainer>
          <TextInput
            placeholder={t("createUser.passwordLabel")}
            onChange={handleChange}
            type="password"
            id="password"
            name="password"
            value={userForm.password}
          ></TextInput>
        </InputContainer>

        <FormLabel>{t("createUser.roleLabel")}</FormLabel>
        <InputContainer>
          <SelectInput name="role" onChange={handleChange}>
            <option value="editor">Editor</option>
            <option value="super-admin">Super Admin</option>
          </SelectInput>
        </InputContainer>

        { userForm.role === 'editor' ? (
          <>
            <FormLabel>{t("editUser.categoriesLabel")}</FormLabel>
            <CategorySelect categories={userForm.categories} onAdd={addUserCategory} onRemove={removeUserCategory}></CategorySelect>
          </>
        ) : null }

        <BtnWrapper>
          <SubmitBtn
            isVisible={formValid()}
            onClick={senduserForm}
            className="submit-button"
            type="submit"
          >
            {t("createUser.addBtn")}
          </SubmitBtn>
        </BtnWrapper>
      </FormContainer>
      <StyledToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </AdminLayout>
  );
};

export default CreateUser;
