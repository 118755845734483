import React from "react";
import Editor from "../../utils/editFunctions";

const Template7 = ({ template, statusMode }) => {
  const editor = new Editor(template, statusMode);
  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        {/* template 7 */}
        <table
          align="center"
          border={0}
          cellPadding={0}
          cellSpacing={0}
          style={{
            background: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <td>
                {/*[if mso | IE]>
<table
   align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:1200px;" width="1200"
>
  <tr>
    <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]*/}
                <div style={{ margin: "0px auto", maxWidth: "1200px" }}>
                  <table
                    align="center"
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    style={{ width: "100%" }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            direction: "ltr",
                            fontSize: "0px",
                            padding: `${editor.handleTopMargin()} 0px ${editor.handleBottomMargin()} 0px`,
                            textAlign: "center",
                          }}
                        >
                          {/*[if mso | IE]>
            <table border="0" cellpadding="0" cellspacing="0">

  <tr>

      <td
         class="" style="vertical-align:top;width:1200px;"
      >
    <![endif]*/}
                          <div
                            className="mj-column-per-100 mj-outlook-group-fix"
                            style={{
                              fontSize: "0px",
                              textAlign: "left",
                              direction: "ltr",
                              display: "inline-block",
                              verticalAlign: "top",
                              width: "100%",
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      backgroundColor: editor.handleBgColor(
                                        "backgroundColors",
                                        "backgroundColor-1"
                                      ),
                                      verticalAlign: "top",
                                      padding: 0,
                                      paddingRight: "0px",
                                      paddingLeft: "0px",
                                    }}
                                  >
                                    <table
                                      border={0}
                                      cellPadding={0}
                                      cellSpacing={0}
                                      style={{}}
                                      width="100%"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              fontSize: "0px",
                                              padding: 0,
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <table
                                              border={0}
                                              cellPadding={0}
                                              cellSpacing={0}
                                              style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0px",
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{ width: "1200px" }}
                                                  >
                                                    <img
                                                      height="auto"
                                                      src="https://makesense-outil-newsletter.s3-eu-west-1.amazonaws.com/Nouveau-Block-Bleu-top-ok_c7c1gg.png"
                                                      alt="top-frame"
                                                      style={{
                                                        border: 0,
                                                        display: "block",
                                                        outline: "none",
                                                        textDecoration: "none",
                                                        height: "auto",
                                                        width: "100%",
                                                        fontSize: "13px",
                                                      }}
                                                      width={1200}
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              fontSize: "0px",
                                              padding: "10px 25px",
                                              paddingBottom: "0px",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontFamily:
                                                  '"Raleway", "Arial-Black", sans-serif',
                                                fontSize: "21px",
                                                fontWeight: 900,
                                                lineHeight: "25px",
                                                textAlign: "center",
                                                color: "#FFFFFF",
                                              }}
                                            >
                                              {editor.handleTitle("title-1")}
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              fontSize: "0px",
                                              padding: "10px 25px",
                                              paddingRight: "10%",
                                              paddingLeft: "10%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontFamily:
                                                  '"Lato", "Arial", sans-serif',
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                lineHeight: "25px",
                                                textAlign: editor.handleTextAlign("text-1"),
                                                color: "#F8F9FA",
                                              }}
                                            >
                                              {editor.handleText("text-1")}
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            vertical-align="middle"
                                            style={{
                                              fontSize: "0px",
                                              padding: "10px 0 20px 0",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <table
                                              border={0}
                                              cellPadding={0}
                                              cellSpacing={0}
                                              style={{
                                                borderCollapse: "separate",
                                                lineHeight: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    align="center"
                                                    bgcolor={editor.handleBgColor(
                                                      "backgroundColors",
                                                      "backgroundColor-1"
                                                    )}
                                                    style={{
                                                      border: "none",
                                                      borderRadius: "100px",
                                                      cursor: "auto",
                                                      msoPaddingAlt:
                                                        "10px 25px",
                                                      backgroundColor: editor.handleBgColor(
                                                        "backgroundColors",
                                                        "backgroundColor-1"
                                                      ),
                                                    }}
                                                    valign="middle"
                                                  >
                                                    <a
                                                      href={editor.handleUrl(
                                                        "buttons",
                                                        "button-1"
                                                      )}
                                                      style={{
                                                        display: "inline-block",
                                                        background: editor.handleBgColor(
                                                          "buttons",
                                                          "button-1"
                                                        ),
                                                        color: editor.handleTextColor(
                                                          "buttons",
                                                          "button-1"
                                                        ),
                                                        fontFamily:
                                                          '"Raleway", "Arial-Black", sans-serif',
                                                        fontSize: "16px",
                                                        fontWeight: 800,
                                                        lineHeight: "14px",
                                                        margin: 0,
                                                        textDecoration: "none",
                                                        textTransform: "none",
                                                        padding: "10px 25px",
                                                        msoPaddingAlt: "0px",
                                                        borderRadius: "100px",
                                                      }}
                                                      rel="noopener noreferrer"
                                                      target="_blank"
                                                    >
                                                      {editor.handleBtnText(
                                                        "button-1"
                                                      )}
                                                    </a>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              fontSize: "0px",
                                              padding: 0,
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <table
                                              border={0}
                                              cellPadding={0}
                                              cellSpacing={0}
                                              style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0px",
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{ width: "1200px" }}
                                                  >
                                                    <img
                                                      height="auto"
                                                      src="https://res.cloudinary.com/dnl53glfq/image/upload/v1601309568/Nouveau-Block-Bleu-bas-ok_zz56hf.png"
                                                      alt="bottom-frame"
                                                      style={{
                                                        border: 0,
                                                        display: "block",
                                                        outline: "none",
                                                        textDecoration: "none",
                                                        height: "auto",
                                                        width: "100%",
                                                        fontSize: "13px",
                                                      }}
                                                      width={1200}
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/*[if mso | IE]>
      </td>

  </tr>

            </table>
          <![endif]*/}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/*[if mso | IE]>
    </td>
  </tr>
</table>
<![endif]*/}
              </td>
            </tr>
          </tbody>
        </table>
        {/* template 7 */}
      </div>
      {editor.handleDivider()}
    </>
  );
};

export default Template7;
