import React from "react";
import Editor from "../../utils/editFunctions";
const footer = require("../../datas/footer.json");

const Footer = ({ template, statusMode }) => {
  if (!template.content.images) {
    template.content.images = Object.assign({}, footer['footer-1'].content.images);
  }
  const editor = new Editor(template, statusMode);
  const renderLink = () => {
    if (template.socialLinks !== undefined) {
      return template.socialLinks.map((element, index) => {
        return (
          <React.Fragment key={`footer-link-${index}`}>
            {/*[if mso | IE]>
          <td>
        <![endif]*/}
            <table
              link={`footer-link-${index}-${template.id}`}
              align="center"
              border={0}
              cellPadding={0}
              cellSpacing={0}
              style={{
                float: "none",
                display: "inline-table",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ padding: "4px" }}>
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      style={{
                        background: editor.handleBgColor(
                          "backgroundColors",
                          "backgroundColor-1"
                        ),
                        borderRadius: "3px",
                        width: "30px",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontSize: 0,
                              height: "30px",
                              verticalAlign: "middle",
                              width: "30px",
                            }}
                          >
                            <a
                              href={element.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                height={30}
                                alt="social"
                                src={`https://makesense-outil-newsletter.s3.eu-west-1.amazonaws.com/social/${element.icon}-${editor.handleBgColorIndex(
                                  "backgroundColors",
                                  "textColor-1"
                                )}.png`}
                                style={{
                                  borderRadius: "3px",
                                  display: "block",
                                }}
                                width={30}
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            {/*[if mso | IE]>
          </td>
  <![endif]*/}
          </React.Fragment>
        );
      });
    }
  };

  // const editor = new Editor(template, statusMode);

  return (
    <div>
      {/* footer */}
      <table
        align="center"
        border={0}
        cellPadding={0}
        cellSpacing={0}
        style={{
          background: editor.handleBgColor(
            "backgroundColors",
            "backgroundColor-1"
          ),
          backgroundColor: editor.handleBgColor(
            "backgroundColors",
            "backgroundColor-1"
          ),
          width: "100%",
        }}
      >
        <tbody>
          <tr>
            <td>
              {/*[if mso | IE]>
<table
   align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
>
  <tr>
    <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]*/}
              <div style={{ margin: "0px auto", maxWidth: "600px" }}>
                <table
                  align="center"
                  border={0}
                  cellPadding={0}
                  cellSpacing={0}
                  style={{ width: "100%" }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          direction: "ltr",
                          fontSize: "0px",
                          padding: "20px 0",
                          textAlign: "center",
                        }}
                      >
                        {/*[if mso | IE]>
            <table border="0" cellpadding="0" cellspacing="0">

  <tr>

      <td
         class="" style="vertical-align:top;width:600px;"
      >
    <![endif]*/}
                        <div
                          className="mj-column-per-100 mj-outlook-group-fix"
                          style={{
                            fontSize: "0px",
                            textAlign: "left",
                            direction: "ltr",
                            display: "inline-block",
                            verticalAlign: "top",
                            width: "100%",
                          }}
                        >
                          <table
                            border={0}
                            cellPadding={0}
                            cellSpacing={0}
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{ verticalAlign: "top", padding: 0 }}
                                >
                                  <table
                                    border={0}
                                    cellPadding={0}
                                    cellSpacing={0}
                                    style={{}}
                                    width="100%"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          align="center"
                                          style={{
                                            fontSize: "0px",
                                            padding: "10px 25px",
                                            paddingTop: "20px",
                                            paddingBottom: "30px",
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          <table
                                            border={0}
                                            cellPadding={0}
                                            cellSpacing={0}
                                            style={{
                                              borderCollapse: "collapse",
                                              borderSpacing: "0px",
                                            }}
                                          >
                                            <tbody>
                                              <tr>
                                                <td style={{ width: (editor.handleNumberValue(
                                                    "imageWidth"
                                                  ) || 140) + "px" }}>
                                                  <img
                                                    alt="logo make sense"
                                                    height="auto"
                                                    src={editor.handleImageSrc(
                                                      "image-1"
                                                    )}
                                                    style={{
                                                      border: 0,
                                                      display: "block",
                                                      outline: "none",
                                                      textDecoration: "none",
                                                      height: "auto",
                                                      width: "100%",
                                                      fontSize: "13px",
                                                    }}
                                                    width={(editor.handleNumberValue(
                                                      "imageWidth"
                                                    ) || 140)}
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          align="center"
                                          style={{
                                            fontSize: "0px",
                                            padding: 0,
                                            wordBreak: "break-word",
                                          }}
                                        >
                                          {/*[if mso | IE]>
                                          <table
                                            align="center" border="0" cellpadding="0" cellspacing="0">
                                            <tr>
                                       <![endif]*/}
                                          {renderLink()}
                                          {/*[if mso | IE]>

                                            </tr>
                                          </table>
                                        <![endif]*/}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/*[if mso | IE]>
                  </td>
              </tr>
            </table>
          <![endif]*/}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/*[if mso | IE]>
                </td>
              </tr>
            </table>
            <![endif]*/}
            </td>
          </tr>
        </tbody>
      </table>
      <table
        align="center"
        border={0}
        cellPadding={0}
        cellSpacing={0}
        style={{
          background: editor.handleBgColor(
            "backgroundColors",
            "backgroundColor-1"
          ),
          backgroundColor: editor.handleBgColor(
            "backgroundColors",
            "backgroundColor-1"
          ),
          width: "100%",
        }}
      >
        <tbody>
          <tr>
            <td>
              {/*[if mso | IE]>
<table
   align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
>
  <tr>
    <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]*/}
              <div style={{ margin: "0px auto", maxWidth: "600px" }}>
                <table
                  align="center"
                  border={0}
                  cellPadding={0}
                  cellSpacing={0}
                  style={{ width: "100%" }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          direction: "ltr",
                          fontSize: "0px",
                          padding: "20px 0",
                          paddingTop: 0,
                          textAlign: "center",
                        }}
                      >
                        {/*[if mso | IE]>
            <table  border="0" cellpadding="0" cellspacing="0">

  <tr>

      <td
         class="" style="width:600px;"
      >
    <![endif]*/}
                        <div
                          className="mj-column-per-100 mj-outlook-group-fix"
                          style={{
                            fontSize: 0,
                            lineHeight: 0,
                            textAlign: "left",
                            display: "inline-block",
                            width: "100%",
                            direction: "ltr",
                          }}
                        >
                          {/*[if mso | IE]>
  <table
     border="0" cellpadding="0" cellspacing="0">
    <tr>
        <td
           style="vertical-align:top;width:600px;">
        <![endif]*/}
                          <div
                            className="mj-column-per-100 mj-outlook-group-fix"
                            style={{
                              fontSize: "0px",
                              textAlign: "left",
                              direction: "ltr",
                              display: "inline-block",
                              verticalAlign: "top",
                              width: "100%",
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      verticalAlign: "top",
                                      paddingRight: 0,
                                    }}
                                  >
                                    <table
                                      border={0}
                                      cellPadding={0}
                                      cellSpacing={0}
                                      style={{}}
                                      width="100%"
                                    >
                                      <tbody>

                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              fontSize: "0px",
                                              padding: "10px 25px 0px 25px",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontFamily:
                                                  '"Raleway", "Arial-Black", sans-serif',
                                                fontSize: "12px",
                                                fontWeight: "normal",
                                                lineHeight: "19px",
                                                textAlign: "center",
                                                color: editor.handleBgColor(
                                                  "backgroundColors",
                                                  "textColor-1"),
                                              }}
                                            >
                                              {
                                                template.content.texts && template.content.texts["text-1"]
                                                ? editor.handleText("text-1")
                                                : "Vous avez reçu cet email car vous avez croisé la route de makesense"
                                              }
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              fontSize: "0px",
                                              padding: "0px 25px 10px 25px",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontFamily:
                                                  '"Raleway", "Arial-Black", sans-serif',
                                                fontSize: "19px",
                                                fontWeight: "bold",
                                                lineHeight: "16px",
                                                textAlign: "center",
                                                color: editor.handleBgColor(
                                                  "backgroundColors",
                                                  "textColor-1"),
                                              }}
                                            >
                                              <a
                                                style={{
                                                  color: editor.handleBgColor(
                                                    "backgroundColors",
                                                    "textColor-1"),
                                                  fontFamily:
                                                    '"Raleway", "Arial-Black", sans-serif',
                                                  fontSize: "12px",
                                                  lineHeight: "19px",
                                                }}
                                                href={editor.handleUnsubscribeLink(
                                                  "unsubscribeLink"
                                                )}
                                              >
                                                {
                                                  template.content.texts && template.content.texts["text-2"]
                                                    ? editor.handleText("text-2")
                                                    : "Pour vous désinscrire c'est par ici"
                                                }
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/*[if mso | IE]>
        </td>

    </tr>
    </table>
  <![endif]*/}
                        </div>
                        {/*[if mso | IE]>
      </td>

  </tr>

            </table>
          <![endif]*/}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/*[if mso | IE]>
    </td>
  </tr>
</table>
<![endif]*/}
            </td>
          </tr>
        </tbody>
      </table>
      {/* footer */}
    </div>
  );
};

export default Footer;
