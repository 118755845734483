import React from "react";
import { useTranslation } from "react-i18next";
import { LangSelector } from "./styles.js";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <LangSelector
      value={i18n.language}
      name="language"
      onChange={changeLanguage}
    >
      <option value="en">EN</option>

      <option value="fr">FR</option>
    </LangSelector>
  );
};

export default LanguageSelector;
