import React from "react";
import { Container, InfoMessage } from "./styles.js";
import { useTranslation } from "react-i18next";

const ResetPasswordSended = (props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <InfoMessage>{t("resetPasswordSended.message")}</InfoMessage>
    </Container>
  );
};

export default ResetPasswordSended;
