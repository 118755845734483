import React from "react";
import styled from "styled-components";

// const sliderThumbStyles = (props) => `
//   width: 20px;
//   height: 20px;
//   background: blue;
//     border-radius: 30px;
//   cursor: pointer;
//   -webkit-transition: .2s;
//   transition: opacity .2s;
// `;

const Styles = styled.div`
  display: flex;
  align-items: center;

  margin-top: 10px;
  margin-bottom: 10px;
  .value {
    flex: 1;
    font-size: 10px;
  }
`;

const InputSlider = styled.input`
   {
    -webkit-appearance: none;
    width: 100%;
    padding: 0px;
    height: 4px;
    border-radius: 5px;
    background: #efefef;
    outline: none;
  }
`;

const Slider = ({ min, max, step, action, sliderValue }) => {
  const renderElement = () => {
    return (
      <>
        <InputSlider
          type="range"
          min={min}
          max={max}
          step={step}
          value={sliderValue}
          className="slider"
          onChange={action}
        />
        <div className="value">{sliderValue}</div>
      </>
    );
  };

  return <Styles>{renderElement()}</Styles>;
};

export default Slider;
