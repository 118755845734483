import React, { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill, { Quill } from "react-quill"; // ES6
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-quill/dist/quill.snow.css";

let newLink = Quill.import("formats/link");
Quill.debug(false);
class LinkBlot extends newLink {
  static create(value) {
    const node = super.create(value);

    node.setAttribute("rel", "noopener noreferrer");
    node.setAttribute("target", "_blank");
    node.setAttribute("style", "color:#16586F");
    return node;
  }
}
LinkBlot.blotName = "link";
LinkBlot.tagName = "A";

Quill.register("formats/link", LinkBlot);

const EditWrapper = styled.div`
  display: block;
  width: 100%;

  margin: 10px auto;

  box-sizing: border-box;
  font-family: "Raleway", sans-serif;

  font-size: 16px;

  transition: all 0.3s ease;

  outline: none;

  color: #000;
  cursor: text;

  .DraftEditor-root,
  .StaticEditor {
    margin-top: 10px;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
    min-height: 100px;
    &:focus {
      border: 2px solid #1abc9d;
    }
  }
`;

const Container = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

const FieldContainer = styled.div`
  flex: 1;
`;

const TextAlignBtn = styled.button`
width: 30px;
height: 30px;
box-sizing: border-box;
background-color: ${({ isSelected }) => isSelected ? "#000" : "#FFF"};
color: ${({ isSelected }) => isSelected ? "#FFF" : "#000"};
border: none;
margin-right:1px;
border-radius: 2px;
cursor: pointer;
outline: none;
transition: all 0.1s ease-in-out;
&:hover {
  background-color: #000;
color: #FFF;




}
`;

const TextareaEditor = ({ templateId, textId }) => {
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);

  const dispatch = useDispatch();
  const { content } = { ...newsletterBuilder.templates[templateId] };
  const editionMode =
    newsletterBuilder.selectedTemplateId === templateId ? true : false;
  const { text } = { ...content.texts[textId] };

  const contentState = decodeURI(text);
  const newEditorState = contentState;

  const [editorState, setEditorState] = useState(newEditorState);

  const setEditor = (editorState) => {
    setEditorState(editorState);

    const content = encodeURI(editorState);

    dispatch({
      type: "CHANGE_TEXT",
      data: {
        templateId: templateId,
        textId: textId,
        text: content,
      },
    });
  }

  const checkTextAlignBtnSelected = (alignValue) => {
    let refValue = "left";

    if (content.texts[textId].align !== undefined) {
      refValue = content.texts[textId].align;
    }
    if (alignValue === refValue) {
      return true;
    }

    return false
  }



  const renderTextarea = () => {
    if (editionMode === true) {
      return (
        <Container
          onMouseEnter={() => {
            dispatch({
              type: "ADD_HIGHLIGHT_ON_FIELD",
              AttributeId: textId,
            });
          }}
          onMouseLeave={() => {
            // if (isSelectedField === false) {
            dispatch({
              type: "REMOVE_HIGHLIGHT_ON_FIELD",
            });
            // }
          }}
        >
          <FieldContainer
            onMouseEnter={() => {
              dispatch({
                type: "SHOW_DRAFT_EDITOR",
                textId: textId,
              });
            }}
          >

            <div>
              <TextAlignBtn

                isSelected={checkTextAlignBtnSelected("left")}
                onClick={() => {

                  dispatch({
                    type: "CHANGE_TEXT_ALIGN",
                    data: {
                      templateId: templateId,
                      textId: textId,
                      align: "left",
                    },
                  });


                }}><FontAwesomeIcon icon="align-left" /></TextAlignBtn>
              <TextAlignBtn
                isSelected={checkTextAlignBtnSelected("center")}

                onClick={() => {
                  dispatch({
                    type: "CHANGE_TEXT_ALIGN",
                    data: {
                      templateId: templateId,
                      textId: textId,
                      align: "center",
                    },
                  });


                }}><FontAwesomeIcon icon="align-center" /></TextAlignBtn>
              <TextAlignBtn
                isSelected={checkTextAlignBtnSelected("right")}

                onClick={() => {

                  dispatch({
                    type: "CHANGE_TEXT_ALIGN",
                    data: {
                      templateId: templateId,
                      textId: textId,
                      align: "right",
                    },
                  });


                }}><FontAwesomeIcon icon="align-right" /></TextAlignBtn>

            </div>

            {newsletterBuilder.selectedDraftEditor !== textId ? (
              <EditWrapper>
                <div className="StaticEditor">
                  <div dangerouslySetInnerHTML={{ __html: decodeURI(text) }} />
                </div>
              </EditWrapper>
            ) : (
                <EditWrapper>
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline"],
                        ["link"],
                        ["clean"],
                      ],
                    }}
                    value={editorState}
                    onChange={setEditor}
                  />
                </EditWrapper>
              )}
          </FieldContainer>
        </Container>
      );
    } else {
      return text;
    }
  };

  return <>{renderTextarea()}</>;
};

export default TextareaEditor;
