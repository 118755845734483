import styled from "styled-components";
import { Link } from "react-router-dom";

export const ItemMenu = styled.li`
  color: ${({ selectedlink }) => (selectedlink === "true" ? "green" : "grey")};
  height: 50px;
  color: #0c3944;
  display: flex;
  align-items: center;
  border-left: ${({ selectedlink }) =>
    selectedlink === "true" ? "10px solid green" : "10px solid  #fabe3c"};
  padding-left: 20px;
`;

export const MenuWrapper = styled.ul`
  background: #fabe3c;
  height: 100vh;
  width: 200px;
  box-sizing: border-box;

  position: ${({ position }) => (position < 120 ? "fixed" : "relative")};
  ${({ position }) =>
    position < 120 ? "top:" + parseInt(position) + "px" : ""};
`;

export const MenuTitle = styled.li`
  box-sizing: border-box;
  padding: 20px;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  height: 60px;
  font-size: 20px;
  display: flex;
  background-color: ${({ selectedlink }) =>
    selectedlink === "true"
      ? "rgba(255, 255, 255, 0.2);"
      : "rgba(255, 255, 255, 0.2);"};
  justify-content: space-between;
  align-items: center;
  color: #fff;
  cursor: pointer;
  &:hover {
  }
`;

export const SubMenuWrapper = styled.ul`
  display: ${({ selectedlink }) =>
    selectedlink === "true" ? "block" : "none"};
`;

export const SubMenuLink = styled(Link)`
  color: #0c3944;
  position: relative;
  z-index: 2;
  ${({ selectedlink }) =>
    selectedlink === "true"
      ? `
  &::after {
    border-radius: 1000px;
    position: absolute;
    top: -10px;
    left: -20px;
    right: -20px;
    bottom: -10px;
    display: block;
    content: "";
    background-color: #fff;
    -webkit-transition: left 0.2s, right 0.2s;
    transition: left 0.2s, right 0.2s;
  }
  & span {
    color:#0c3944;
  }
  
  `
      : ``};
`;

export const SubMenuWithoutLink = styled.div`
  color: #0c3944;
  position: relative;
  z-index: 2;
  opacity: 0.5;
  ${({ selectedlink }) =>
    selectedlink === "true"
      ? `
&::after {
  border-radius: 1000px;
  position: absolute;
  top: -10px;
  left: -20px;
  right: -20px;
  bottom: -10px;
  display: block;
  content: "";
  
  background-color: #fff;
  -webkit-transition: left 0.2s, right 0.2s;
  transition: left 0.2s, right 0.2s;
}
& span {
  color:#0c3944;
}
opacity: 1;
`
      : ``};
`;

export const SubMenuText = styled.span`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  font-family: "Raleway", sans-serif;

  position: relative;
  z-index: 2;
`;
