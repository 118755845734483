import React from "react";
import styled from "styled-components";
import PickBgColor from "./PickBgColor";
import { useTranslation } from "react-i18next";
import UrlEditor from "./UrlEditor";
import { useSelector, useDispatch } from "react-redux";
import PickColor from "./PickColor";
import Checkbox from './Checkbox';

const ContainerWrapper = styled.div`
  opacity: ${({ disabled }) => (disabled !== true ? 1 : 0.2)};
  ${({ disabled }) => (disabled !== true ? "" : "pointer-events: none;")}
`;

const EditField = styled.input`
  display: block;
  width: 100%;
  height: 35px;
  margin: 10px auto;
  background: #fff;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  padding: 5px;
  font-size: 16px;
  border: 2px solid #fff;
  transition: all 0.3s ease;
  border-radius: 5px;
  outline: none;
  &:focus {
    border: 2px solid #1abc9d;
  }
`;

const Container = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

const LAbelContainer = styled.div`
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  width: 100px;
  margin-right: 10px;
  font-weight: bold;
`;

const FieldContainer = styled.div`
  flex: 1;
`;

const DividerLabel = styled.label`
  font-family: "Raleway", sans-serif;
  font-size: 16px;
`;

const BtnEditor = ({ templateId, btnId }) => {
  const { t } = useTranslation();
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();

  const { content } = { ...newsletterBuilder.templates[templateId] };
  const editionMode =
    newsletterBuilder.selectedTemplateId === templateId ? true : false;
  console.log(content.buttons);
  const { text, link, disabled } = { ...content.buttons[btnId] };

  const changeBackgroundColor = (event) => {
    const bgColor = event.target.value;

    dispatch({
      type: "CHANGE_BUTTON_BACKGROUND_COLOR",
      data: {
        templateId: templateId,
        btnId: btnId,
        bgColor: bgColor,
      },
    });
  };

  const changeBtnTextColor = (event) => {
    const color = event.target.value;

    dispatch({
      type: "CHANGE_BTN_TEXT_COLOR",
      data: {
        templateId: templateId,
        btnId: btnId,
        textColor: color,
      },
    });
  };

  const changeBtnBorderColor = (event) => {
    const color = event.target.value;

    dispatch({
      type: "CHANGE_BTN_BORDER_COLOR",
      data: {
        templateId: templateId,
        btnId: btnId,
        borderColor: color,
      },
    });
  };

  const changeUrl = (event) => {
    const newLink = event.target.value;

    dispatch({
      type: "CHANGE_BUTTON_URL",
      data: {
        templateId: templateId,
        btnId: btnId,
        link: newLink,
      },
    });
  };

  const renderButton = () => {
    if (editionMode === true) {
      return (
        <ContainerWrapper
          onMouseEnter={() => {
            dispatch({
              type: "ADD_HIGHLIGHT_ON_FIELD",
              AttributeId: btnId,
            });
          }}
          onMouseLeave={() => {
            // if (isSelectedField === false) {
            dispatch({
              type: "REMOVE_HIGHLIGHT_ON_FIELD",
            });
            // }
          }}
        >
          {newsletterBuilder.templates[templateId].content.buttons[btnId]
            .disabled === undefined ? null : (
              <Container>
                <LAbelContainer>{t("btnEditor.disableLabel")}</LAbelContainer>
                <FieldContainer>
                  <DividerLabel htmlFor="button_disabled">
                    <Checkbox
                      id="button_disabled"
                      onChange={() => {
                        dispatch({
                          type: "CHANGE_BUTTON_DISABLED",
                          data: {
                            templateId: templateId,
                            btnId: btnId,
                            disabled: !disabled,
                          },
                        });
                      }}
                      checked={disabled}
                    ></Checkbox>
                  {disabled === true
                    ? t("btnEditor.enable")
                    : t("btnEditor.disable")}
                  </DividerLabel>
                </FieldContainer>
              </Container>
          )}
          <ContainerWrapper disabled={disabled}>
          {newsletterBuilder.templates[templateId].content.buttons[btnId]
            .text === undefined ? null : (
            <Container>
              <LAbelContainer>{t("btnEditor.textLabel")}</LAbelContainer>
              <FieldContainer>
                <EditField
                  editionMode={editionMode}
                  type="text"
                  value={text}
                  placeholder="btn text"
                  onChange={(event) => {
                    const newText = event.target.value;

                    dispatch({
                      type: "CHANGE_BUTTON_TEXT",
                      data: {
                        templateId: templateId,
                        btnId: btnId,
                        text: newText,
                      },
                    });
                  }}
                ></EditField>
              </FieldContainer>
            </Container>
          )}
          <UrlEditor action={changeUrl} link={link}></UrlEditor>

          {newsletterBuilder.templates[templateId].content.buttons[btnId]
            .bgColor === undefined ? null : (
            <PickBgColor
              labelName="background"
              action={changeBackgroundColor}
            ></PickBgColor>
          )}

          {newsletterBuilder.templates[templateId].content.buttons[btnId]
            .textColor === undefined ? null : (
            <PickColor labelName="text" action={changeBtnTextColor}></PickColor>
          )}

          {newsletterBuilder.templates[templateId].content.buttons[btnId]
            .borderColor === undefined ? null : (
            <PickColor
              labelName="border"
              action={changeBtnBorderColor}
            ></PickColor>
          )}
          </ContainerWrapper>
        </ContainerWrapper>
      );
    } else {
      return text;
    }
  };

  return <>{renderButton()}</>;
};

export default BtnEditor;
