import React from "react";
import Editor from "../../utils/editFunctions";

const Template10 = ({ template, statusMode }) => {
  const editor = new Editor(template, statusMode);

  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        {/* template 10 */}
        {/*[if mso | IE]>
      <table
         align="center" border="0" cellpadding="0" cellspacing="0" class="content-padding" style="width:600px;" width="600"
      >
        <tr>
          <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
      <![endif]*/}
        <div
          className="content-padding"
          style={{
            background: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            margin: "0px auto",
            maxWidth: "600px",
          }}
        >
          <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
              background: editor.handleTemplateBgColor(),
              backgroundColor: editor.handleTemplateBgColor(),
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    direction: "ltr",
                    fontSize: "0px",
                    padding: `${editor.handleTopMargin()} 10% ${editor.handleBottomMargin()} 10%`,
                    textAlign: "center",
                  }}
                >
                  {/*[if mso | IE]>
                  <table  border="0" cellpadding="0" cellspacing="0">

        <tr>

            <td
               class="box-shadow-outlook" style="vertical-align:top;width:580px;"
            >
          <![endif]*/}
                  <div
                    className="mj-column-per-100 mj-outlook-group-fix box-shadow"
                    style={{
                      boxShadow: "7px 7px 5px #00000026",
                      fontSize: "0px",
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%",
                    }}
                  >
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      role="presentation"
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #EAEAEA",
                              verticalAlign: "top",
                              padding: "22px 35px",
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              role="presentation"
                              style={{ borderRadius: "10px" }}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="left"
                                    style={{
                                      fontSize: "0px",
                                      padding: "20px 0 0 0",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontFamily:
                                          '"Raleway", "Arial-Black", sans-serif',
                                        fontSize: "16px",
                                        fontWeight: 100,

                                        lineHeight: "25px",
                                        textAlign: "left",
                                        color: "#183F4B",
                                      }}
                                    >
                                      {/* tag line */}
                                      <table
                                        width="100%"
                                        border={0}
                                        cellSpacing={0}
                                        cellPadding={0}
                                        align="left"
                                      >
                                        <tbody>
                                          <tr>
                                            <td>
                                              <table
                                                border={0}
                                                cellSpacing={0}
                                                cellPadding={0}
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td vertical-align="bottom">
                                                      <div
                                                        style={{
                                                          fontSize: "26px",
                                                          fontWeight: "bold",
                                                          color: "#7A7A7A",
                                                          textTransform:
                                                            "uppercase",
                                                          lineHeight: "25px",
                                                        }}
                                                      >
                                                        {editor.handleTitle(
                                                          "title-1"
                                                        )}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <table
                                                        border={0}
                                                        cellSpacing={0}
                                                        cellPadding={0}
                                                      >
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              align="left"
                                                              vertical-align="middle"
                                                              style={{
                                                                fontSize: "0px",
                                                                padding:
                                                                  "0 0 0 0",
                                                                wordBreak:
                                                                  "break-word",
                                                              }}
                                                            >
                                                              <table
                                                                border={0}
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                role="presentation"
                                                                style={{
                                                                  borderCollapse:
                                                                    "separate",
                                                                  lineHeight:
                                                                    "100%",
                                                                }}
                                                              >
                                                                <tbody>
                                                                  <tr>
                                                                    <td width="10px" />
                                                                    <td
                                                                      align="center"
                                                                      bgcolor={editor.handleBgColor(
                                                                        "tags",
                                                                        "tag-1"
                                                                      )}
                                                                      role="presentation"
                                                                      style={{
                                                                        border:
                                                                          "none",
                                                                        borderRadius:
                                                                          "100px",
                                                                        cursor:
                                                                          "auto",
                                                                        msoPaddingAlt:
                                                                          "2px 16px",
                                                                        background: editor.handleBgColor(
                                                                          "tags",
                                                                          "tag-1"
                                                                        ),
                                                                      }}
                                                                      valign="middle"
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          display:
                                                                            "inline-block",
                                                                          background: editor.handleBgColor(
                                                                            "tags",
                                                                            "tag-1"
                                                                          ),
                                                                          color:
                                                                            "#ffffff",
                                                                          fontFamily:
                                                                            '"Lato", "Arial", sans-serif',
                                                                          fontSize:
                                                                            "16px",
                                                                          lineHeight:
                                                                            "120%",
                                                                          margin: 0,
                                                                          textDecoration:
                                                                            "none",
                                                                          textTransform:
                                                                            "none",
                                                                          padding:
                                                                            "4px 16px",
                                                                          msoPaddingAlt:
                                                                            "0px",
                                                                          borderRadius:
                                                                            "100px",
                                                                          fontWeight: 900,
                                                                        }}
                                                                      >
                                                                        {editor.handleTagText(
                                                                          "tag-1"
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      {/* tag line */}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="left"
                                    style={{
                                      fontSize: "0px",
                                      padding: "22px 0 0 0",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontFamily:
                                          '"Lato", "Arial", sans-serif',
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        lineHeight: "25px",
                                        textAlign: editor.handleTextAlign("text-1"),
                                        color: "#183F4B",
                                      }}
                                    >
                                      {editor.handleText("text-1")}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*[if mso | IE]>
            </td>

        </tr>

                  </table>
                <![endif]*/}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*[if mso | IE]>
          </td>
        </tr>
      </table>
      <![endif]*/}
        {/* template 10 */}
      </div>
      {editor.handleDivider()}
    </>
  );
};

export default Template10;
