import React from "react";
import styled from "styled-components";
import fieldDisplayer from "../../utils/fieldDisplayer";
import { useTranslation } from "react-i18next";

const LabelName = styled.ul`
  color: #fabe3c;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const EditorItemList = styled.ul`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const EditorItem = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const EditorItemContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 10px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    background: #fabe3c;
    border-radius: 1000px;
    width: 6px;
  }
`;

const FieldList = styled.ul`
  opacity: 1;
  display: block;
  ${({ isOpen }) => (isOpen ? "overflow: visible" : "overflow: hidden")};
`;

const ImageFieldList = styled.ul`
  display: flex;
  padding-left: 10px;
  // opacity: .5;
  // overflow: hidden;
  // padding: 5px 5px;
  display: flex;
`;

const ImageFieldItem = styled.li`
  // width: 130px;
`;

const FieldItem = styled.li`
  display: flex;
  flex-direction: column;
`;

const EditorInterface = ({ template }) => {
  const { t } = useTranslation();

  const interfaceLabel = {
    titles: t("editorInterface.titles"),
    texts: t("editorInterface.texts"),
    buttons: t("editorInterface.buttons"),
    images: t("editorInterface.images"),
    tags: t("editorInterface.tags"),
    options: t("editorInterface.options"),
    backgroundColors: t("editorInterface.backgroundColors"),
  };

  const content = Object.keys(template.content);

  const renderEditorList = (contentKey) => {
    const itemKeys = Object.keys(template.content[contentKey]);
    if (contentKey === "images") {
      return (
        <ImageFieldList className="selected" isOpen={true}>
          {renderImageItem(itemKeys, contentKey)}
        </ImageFieldList>
      );
    } else {
      return (
        <FieldList className="selected" isOpen={true}>
          {renderEditorItem(itemKeys, contentKey)}
        </FieldList>
      );
    }
  };

  const renderEditorItem = (itemKeys, contentKey) => {
    return itemKeys.map((element, index) => {
      return (
        <FieldItem
          key={`editor-item-${index}`}
          className={` editor-content editor-content-open`}
        >
          <div className="editor-content-text editor-content-text-open">
            {fieldDisplayer(template, contentKey, element)}
          </div>
        </FieldItem>
      );
    });
  };

  const renderImageItem = (itemKeys, contentKey) => {
    return itemKeys.map((element, index) => {
      return (
        <ImageFieldItem
          key={`editor-item-${index}`}
          className={` editor-content editor-content-open`}
        >
          <div className="editor-content-image editor-content-image-open">
            {fieldDisplayer(template, contentKey, element)}
          </div>
        </ImageFieldItem>
      );
    });
  };

  const renderInterface = () => {
    return (
      <EditorItemList isActived={true}>
        {content.map((element, index) => {
          return (
            <EditorItem isOpen={true} key={`editor-list-item-${index}`}>
              <LabelName>{interfaceLabel[element]}</LabelName>
              <EditorItemContent>{renderEditorList(element)}</EditorItemContent>
            </EditorItem>
          );
        })}
      </EditorItemList>
    );
  };

  return <>{renderInterface()}</>;
};

export default EditorInterface;
