import React from "react";
import Editor from "../../utils/editFunctions";

const Template4 = ({ template, statusMode }) => {
  const editor = new Editor(template, statusMode);
  const renderImage = () => [
    <img
      height="auto"
      src={editor.handleImageSrc(
        "image-1"
      )}
      alt={editor.handleImageAlt(
        "image-1"
      )}
      style={{
        border: 0,
        display: "block",
        outline: "none",
        textDecoration: "none",
        height: "auto",
        width: "100%",
        fontSize: "13px",
      }}
      width={editor.handleImageWidth(
        "image-1"
      )}
    />,
    editor.handleImageHighlight(
      "image-1"
    )
  ]
  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        {/* template 4 */}
        <table
          align="center"
          className="content-padding"
          border={0}
          cellPadding={0}
          cellSpacing={0}
          style={{
            background: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <td>
                {/*[if mso | IE]>
<table
   align="center" border="0" cellpadding="0" cellspacing="0" class="content-padding-outlook" style="width:1200px;" width="1200"
>
  <tr>
    <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]*/}
                <div style={{ margin: "0px auto", maxWidth: "1200px" }}>
                  <table
                    align="center"
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    style={{ width: "100%" }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            direction: "ltr",
                            fontSize: "0px",
                            padding: `${editor.handleTopMargin()} 0px ${editor.handleBottomMargin()} 0px`,
                            textAlign: "center",
                          }}
                        >
                          {/*[if mso | IE]>
            <table border="0" cellpadding="0" cellspacing="0">

  <tr>

      <td
         class="" style="vertical-align:top;width:1200px;"
      >
    <![endif]*/}
                          <div
                            className="mj-column-per-100 mj-outlook-group-fix"
                            style={{
                              fontSize: "0px",
                              textAlign: "left",
                              direction: "ltr",
                              display: "inline-block",
                              verticalAlign: "top",
                              width: "100%",
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{ verticalAlign: "top", padding: 0 }}
                                  >
                                    <table
                                      border={0}
                                      cellPadding={0}
                                      cellSpacing={0}
                                      style={{}}
                                      width="100%"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              fontSize: "0px",
                                              padding: 0,
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <table
                                              border={0}
                                              cellPadding={0}
                                              cellSpacing={0}
                                              style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0px",
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{
                                                      position: `${editor.handleImagePosition()}`,
                                                      width: `${editor.handleImageWidth(
                                                        "image-1"
                                                      )}px`,
                                                    }}
                                                  >
                                                    {
                                                      editor.handleImageLink("image-1") ?
                                                        (<a href={editor.handleImageLinkUrl("image-1")} target="_blank" rel="noopener noreferrer">{renderImage()}</a>) : renderImage()
                                                    }
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/*[if mso | IE]>
      </td>

  </tr>

            </table>
          <![endif]*/}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/*[if mso | IE]>
    </td>
  </tr>
</table>
<![endif]*/}
              </td>
            </tr>
          </tbody>
        </table>
        {/* template 4 */}
      </div>
      {editor.handleDivider()}
    </>
  );
};

export default Template4;
