import React from "react";
import Footer from "../components/templates/Footer";

const footerDisplayer = (template, data, setData) => {
  let footerToDisplay;

  switch (template.model) {
    case "model-footer-1":
      footerToDisplay = (
        <Footer
          key={`template-${template.id}`}
          template={template}
          statusMode="NEWSLETTER-FORMAT"
        ></Footer>
      );
      break;

    default:
      footerToDisplay = null;
  }

  return footerToDisplay;
};

export default footerDisplayer;
