import React from "react";
import Editor from "../../utils/editFunctions";

const Template24 = ({ template, statusMode }) => {
  const editor = new Editor(template, statusMode);

  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        {/*[if mso | IE]>
        <table
          align="center" border="0" cellpadding="0" cellspacing="0" class="content-padding-outlook" style="width:600px;" width="600"
        >
          <tr>
            <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
        <![endif]*/}
        <div
          className="content-padding"
          style={{
            background: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            margin: "0px auto",
            maxWidth: "600px",
          }}
        >
          <table
            align="center"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
              background: editor.handleTemplateBgColor(),
              backgroundColor: editor.handleTemplateBgColor(),
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    direction: "ltr",
                    fontSize: "0px",
                    padding: `${editor.handleTopMargin()} 0px ${editor.handleBottomMargin()} 0px`,
                    paddingLeft: "10%",
                    paddingRight: "10%",
                    textAlign: "center",
                  }}
                >
                  {/*[if mso | IE]>
                    <table border="0" cellpadding="0" cellspacing="0">
                      <tr>
                        <td style="vertical-align:top;width:580px;">
                    <![endif]*/}
                  <div
                    className="mj-column-per-100 mj-outlook-group-fix"
                    style={{
                      fontSize: "0px",
                      textAlign: "left",
                      direction: "ltr",
                      display: "inline-block",
                      verticalAlign: "top",
                      width: "100%",
                    }}
                  >
                    <table
                      border={0}
                      cellPadding={0}
                      cellSpacing={0}
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td style={{ verticalAlign: "top", padding: 0 }}>
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="center"
                                    vertical-align="middle"
                                    style={{
                                      fontSize: "0px",
                                      padding: "0px 0 0px 0",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    <table
                                      border={0}
                                      cellPadding={0}
                                      cellSpacing={0}
                                      style={{
                                        borderCollapse: "separate",
                                        lineHeight: "100%",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            align="center"
                                            bgcolor={editor.handleBgColor(
                                              "buttons",
                                              "button-1"
                                            )}
                                            style={{
                                              border: "none",
                                              borderRadius: "100px",
                                              cursor: "auto",
                                              msoPaddingAlt: "10px 20px",
                                              background: editor.handleBgColor(
                                                "buttons",
                                                "button-1"
                                              ),
                                            }}
                                            valign="middle"
                                          >
                                            <a
                                              href={editor.handleUrl(
                                                "buttons",
                                                "button-1"
                                              )}
                                              style={{
                                                display: "inline-block",
                                                background: editor.handleBgColor(
                                                  "buttons",
                                                  "button-1"
                                                ),
                                                color: editor.handleTextColor(
                                                  "buttons",
                                                  "button-1"
                                                ),
                                                fontFamily:
                                                  '"Raleway", "Arial-Black",  sans-serif',
                                                fontSize: "21px",
                                                fontWeight: "bold",
                                                lineHeight: "25px",
                                                margin: 0,
                                                textDecoration: "none",
                                                textTransform: "none",
                                                padding: "10px 20px",
                                                msoPaddingAlt: "0px",
                                                borderRadius: "100px",
                                              }}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {editor.handleBtnText("button-1")}
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/*[if mso | IE]>
                        </td>
                      </tr>
                    </table>
                  <![endif]*/}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*[if mso | IE]>
            </td>
          </tr>
        </table>
        <![endif]*/}
      </div>
      {editor.handleDivider()}
    </>
  );
};

export default Template24;
