import {InputContainer, SelectInput} from '../containers/Admin/EditUser/styles';
import {CategoryItem, DeleteBtn} from '../containers/Admin/CategoryList/styles';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const AddCategoryBtn = styled.button`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  background-color: #13ce66;
  border-color: #13ce66;
  border-radius: 1000px;
  padding: 10px 20px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  border-width: 1px;
  border-style: solid;
  text-decoration: none;
  border: none;
  font-family: "Raleway", sans-serif;
  transition: background-color 0.2s, border-color 0.2s;
  &:hover {
    background-color: #fabe3c;
    border-color: #fabe3c;
  }
  & span {
    margin-left: 4px;
  }
`;

const CategorySelect = (props) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [categories, setCategories] = useState(null);
  const userCategories = props.categories;

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/categories`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
      await setCategories(response.data);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return categories !== null && (
    <InputContainer>
      {
        userCategories.map((catId) => (
          <CategoryItem key={`user+${catId}`}>
            {categories.find((c) => c._id === catId).title}
            <DeleteBtn
              onClick={() => props.onRemove && props.onRemove(catId)}
              className="submit-button"
            >
              <FontAwesomeIcon icon="trash" />
            </DeleteBtn>
          </CategoryItem>
        ))
      }
      <AddCategoryBtn to="/category/add" type="button">
        <FontAwesomeIcon icon="plus" />
        <span>{t("categorySelect.addCategoryBtn")}</span>
        <SelectInput
          style={{opacity: 0, position: 'absolute', inset: 0, padding: 0, height: '100%'}}
          value=""
          name="role"
          onChange={(event) => {
            if (event.target.value) {
              props.onAdd && props.onAdd(event.target.value);
              event.target.value = '';
            }
          }}
        >
          {
            categories.map((cat) => (
              <option value={cat._id} key={cat._id}>{cat.title}</option>
            ))
          }
        </SelectInput>
      </AddCategoryBtn>
    </InputContainer>
  );
}



export default CategorySelect;
