import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LoaderAnimation from "../components/LoaderAnimation";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import UpdateForm from "../components/NewsletterForm/UpdateForm";
import EditorTab from "../components/newsletterTab/EditorTab";
import HtmlTab from "../components/newsletterTab/HtmlTab/HtlmTab";
import BreadcrumbFront from "../components/BreadcrumbFront";

const MainContainer = styled.div`
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
`;

const NewsletterEdit = (props) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsloading] = useState(true);
  const dispatch = useDispatch();
  const { newsletterId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true);
      dispatch({
        type: "RESET_NEWLETTER_BUILDER",
      });

      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/newsletter/${newsletterId}`,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );

      await dispatch({
        type: "LOAD_TEMPLATES_IN_MODEL_AND_NEWSLETTER_COLUMN",
        data: {
          category: response.data.category,
          newsletterTemplates: response.data.templates,
          newsletterId: response.data._id,
          newsletterTitle: response.data.title,
        },
      });

      await setIsloading(false);
    };

    //

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContainer>
      {isLoading === true ? (
        <LoaderAnimation></LoaderAnimation>
      ) : (
        <>
          <HeaderWrapper>
            <BreadcrumbFront
              title={t("newsletterEdit.title")}
              link="/"
            ></BreadcrumbFront>
            <UpdateForm></UpdateForm>
          </HeaderWrapper>

          <EditorTab></EditorTab>
          <HtmlTab></HtmlTab>
        </>
      )}
    </MainContainer>
  );
};

export default NewsletterEdit;
