import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

const List = styled.ul`
  display: flex;
`;

const HomeLink = styled(Link)`
  color: #000;
  font-size: 30px;
  margin-right: 10px;
`;

const AdminLink = styled(Link)`
  color: #000;
  font-size: 30px;
  ${({ user_role }) =>
    user_role !== "super-admin" ? "pointer-events:none;" : ""}

  opacity:${({ user_role }) => (user_role === "super-admin" ? "1" : "0.2")}
`;

const Item = styled.li``;

const AdminMenu = styled.nav``;

const SuperAdminNav = (props) => {
  const user = useSelector((state) => state.user);

  return (
    <AdminMenu>
      <List>
        <Item>
          <HomeLink user_role={user.role} to="/">
            <FontAwesomeIcon icon="home" />
          </HomeLink>
        </Item>
        <Item>
          <AdminLink user_role={user.role} to="/category/list">
            <FontAwesomeIcon icon="cogs" />
          </AdminLink>
        </Item>
      </List>
    </AdminMenu>
  );
};

export default SuperAdminNav;
