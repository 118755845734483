import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import ModelChoice from "../components/ModelChoice";
import UserNewsletterList from "../components/UserNewsletterList";

const Container = styled.div`
  padding: 20px;
`;

const Home = (props) => {
  const user = useSelector((state) => state.user);

  return (
    <Container>
      {user.token !== undefined &&
      (user.role === "editor" || user.role === "super-admin") ? (
        <>
          <ModelChoice></ModelChoice>
          <UserNewsletterList></UserNewsletterList>
        </>
      ) : null}
    </Container>
  );
};

export default Home;
