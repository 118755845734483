import React from "react";
import Editor from "../../utils/editFunctions";

const Template13 = ({ template, statusMode }) => {
  const editor = new Editor(template, statusMode);

  return (
    <>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        {/* template 13 */}
        <table
          align="center"
          border={0}
          cellPadding={0}
          cellSpacing={0}
          style={{
            background: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <td>
                {/*[if mso | IE]>
<table
   align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:1200px;" width="1200"
>
  <tr>
    <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
<![endif]*/}
                <div style={{ margin: "0px auto", maxWidth: "1200px" }}>
                  <table
                    align="center"
                    border={0}
                    cellPadding={0}
                    cellSpacing={0}
                    style={{ width: "100%" }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            direction: "ltr",
                            fontSize: "0px",
                            padding: `${editor.handleTopMargin()} 0px ${editor.handleBottomMargin()} 0px`,
                            textAlign: "center",
                          }}
                        >
                          {/*[if mso | IE]>
            <table border="0" cellpadding="0" cellspacing="0">

  <tr>

      <td
         class="" style="vertical-align:top;width:1200px;"
      >
    <![endif]*/}
                          <div
                            className="mj-column-per-100 mj-outlook-group-fix"
                            style={{
                              fontSize: "0px",
                              textAlign: "left",
                              direction: "ltr",
                              display: "inline-block",
                              verticalAlign: "top",
                              width: "100%",
                            }}
                          >
                            <table
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      backgroundColor: editor.handleBgColor(
                                        "backgroundColors",
                                        "backgroundColor-1"
                                      ),
                                      verticalAlign: "top",
                                      padding: 0,
                                    }}
                                  >
                                    <table
                                      border={0}
                                      cellPadding={0}
                                      cellSpacing={0}
                                      style={{}}
                                      width="100%"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              fontSize: "0px",
                                              padding: 0,
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <table
                                              border={0}
                                              cellPadding={0}
                                              cellSpacing={0}
                                              style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0px",
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{ width: "1200px" }}
                                                  >
                                                    <img
                                                      height="auto"
                                                      alt="top-frame"
                                                      src="https://makesense-outil-newsletter.s3-eu-west-1.amazonaws.com/Block-Jaune-top_gatahu.png"
                                                      style={{
                                                        border: 0,
                                                        display: "block",
                                                        outline: "none",
                                                        textDecoration: "none",
                                                        height: "auto",
                                                        width: "100%",
                                                        fontSize: "13px",
                                                      }}
                                                      width={1200}
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="left"
                                            style={{
                                              fontSize: "0px",
                                              padding: "0px",
                                              paddingLeft: "10%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <table
                                              border={0}
                                              cellPadding={0}
                                              cellSpacing={0}
                                              style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0px",
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td style={{ width: "31px" }}>
                                                    <img
                                                      height={30}
                                                      src="https://makesense-outil-newsletter.s3-eu-west-1.amazonaws.com/Apostrophe-seul_l45fbd.png"
                                                      alt="quote"
                                                      style={{
                                                        border: 0,
                                                        display: "block",
                                                        outline: "none",
                                                        textDecoration: "none",
                                                        height: "30px",
                                                        width: "100%",
                                                        fontSize: "13px",
                                                      }}
                                                      width={31}
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="left"
                                            style={{
                                              fontSize: "0px",
                                              padding: "10px 25px",
                                              paddingRight: "10%",
                                              paddingLeft: "10%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontFamily:
                                                  '"Raleway", "Arial-Black", sans-serif',
                                                fontSize: "16px",
                                                lineHeight: "25px",
                                                textAlign: editor.handleTextAlign("text-1"),
                                                color: "#0C3944",
                                              }}
                                            >
                                              {editor.handleText("text-1")}
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="right"
                                            style={{
                                              fontSize: "0px",
                                              padding: "10px 25px",
                                              paddingTop: "0px",
                                              paddingRight: "10%",
                                              paddingBottom: "0px",
                                              paddingLeft: "10%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontFamily:
                                                  '"lato", Arial, sans-serif',
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                                lineHeight: "25px",
                                                textAlign: "right",
                                                color: "#0C3944",
                                              }}
                                            >
                                              {editor.handleTitle("title-1")}
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="right"
                                            style={{
                                              fontSize: "0px",
                                              padding: "10px 25px",
                                              paddingTop: "0px",
                                              paddingRight: "10%",
                                              paddingLeft: "10%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontFamily:
                                                  '"lato", Arial, sans-serif',
                                                fontSize: "16px",
                                                lineHeight: "25px",
                                                fontWeight: "400",
                                                textAlign: "right",
                                                color: "#0C3944",
                                              }}
                                            >
                                              {editor.handleTitle("title-2")}
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              fontSize: "0px",
                                              padding: 0,
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <table
                                              border={0}
                                              cellPadding={0}
                                              cellSpacing={0}
                                              style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0px",
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{ width: "1200px" }}
                                                  >
                                                    <img
                                                      height="auto"
                                                      alt="bottom-frame"
                                                      src="https://makesense-outil-newsletter.s3-eu-west-1.amazonaws.com/Block-Jaune-bas-ok_ybgqpz.png"
                                                      style={{
                                                        border: 0,
                                                        display: "block",
                                                        outline: "none",
                                                        textDecoration: "none",
                                                        height: "auto",
                                                        width: "100%",
                                                        fontSize: "13px",
                                                      }}
                                                      width={1200}
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/*[if mso | IE]>
      </td>

  </tr>

            </table>
          <![endif]*/}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/*[if mso | IE]>
    </td>
  </tr>
</table>
<![endif]*/}
              </td>
            </tr>
          </tbody>
        </table>
        {/* template 13 */}
      </div>
      {editor.handleDivider()}
    </>
  );
};

export default Template13;
