import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getColors } from "../../utils/getColors";

const defaultColors = getColors();

const Container = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

const LAbelContainer = styled.div`
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  width: 100px;
  margin-right: 10px;
  font-weight: bold;
`;

const ColorContainer = styled.div`
  display: flex;
  flex: 1;
`;

const ColorList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  & li {
    box-sizing: border-box;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 30px;
    height: 30px;
  }
`;

const PickerBtn = styled.button`
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  background-color: ${({ color }) => color};
  border: none;

  border-radius: 80px;
  cursor: pointer;
  outline: none;
  transition: all 0.1s ease-in-out;
  &:hover {
    position: absolute;

    transform: scale(1.3);
    z-index: 100;
    box-shadow: inset rgba(0, 0, 0, 0) 2px 2px 12px, #ffffff 0 0 10px;
  }
`;

const PickBgColor = ({ action, labelName, newColors }) => {
  const { t } = useTranslation();
  let colors = defaultColors;
  if (newColors !== undefined) {
    colors = newColors;
  }

  const renderColorPicker = () => {
    return colors.map((element, index) => {
      return (
        <li key={`bg-color-${index}`}>
          <PickerBtn
            value={element}
            onClick={action}
            color={element}
          ></PickerBtn>
        </li>
      );
    });
  };

  const labelSelected = {
    text: t("pickBgColor.textColorLabel"),
    background: t("pickBgColor.backgroundColorLabel"),
    border: t("pickBgColor.borderColorLabel"),
  };

  return (
    <Container>
      <LAbelContainer>{labelSelected[labelName]}</LAbelContainer>
      <ColorContainer>
        <ColorList>{renderColorPicker()}</ColorList>
      </ColorContainer>
    </Container>
  );
};

export default PickBgColor;
