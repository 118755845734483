import React, { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import HandleImage from "./HandleImage";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import CanvasTitle from "./editorFields/CanvasTitle";
import EventImage from "./editorFields/EventImage";

const CropperContainer = styled.div`
  height: 100%;

  position: relative;

  box-sizing: border;
`;

const CloseBtn = styled.a`

  text-decoration:none
  font-size: 20px;
  color: #000;
`;

const Modal = (props) => {
  const userModalRef = useRef();
  const newsletterBuilder = useSelector((state) => state.newsletterBuilder);
  const dispatch = useDispatch();

  useOnClickOutside(userModalRef, () => {
    dispatch({
      type: "CLOSE_IMAGE_UPLOADER_MODAL",
    });
  });

  const renderModalContent = () => {
    let contentToDisplay;
    switch (newsletterBuilder.modal.frontContent) {
      case "image-cropper":
        contentToDisplay = <HandleImage></HandleImage>;
        break;
      case "canvas-image-title":
        contentToDisplay = <CanvasTitle></CanvasTitle>;
        break;
      case "event-image-generator":
        contentToDisplay = <EventImage  ></EventImage>;
        break;
      default:
        contentToDisplay = "vide";
    }

    return contentToDisplay;
  };

  return (
    <div
      className={`modal-wrapper ${newsletterBuilder.modal.isVisible ? "visible" : ""
        }`}
      id="login-modal"
    >
      <div ref={userModalRef} className="modal">
        <CloseBtn
          href="#close"
          className="close"
          onClick={(event) => {
            event.preventDefault();
            dispatch({
              type: "CLOSE_IMAGE_UPLOADER_MODAL",
            });
          }}
        >
          <FontAwesomeIcon icon="times" />
        </CloseBtn>

        <CropperContainer>
          {renderModalContent()}
          {/* {newsletterBuilder.modal.isVisible ? (
            <HandleImage></HandleImage>
          ) : (
            <div></div>
          )} */}
        </CropperContainer>
      </div>
    </div>
  );
};

// Hook
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export default Modal;
